import React from "react";


import {Row, Col} from "react-bootstrap";


import NavigationLinkComponent from "@UIParts/Header/Navigation/Item";


const NAVIGATION_LINKS = [
	{navigation_link_id: 1, navigation_link_url: {"PL": "/o-nas", "EN": "/about-us"}, navigation_link_title: {"PL": "O nas", "EN": "About Us"}},
	{navigation_link_id: 2, navigation_link_url: {"PL": "/checkflat", "EN": "/checkflat"}, navigation_link_title: {"PL": "Checkflat", "EN": "Checkflat"}},
	{navigation_link_id: 3, navigation_link_url: {"PL": "/jak-to-dziala", "EN": "/how-it-works"}, navigation_link_title: {"PL": "Jak to działa", "EN": "How it works"}},
	{navigation_link_id: 4, navigation_link_url: {"PL": "/cennik", "EN": "/pricing"}, navigation_link_title: {"PL": "Cennik", "EN": "Pricing"}},
	{navigation_link_id: 5, navigation_link_url: {"PL": "/nasze-rady", "EN": "/our-advices"}, navigation_link_title: {"PL": "Nasze rady", "EN": "Our Tips"}},
];


export const HeaderNavigationComponent = ({}) => {
	
	
	const navigationLinks = NAVIGATION_LINKS.map((linkData, i) =>
		<NavigationLinkComponent
			key={i}
			linkData={linkData}
		/>
	);
	
	
	return (
		<Col as="nav" xs={3} lg={6} className="application-header-navigation">
			<Row as="ul" className={"header-navigation-links"}>
				{navigationLinks}
			</Row>
		</Col>
	);
	
};


export default HeaderNavigationComponent;
