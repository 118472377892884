import React, {useState, useEffect, useContext, useRef} from "react";

import GlobalDataContext from "@Contexts/Global";


import {Redirect} from "react-router-dom";


import {Container, Row} from "react-bootstrap"


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PricingSectionHeaderComponent from "@UIPages/Common/PricingPage/Sections/PricingSection/Header";
import PricingSectionPricingComponent from "@UIPages/Common/PricingPage/Sections/PricingSection/Pricing";
import PricingSectionLegendComponent from "@UIPages/Common/PricingPage/Sections/PricingSection/Legend";
import scrollToDomElement from "@Utilities/Scrolling";


const URLS = {
	cart: {PL: "koszyk", EN: "cart"},
	contact: {PL: "kontakt", EN: "contact-us"}
}


export const PricingPagePricingSectionComponent = ({sectionData, activeLanguage}) => {
	
	
	const COMPONENT_REF = useRef(null);
	
	
	const [chosenPaymentOption, setChosenPaymentOption] = useState("1");
	const [chosenPackageOption, setChosenPackageOption] = useState("2");

	const [packageAddedToCart, setPackageAddedToCart] = useState(null);
	
	useEffect(() => {
		scrollToDomElement(COMPONENT_REF.current, 100);
		return () => {
			setChosenPaymentOption("1");
			setChosenPackageOption("2");
			setPackageAddedToCart(null);
		};
	}, []);
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent />;
	}
	
	const {
		payment_label_text: paymentLabelText,
		payment_option1_text: paymentOption1Text,
		payment_option2_text: paymentOption2Text,
		singular_check_text: singularCheckText,
		package_check_text: packageCheckText,
		price_label_text: priceLabelText
	} = sectionData;
	
	
	const addPackageToCart = (packageData) => {
		setPackageAddedToCart({chosenPackage: packageData, paymentType: chosenPaymentOption});
	};
	
	
	if (packageAddedToCart) {
		if (packageAddedToCart.packageID === 4) {
			return <Redirect to={{pathname: `/${URLS.contact[activeLanguage]}`, state: {}}}/>;
		}
		return <Redirect to={{pathname: `/${URLS.cart[activeLanguage]}`, state: {packageData: packageAddedToCart}}} />;
	}
	
	
	return (
		<Container ref={COMPONENT_REF} as="section" fluid={true} id="pricing-page-pricing-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					
					<PricingSectionHeaderComponent
						activeLanguage={activeLanguage}
						componentData={{
							paymentLabelText, paymentOption1Text, paymentOption2Text
						}}
						chosenPaymentOption={chosenPaymentOption}
						chosenPaymentOptionHandler={setChosenPaymentOption}
					/>
					
					
					<PricingSectionPricingComponent
						activeLanguage={activeLanguage}
						componentData={{
							singularCheckText, packageCheckText, priceLabelText
						}}
						chosenPaymentOption={chosenPaymentOption}
						chosenPackageOption={chosenPackageOption}
						chosenPackageOptionHandler={setChosenPackageOption}
						addToCartHandler={addPackageToCart}
					/>
					
					
					<PricingSectionLegendComponent
						activeLanguage={activeLanguage}
					/>
					
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default PricingPagePricingSectionComponent;
