import React, {useState, useEffect} from "react";

// import parse from 'html-react-parser';


import {Row, Col, Form} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


export const PricingSectionHeaderComponent = ({
	componentData,
	chosenPaymentOption, chosenPaymentOptionHandler
}) => {
	
	
	const [pricingSectionHeaderData, setPricingSectionHeaderData] = useState(null);
	
	useEffect(() => {
		setPricingSectionHeaderData(componentData);
		return () => {
			setPricingSectionHeaderData(null);
		};
	}, []);
	
	
	if (!pricingSectionHeaderData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		paymentLabelText, paymentOption1Text, paymentOption2Text
	} = pricingSectionHeaderData;
	
	
	return (
		<Col xs={12} className="pricing-section-header">
			<Row className="pricing-section-header-content">
				
				<Col xs={12} lg={4} className="pricing-section-header-label">
					<span>{paymentLabelText}</span>
				</Col>
				
				<Col xs={12} lg={{span: 7, offset: 1}} className="pricing-section-header-payment-type">
					<Form.Label className={chosenPaymentOption === "1" ? "active" : ""}>
						<span>SAVE 15%</span>
						{paymentOption1Text}
					</Form.Label>
					<Form.Check
						type="switch"
						id="custom-switch"
						value={chosenPaymentOption}
						onChange={e => chosenPaymentOptionHandler(e.target.checked ? "2" : "1")}
					/>
					<Form.Label  className={chosenPaymentOption === "2" ? "active" : ""}>
						{paymentOption2Text}
					</Form.Label>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default PricingSectionHeaderComponent;
