const MESSAGES = {
	PL: {
		undefined_error_message: code => `Niezdefiniowany Błąd (${code})`,
		missing_data_error_message: "Brak wymaganych danych w żądaniu",
		user_already_exists_error_message: "Użytkownik już isntnieje",
		missing_user_data_error_message: "Brakujące Dane użytkownika"
	},
	EN: {
		undefined_error_message: code => `Undefined Error (${code})`,
		missing_data_error_message: "Missing Request Data",
		user_already_exists_error_message: "User already exists",
		missing_user_data_error_message: "Missing User Data"
	}
}


export const registerFormApiRequest = async(
	registerFormData, REST_API_URL, ACTIVE_LANGUAGE = "PL"
) => {
	
	
	if (!registerFormData || !REST_API_URL) return null;
	
	
	const {
		undefined_error_message: undefinedErrorMessage, missing_data_error_message: missingDataErrorMessage,
		user_already_exists_error_message: userAlreadyExistsErrorMessage, missing_user_data_error_message: missingUserDataErrorMessage
	} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const {
		userFirstName, userLastName, userEmailAddress, userPassword,
		userInvoice, userCompanyName, userCompanyDNI, userCompanyStreet, userCompanyHouseNo, userCompanyPostCode, userCompanyCity,
		userBirthdayPresent, userBirthdayDate
	} = registerFormData;
	
	
	const registerRequestData = new FormData();
	
	
	registerRequestData.append("user_first_name", userFirstName);
	registerRequestData.append("user_last_name", userLastName);
	registerRequestData.append("user_email", userEmailAddress);
	registerRequestData.append("user_password", userPassword);
	
	
	if (userInvoice) {
		registerRequestData.append("user_company_invoice_status", userInvoice);
		registerRequestData.append("user_company_name", userCompanyName);
		registerRequestData.append("user_company_dni", userCompanyDNI);
		registerRequestData.append("user_company_street", userCompanyStreet);
		registerRequestData.append("user_company_house_no", userCompanyHouseNo);
		registerRequestData.append("user_company_postcode", userCompanyPostCode);
		registerRequestData.append("user_company_city", userCompanyCity);
	}
	
	
	try {
		
		const restApiResponse = await fetch(
			`${REST_API_URL}/app/users/list/register-new-user/`,
			{method: "POST", body: registerRequestData}
		);
		
		if (!restApiResponse.ok) {
			return {
				responseError: true, code: restApiResponse.status, message: undefinedErrorMessage(restApiResponse.status)
			};
		}
		
		try {
			
			const restApiResponseContent = await restApiResponse.json();
			
			if (restApiResponseContent.error) {
				const errorMessage = {code: 400};
				switch (restApiResponseContent.code) {
					case 40:
						errorMessage.message = missingDataErrorMessage;
						break;
					case 41:
						errorMessage.message = userAlreadyExistsErrorMessage;
						break;
					case 42:
						errorMessage.message = missingUserDataErrorMessage;
						break;
				}
				return {
					responseError: true, ...errorMessage
				};
			}
			
			if (restApiResponseContent.success) {
				return {
					responseSuccess: true, registeredUser: restApiResponseContent.data
				}
			}
			
			return {
				responseError: true, code: 500, message: undefinedErrorMessage(500)
			};
			
		} catch (e) {
			return {
				responseError: true, code: 500, message: undefinedErrorMessage(500)
			};
		}
		
	} catch (e) {
		return {
			responseError: true, code: 500, message: undefinedErrorMessage(500)
		};
	}
	

};


export default registerFormApiRequest;
