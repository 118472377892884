import React from "react";


import {Col, Row} from "react-bootstrap";


import imagePlaceholder from "@Assets/Images/no-image_s.png";


export const CheckFormInformationComponent = ({
	xs = 12, lg = 12, picture = null,
	title = "", children, formStep = null,
	cssClasses = "",
	...props
}) => {
	
	
	picture = picture || imagePlaceholder;
	
	cssClasses = "check-form-step-info user-form-info" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col {...props} xs={xs} lg={lg} className={cssClasses}>
			{children}
			{picture && !children &&
			<Row as="picture" className={"check-form-step-info-picture" + (formStep ? ` form-step-${formStep}` : "")}>
				<img src={picture} alt={title}/>
			</Row>
			}
		</Col>
	);
	
};


export default CheckFormInformationComponent;
