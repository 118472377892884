import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap"


import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';


import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";
import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Podaj lokalizację, którą chcesz sprawdzić",
			button_text: "Dalej",
			return_text: "Wróć"
		},
	},
	EN: {
		texts: {
			step_header: "Enter the location you want to check",
			button_text: "Next",
			return_text: "Back"
		},
	},
}

export const CheckFormStep2SectionComponent = ({
	activeLanguage,
	formData, formDataHandler,
	formStep, formStepHandler,
	supportedLocalizations
}) => {
	
	
	const {
		addressCountry, addressCity,
		addressStreet, addressDistrict, addressHouseNo, addressFlatNo
	} = formData;
	
	
	const [checkFlatFormDataAddressCountry, setCheckFlatFormDataAddressCountry] = useState(addressCountry || "1");
	const [checkFlatFormDataAddressCity, setCheckFlatFormDataAddressCity] = useState(addressCity || "");
	const [checkFlatFormDataAddressStreet, setCheckFlatFormDataAddressStreet] = useState(addressStreet || "");
	const [checkFlatFormDataAddressDistrict, setCheckFlatFormDataAddressDistrict] = useState(addressDistrict || "");
	const [checkFlatFormDataAddressHouseNo, setCheckFlatFormDataAddressHouseNo] = useState(addressHouseNo || "");
	const [checkFlatFormDataAddressFlatNo, setCheckFlatFormDataAddressFlatNo] = useState(addressFlatNo || "");
	
	const [foundSupportedLocalizations, setFoundSupportedLocalizations] = useState([]);
	
	const [formStepSubmitAttemptStatus, setFormStepSubmitAttemptStatus] = useState(false);
	
	const formStepErrorsMessages = {
		addressCity: null,
		addressStreet: null
	};
	
	const [formStepErrors, setFormStepErrors] = useState(formStepErrorsMessages);
	
	const changeCheckFlatFormDataAddressCity = (city, fromDropdown = false) => {
		
		const foundLocalization = Object.entries(supportedLocalizations).filter(([key, localization]) => {
			return localization.localization_name.toLowerCase().includes(city.toLowerCase());
		});
		
		setFoundSupportedLocalizations(fromDropdown || city.trim() === "" ? [] : foundLocalization);
		
		setCheckFlatFormDataAddressCity(city);
		
		setCheckFlatFormDataAddressStreet("");
		setCheckFlatFormDataAddressDistrict("");
		setCheckFlatFormDataAddressHouseNo("");
		
		const errors = {
			...formStepErrors,
			addressCity:
				foundLocalization.length > 0 || city.trim() === "" ?
					null :
					{message: activeLanguage === "PL" ? "Lokalizacja nie jest obsługiwana" : "Localization is not supported"}
		}
		
		setFormStepErrors(errors);
		
	};
	
	useEffect(() => {
		return () => {
			setCheckFlatFormDataAddressCountry("1");
			setCheckFlatFormDataAddressCity("");
			setCheckFlatFormDataAddressStreet("");
			setCheckFlatFormDataAddressDistrict("");
			setCheckFlatFormDataAddressHouseNo("");
			setFoundSupportedLocalizations([]);
			setFormStepSubmitAttemptStatus(false);
			setFormStepErrors(formStepErrorsMessages);
		};
	}, []);
	
	
	const SECTION_DATA = CONTENT[activeLanguage];
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	
	const submitCheckFlatFormStep = (reset = false) => {
		
		const error =
			!checkFlatFormDataAddressCity.trim().length
			||
			formStepErrors.addressCity
			||
			!Object.entries(supportedLocalizations).filter(([key, localization]) => {
				return localization.localization_name.toLowerCase() === checkFlatFormDataAddressCity.toLowerCase();
			}).length
		;
		
		if (error && !reset) {
			setFormStepSubmitAttemptStatus(true);
			setFormStepErrors({...formStepErrors, addressCity: {message: activeLanguage === "PL" ? "Proszę podać miasto" : "Please Enter the city"}});
			return null;
		}
		
		formDataHandler({
			addressCountry: reset ? null : checkFlatFormDataAddressCountry,
			addressCity: reset ? null : checkFlatFormDataAddressCity,
			addressStreet: reset ? null : checkFlatFormDataAddressStreet,
			addressDistrict: reset ? null : checkFlatFormDataAddressDistrict,
			addressHouseNo: reset ? null : checkFlatFormDataAddressHouseNo,
			addressFlatNo: reset ? null : checkFlatFormDataAddressFlatNo
		}, reset ? 0 : 2);
		
	};
	
	
	const {isLoaded: isMapLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyDIH0ikRFe6yGyi68nLEumr0evrbg5fjTM"
	});
	
	const [googleMapComponent, setGoogleMapComponent] = React.useState(null);
	
	useEffect(() => {
		setGoogleMapComponent(googleMapComponentRef);
		return () => {
			setGoogleMapComponent(null);
		};
	}, [isMapLoaded]);
	
	const googleMapComponentRef = useRef(null);

	
	return (
		<Row className={"check-form-step-component check-form-step-2-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Państwo" : "Country"}</Form.Label>
						<Form.Control
							as={"select"}
							value={checkFlatFormDataAddressCountry}
							onChange={e => setCheckFlatFormDataAddressCountry(e.target.value)}
							disabled={true}
						>
							<option value={"0"}>{activeLanguage === "PL" ? "Polska" : "Poland"}</option>
						</Form.Control>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option text-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Miasto" : "City"}</Form.Label>
						<Form.Control
							value={checkFlatFormDataAddressCity}
							onChange={e => changeCheckFlatFormDataAddressCity(e.target.value)}
							className={formStepErrors.addressCity ? "error" : ""}
							placeholder={activeLanguage === "PL" ? "Wpisz miasto" : "Enter the city"}
							autocomplete={false}
						/>
						{formStepErrors.addressCity &&
						<Form.Label className={"error"}>{formStepErrors.addressCity.message}</Form.Label>
						}
					</Col>
					{foundSupportedLocalizations.length > 0 &&
					<Col xs={12} className={"user-form-option-drop-down"}>
						{foundSupportedLocalizations.map((item, i) =>
						<Row key={i} className={"drop-down-item"} onClick={() => changeCheckFlatFormDataAddressCity(item[1].localization_name, true)}>
							<Col xs={12} className={"drop-down-item-content"}>
								<span>{item[1].localization_name}</span>
							</Col>
						</Row>
						)}
					</Col>
					}
				</Row>
				
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Dzielnica" : "District"}</Form.Label>
						<Form.Control
							value={checkFlatFormDataAddressDistrict}
							onChange={e => setCheckFlatFormDataAddressDistrict(e.target.value)}
							disabled={true}
							placeholder={activeLanguage === "PL" ? "Wpisz dzielnicę" : "Enter the district"}
						/>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Ulica" : "Street"}</Form.Label>
						<Form.Control
							value={checkFlatFormDataAddressStreet}
							onChange={e => setCheckFlatFormDataAddressStreet(e.target.value)}
							placeholder={activeLanguage === "PL" ? "Wpisz ulicę" : "Enter the street"}
						/>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={6} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Nr Budynku" : "Building nr"}</Form.Label>
						<Form.Control
							value={checkFlatFormDataAddressHouseNo}
							onChange={e => setCheckFlatFormDataAddressHouseNo(e.target.value)}
							placeholder={activeLanguage === "PL" ? "Wpisz numer budynku" : "Enter the house no"}
						/>
					</Col>
					<Col xs={6} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Nr Mieszkania" : "Flat nr"}</Form.Label>
						<Form.Control
							value={checkFlatFormDataAddressFlatNo}
							onChange={e => setCheckFlatFormDataAddressFlatNo(e.target.value)}
							placeholder={activeLanguage === "PL" ? "Wpisz numer mieszkania" : "Enter the flat no"}
						/>
					</Col>
				</Row>
			
			</Col>
			
			
			<CheckFormInformationComponent xs={12} lg={{span: 5, offset: 1}} formStep={formStep}>
				{isMapLoaded &&
				<GoogleMap
					ref={googleMapComponentRef}
					mapContainerStyle={{
						width: "100%",
						height: "100%",
						borderWidth: 2,
						borderStyle: "solid",
						borderColor: "#CCC"
					}}
					center={{lat: 52.13, lng: 21.00}}
					zoom={9}
				>
				</GoogleMap>
				}
				{!isMapLoaded && <MainContentLoaderComponent />}
			</CheckFormInformationComponent>
			
		</Row>
	);
	
};


export default CheckFormStep2SectionComponent;
