import React, {useState} from "react";


import {Row, Col} from "react-bootstrap";


import SuccessCommunicateComponent from "@Elements/Communicates/Success";
import ErrorCommunicateComponent from "@Elements/Communicates/Error";


import TeamMemberComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/List/Item";


export const TeamMembersListComponent = ({teamMembers, teamMemberHandler}) => {
	
	
	const [teamMembersListMessage, setTeamMembersListMessage] = useState(null);
	
	
	if (!teamMembers || !teamMembers.length) return null;
	
	
	const TeamMembersList = teamMembers.map((member, i) =>
		<TeamMemberComponent
			key={i}
			orderNr={i + 1}
			teamMemberData={member}
			teamMemberHandler={teamMemberHandler}
			teamMembersListMessageHandler={setTeamMembersListMessage}
		/>
	);
	
	
	const MessageComponent = teamMembersListMessage ? (
		teamMembersListMessage.success ? SuccessCommunicateComponent : ErrorCommunicateComponent
	) : null;
	
	
	return (
		<Col xs={12} className={"team-members-list"}>
		
			{teamMembersListMessage &&
			<Row className={"team-members-message"}>
				<MessageComponent communicateText={teamMembersListMessage.message} closeHandler={() => setTeamMembersListMessage(null)} />
			</Row>
			}
			
			{TeamMembersList}
		
		</Col>
	);
	
	
};


export default TeamMembersListComponent;
