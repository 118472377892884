import {CHANGE_LANGUAGE} from "@Store/Actions";


const activeLanguage = JSON.parse(localStorage.getItem("aggeActiveLanguage"));
const previousLanguage = JSON.parse(localStorage.getItem("aggePreviousLanguage"));

const initialState = {
	activeLanguage: activeLanguage || {languageID: 1, languageCode: "PL"},
	previousLanguage: previousLanguage
};


export const localizationReducer = (state = initialState, action) => {

	switch (action.type) {
	
		case CHANGE_LANGUAGE:
			localStorage.setItem("aggePreviousLanguage", JSON.stringify(state.activeLanguage));
			localStorage.setItem("aggeActiveLanguage", JSON.stringify(action.languageData));
			return {...state, activeLanguage: action.languageData, previousLanguage: state.activeLanguage};
		
		default:
			return state;
			
	}
	
};


export default localizationReducer;
