import React from "react";

import {useSelector} from "react-redux";


import CheckFormBackButtonComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormSkipButtonComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Skip";


const TEXTS = {
	PL: {submit_button_text: "Sprawdź wartość", alert_text: "Wkrótce"},
	EN: {submit_button_text: "Check the value", alert_text: "Soon"},
};


export const CheckFormStepsButtonsComponent = ({checkFormStep = 1, checkFormStepHandler}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode || "PL";
	
	const {submit_button_text: submitButtonText, alert_text: alertText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const previousButtonStatus = checkFormStep > 1;
	const nextButtonStatus = checkFormStep < 10;
	const skipButtonStatus = checkFormStep === 8 || checkFormStep === 9;
	
	
	const nextButtonColumns = !previousButtonStatus && !skipButtonStatus ? {span: 4, offset: 3} : 6;

	
	return (
		<>
			
			{previousButtonStatus &&
			<CheckFormBackButtonComponent xs={skipButtonStatus ? 4 : 6} lg={skipButtonStatus ? 4 : 6} xl={skipButtonStatus ? 4 : 6} clickHandler={() => checkFormStepHandler(checkFormStep - 1)}/>
			}
			
			{nextButtonStatus &&
			<CheckFormNextButtonComponent xs={skipButtonStatus ? 4 : nextButtonColumns} lg={skipButtonStatus ? 4 : nextButtonColumns} xl={skipButtonStatus ? 4 : nextButtonColumns} clickHandler={() => checkFormStepHandler(checkFormStep + 1)}/>
			}
			
			{skipButtonStatus &&
			<CheckFormSkipButtonComponent xs={4} lg={2} clickHandler={() => checkFormStepHandler(checkFormStep + 1)}/>
			}
			
			{checkFormStep === 10 &&
			<CheckFormNextButtonComponent xs={6} lg={6} cssClasses={"btn-final"}  buttonText={submitButtonText} clickHandler={() => alert(alertText)} />
			}
			
		</>
	);
	
};


export default CheckFormStepsButtonsComponent;
