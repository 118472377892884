import React from "react";


import {Container, Row, Col} from "react-bootstrap"



import CheckFormStepsSectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps";


import imagePlaceholder from "@Assets/Images/no-image.png";


export const CheckFlatPageCheckFormSectionComponent = ({
	activeLanguage,
	checkFlatFormStep, checkFlatFormStepHandler,
	checkFlatFormData, checkFlatFormDataHandler
}) => {
	
	
	const changeCheckFlatFormData = (fields, submitStep = false) => {
		const updatedFormData = {...checkFlatFormData, ...fields};
		checkFlatFormDataHandler(updatedFormData);
		if (submitStep || +submitStep === 0) {
			checkFlatFormStepHandler(submitStep + 1);
		}
		saveCheckFlatFormData(updatedFormData, submitStep + 1);
	};
	
	const changeCheckFlatFormStep = (step) => {
		checkFlatFormStepHandler(step);
		saveCheckFlatFormData(null, step);
	};
	
	const saveCheckFlatFormData = (updatedFormData, step) => {
		localStorage.setItem("checkFlatFormData", JSON.stringify({
			formStep: step || checkFlatFormStep,
			formData: updatedFormData || checkFlatFormData
		}));
	};
	

	return (
		<Container as="section" fluid={true} id="check-flat-page-check-form-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<CheckFormStepsSectionComponent
						activeLanguage={activeLanguage}
						formStep={checkFlatFormStep}
						formStepHandler={changeCheckFlatFormStep}
						formData={checkFlatFormData}
						formDataHandler={changeCheckFlatFormData}
					/>
					
					<Col xs={12} className={"check-form-information-component"}>
						<Row className={"check-form-information-image"}>
							<Col xs={12} lg={4} className={"information-image-label"}>
								<span>{activeLanguage ? "Czy wiesz że" : "Do You know"}</span>
							</Col>
							<Col xs={12} lg={4} className={"information-image-picture"}>
								<img src={imagePlaceholder} alt={""}/>
							</Col>
						</Row>
						<Row className={"check-form-information-content"}>
							<Col xs={12}>
								<p>
									{activeLanguage === "PL" ?
										"Ciągle zwiększamy swoją bazę o kolejne miasta i transakcje"
										:
										"We are constantly increasing our base with new cities and transactions"
									}
								</p>
							</Col>
						</Row>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default CheckFlatPageCheckFormSectionComponent;
