import React, {useState, useEffect, useContext, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";

import {LOG_USER_IN_ACTION} from "@Store/Actions";

import RestApiContext from "@Contexts/REST";


import validator from 'validator';


import {Row, Col, Form, Alert} from "react-bootstrap";


import FormHeaderComponent from "@Elements/Forms/Headers/FormHeader";
import FormLegendComponent from "@Elements/Forms/Elements/Legend";
import FormTextFieldComponent from "@Elements/Forms/Fields/Text";
import FormSwitchFieldComponent from "@Elements/Forms/Fields/Switch";
import FormCheckboxFieldComponent from "@Elements/Forms/Fields/Checkbox";
import FormSubmitFieldComponent from "@Elements/Forms/Fields/Submit";


import registerFormSubmitAttempt from "@Elements/Forms/Authentication/Register/registerSubmitAttempt";
import registerFormApiRequest from "@Elements/Forms/Authentication/Register/registerApiRequest";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";
import scrollToDomElement from "@Utilities/Scrolling";


const TEXTS = {
	PL: {
		header_text: "Nie mam jeszcze konta",
		legend_text: "pola obowiązkowe",
		name_label: "Imię",
		surname_label: "Nazwisko",
		email_label: "Adres e-mail",
		password_label: "Hasło",
		password_repeat_label: "Powtórz Hasło",
		invoice_label: "Potrzebujesz faktury?",
		yes_text: "Tak",
		no_text: "Nie",
		company_name_label: "Nazwa Firmy",
		company_dni_label: "NIP",
		company_street_label: "Ulica",
		company_house_no_label: "Nr Budynku / Lokalu",
		company_postcode_label: "Kod Pocztowy",
		company_city_label: "Miejscowość",
		user_type_label: "Powiedz, co planujesz, żebyśmy mogli lepiej pomóc Ci w Twojej transakcji",
		buy_label: "Chcę kupić mieszkanie",
		sell_label: "Chcę sprzedać mieszkanie",
		middleman_label: "Jestem pośrdednikiem",
		birthday_present_label: "Chcesz otrzymać prezent na urodziny?",
		birthday_date_label: "Podaj datę urodzin",
		for_myself_label: "Dla siebie",
		for_investment_label: "Pod inwestycję",
		newsletter_label: "Tak, chcę otrzymywać newsletter o rynku nieruchomości, ofertach specjalnych oraz webinarach.",
		regulations_label: "Tak, akceptuję <a onclick='return false'>regulamin</a> oraz <a onclick='return false'>Politykę Prywatności</a> Checkflat *",
		button_text: "Zarejestruj się",
	},
	EN: {
		header_text: "I don't have an account yet",
		legend_text: "Required fields",
		name_label: "Name",
		surname_label: "Last Name",
		email_label: "E-mail adress",
		password_label: "Password",
		password_repeat_label: "Repeat password",
		invoice_label: "Do you need an invoice?",
		yes_text: "Yes",
		no_text: "No",
		company_name_label: "Company name",
		company_dni_label: "DNI",
		company_street_label: "Street",
		company_house_no_label: "Building / Premise No.",
		company_postcode_label: "Postal Code",
		company_city_label: "City",
		user_type_label: "Tell us what you are planning so that we can better assist you with your transaction",
		buy_label: "I want to buy an apartment",
		sell_label: "I want to sell my apartment",
		middleman_label: "I am an intermediary",
		birthday_present_label: "Would you like a birthday present?",
		birthday_date_label: "Enter your date of birth",
		for_myself_label: "For myself",
		for_investment_label: "For investment",
		newsletter_label: "Yes, I would like to receive a newsletter about the real estate market, special offers and webinars.",
		regulations_label: "Yes, I accept <a onclick='return false'>regulations</a> and <a onclick='return false'>Privacy Policy</a> of Checkflat *",
		button_text: "Register",
	},
}


export const RegisterFormComponent = ({userData = {}}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	const LOGGED_USER = useSelector(state => state?.authentication)?.loggedUser;
	
	const DISPATCHER = useDispatch();
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		return () => {
			setFormErrors({});
			DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		};
	}, []);
	
	
	const [userFirstName, setUserFirstName] = useState(userData?.userFirstName || "");
	const [userLastName, setUserLastName] = useState(userData?.userLastName || "");
	const [userEmailAddress, setUserEmailAddress] = useState(userData?.userEmailAddress || "");
	const [userPassword, setUserPassword] = useState("");
	const [userPasswordRepeat, setUserPasswordRepeat] = useState("");
	
	const [userInvoice, setUserInvoice] = useState(userData?.userInvoice || false);
	const [userCompanyName, setUserCompanyName] = useState(userData?.userCompanyName || "");
	const [userCompanyDNI, setUserCompanyDNI] = useState(userData?.userCompanyDNI || "");
	const [userCompanyStreet, setUserCompanyStreet] = useState(userData?.userCompanyStreet || "");
	const [userCompanyHouseNo, setUserCompanyHouseNo] = useState(userData?.userCompanyHouseNo || "");
	const [userCompanyPostCode, setUserCompanyPostCode] = useState(userData?.userCompanyPostCode || "");
	const [userCompanyCity, setUserCompanyCity] = useState(userData?.userCompanyCity || "");
	
	const [userTransactionType, setUserTransactionType] = useState(userData?.userTransactionType || 0);
	const [userTransactionSubType, setUserTransactionSubType] = useState(userData?.userTransactionSubType || 0);
	
	const [userBirthdayPresent, setUserBirthdayPresent] = useState(userData?.userBirthdayPresent || false);
	const [userBirthdayDate, setUserBirthdayDate] = useState(userData?.userBirthdayDate || "");
	
	const [userNewsletterStatus, setUserNewsletterStatus] = useState(userData?.userNewsletterStatus || false);
	
	const [regulationAcceptedStatus, setRegulationAcceptedStatus] = useState(false);
	
	
	const changeUserFirstName = (fieldValue) => {
		if (fieldValue.length > 2) setFormErrors({...formErrors, userFirstName: null}) ;
		setUserFirstName(fieldValue);
	};
	
	const changeUserLastName = (fieldValue) => {
		if (fieldValue.length > 2) setFormErrors({...formErrors, userLastName: null}) ;
		setUserLastName(fieldValue);
	};
	
	const changeUserEmailAddress = (fieldValue) => {
		if (validator.isEmail(fieldValue)) setFormErrors({...formErrors, userEmailAddress: null}) ;
		setUserEmailAddress(fieldValue);
	};
	
	const changeUserPassword = (fieldValue) => {
		if (fieldValue.length > 7) setFormErrors({...formErrors, userPassword: null});
		setUserPassword(fieldValue);
	};
	
	const changeUserPasswordRepeat = (fieldValue) => {
		if (fieldValue === userPassword) setFormErrors({...formErrors, userPasswordRepeat: null});
		setUserPasswordRepeat(fieldValue);
	};
	
	
	const changeUserInvoiceStatus = (status) => {
		if (!status) {
			setUserCompanyName("");
			changeUserCompanyDNI("");
			setUserCompanyStreet("");
			setUserCompanyHouseNo("");
			setUserCompanyPostCode("");
			setUserCompanyCity("");
			setFormErrors({
				...formErrors,
				userCompanyName: null, userCompanyDNI: null,
				userCompanyStreet: null, userCompanyHouseNo: null,
				userCompanyPostCode: null, userCompanyCity: null
			});
		}
		setUserInvoice(status);
	};
	
	const changeUserCompanyName = (fieldValue) => {
		if (fieldValue.length > 2) setFormErrors({...formErrors, userCompanyName: null});
		setUserCompanyName(fieldValue);
	};
	
	const changeUserCompanyDNI = (fieldValue) => {
		if (fieldValue.length > 9) setFormErrors({...formErrors, userCompanyDNI: null});
		setUserCompanyDNI(fieldValue);
	};
	
	const changeUserCompanyStreet = (fieldValue) => {
		if (fieldValue.length > 3) setFormErrors({...formErrors, userCompanyStreet: null});
		setUserCompanyStreet(fieldValue);
	};
	
	const changeUserCompanyHouseNo = (fieldValue) => {
		if (fieldValue.length > 0) setFormErrors({...formErrors, userCompanyHouseNo: null});
		setUserCompanyHouseNo(fieldValue);
	};
	
	const changeUserCompanyPostCode = (fieldValue) => {
		if (fieldValue.length > 5) setFormErrors({...formErrors, userCompanyPostCode: null});
		setUserCompanyPostCode(fieldValue);
	};
	
	const changeUserCompanyCity = (fieldValue) => {
		if (fieldValue.length > 2) setFormErrors({...formErrors, userCompanyCity: null});
		setUserCompanyCity(fieldValue);
	};
	
	
	const changeUserTransactionType = (fieldValue) => {
		setUserTransactionSubType(0);
		setUserTransactionType(fieldValue);
	};
	
	
	const changeBirthdayPresentStatus = (status) => {
		if (!status) {
			setUserBirthdayDate("");
			setFormErrors({
				...formErrors,
				userBirthdayDate: null
			});
		}
		setUserBirthdayPresent(status);
	};
	
	const changeUserBirthdayDate = (fieldValue) => {
		if (validator.isDate(fieldValue)) setFormErrors({...formErrors, userBirthdayDate: null});
		setUserBirthdayDate(fieldValue);
	}
	
	
	const changeUserNewsletterStatus = (status) => {
		if (status) setFormErrors({...formErrors, userNewsletterStatus: null});
		setUserNewsletterStatus(status);
	}
	
	const changeRegulationAcceptedStatus = (status) => {
		if (status) setFormErrors({...formErrors, regulationAcceptedStatus: null});
		setRegulationAcceptedStatus(status);
	}
	
	
	const submitRegisterForm = async() => {
		
		const registerFormValidData = registerFormSubmitAttempt(
			{
				userFirstName, userLastName, userEmailAddress, userPassword, userPasswordRepeat,
				userInvoice, userCompanyName, userCompanyDNI, userCompanyStreet, userCompanyHouseNo, userCompanyPostCode, userCompanyCity,
				userBirthdayPresent, userBirthdayDate,
				regulationAcceptedStatus
			},
			setFormErrors,
			ACTIVE_LANGUAGE
		);
		
		if (!registerFormValidData) return null;
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const registerFormApiResponse = await registerFormApiRequest(
			registerFormValidData, REST_API_URL
		)
		
		if (registerFormApiResponse.responseError) {
			setFormErrors({responseError: registerFormApiResponse.message});
			scrollToDomElement(CONTAINER_REF.current, 130);
		} else if (registerFormApiResponse.responseSuccess) {
			DISPATCHER(LOG_USER_IN_ACTION(registerFormApiResponse.registeredUser));
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
 
	
	if (LOGGED_USER && LOGGED_USER.id) return null;
	
	
	const {
		header_text: headerText, legend_text: legendText,
		name_label: nameLabel, surname_label: surnameLabel, email_label: emailLabel,
		password_label: passwordLabel, password_repeat_label: passwordRepeatLabel,
		invoice_label: invoiceLabel,
		yes_text: yesText, no_text: noText,
		company_name_label: companyNameLabel, company_dni_label: companyDniLabel,
		company_street_label: companyStreetLabel, company_house_no_label: companyHouseNoLabel,
		company_postcode_label: companyPostcodeLabel, company_city_label: companyCityLabel,
		user_type_label: userTypeLabel, buy_label: buyLabel, sell_label: sellLabel, middleman_label: middlemanLabel,
		for_myself_label: forMyselfLabel, for_investment_label: forInvestmentLabel,
		birthday_present_label: birthdayPresentLabel, birthday_date_label: birthdayDateLabel,
		newsletter_label: newsletterLabel, regulations_label: regulationsLabel,
		button_text: buttonText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<Row ref={CONTAINER_REF} className={"register-form-component form-component"}>
		
			
			<FormHeaderComponent
				headerText={headerText}
				cssClasses={"register-form-header"}
			/>
			
			{formErrors.responseError &&
			<Col xs={12} as={Alert} variant={"danger"}>
				<span>{formErrors.responseError}</span>
			</Col>
			}
			
			<FormLegendComponent
				cssClasses={"register-form-legend"}
			>
				<span className={"asterisk"}>*</span>
				<span className={"text-label"}>{legendText}</span>
			</FormLegendComponent>
			
			
			<FormTextFieldComponent
				fieldValue={userFirstName}
				fieldValueHandler={changeUserFirstName}
				fieldLabel={nameLabel}
				fieldError={formErrors?.userFirstName || null}
				fieldControlID={"user-first-name"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userLastName}
				fieldValueHandler={changeUserLastName}
				fieldLabel={surnameLabel}
				fieldError={formErrors?.userLastName || null}
				fieldControlID={"user-last-name"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userEmailAddress}
				fieldValueHandler={changeUserEmailAddress}
				fieldLabel={emailLabel}
				fieldError={formErrors?.userEmailAddress || null}
				fieldControlID={"user-email-address"}
				fieldType={"email"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userPassword}
				fieldValueHandler={changeUserPassword}
				fieldLabel={passwordLabel}
				fieldError={formErrors?.userPassword || null}
				fieldControlID={"user-password"}
				fieldType={"password"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userPasswordRepeat}
				fieldValueHandler={changeUserPasswordRepeat}
				fieldLabel={passwordRepeatLabel}
				fieldError={formErrors?.userPasswordRepeat || null}
				fieldControlID={"user-password-repeat"}
				fieldType={"password"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			
			<FormSwitchFieldComponent
				switchMainLabelText={invoiceLabel}
				option1LabelText={noText}
				option2LabelText={yesText}
				checkedStatus={userInvoice}
				changeHandler={changeUserInvoiceStatus}
				switchId={"invoice-status-switch"}
				containerCssClasses={"invoice-switch-field"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			{userInvoice && <>
			<FormTextFieldComponent
				fieldValue={userCompanyName}
				fieldValueHandler={changeUserCompanyName}
				fieldLabel={companyNameLabel}
				fieldError={formErrors.userCompanyName || null}
				fieldControlID={"user-company-name"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userCompanyDNI}
				fieldValueHandler={changeUserCompanyDNI}
				fieldLabel={companyDniLabel}
				fieldError={formErrors.userCompanyDNI || null}
				fieldControlID={"user-company-dni"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userCompanyStreet}
				fieldValueHandler={changeUserCompanyStreet}
				fieldLabel={companyStreetLabel}
				fieldError={formErrors.userCompanyStreet || null}
				fieldControlID={"user-company-street"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userCompanyHouseNo}
				fieldValueHandler={changeUserCompanyHouseNo}
				fieldLabel={companyHouseNoLabel}
				fieldError={formErrors.userCompanyHouseNo || null}
				fieldControlID={"user-company-house-no"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userCompanyPostCode}
				fieldValueHandler={changeUserCompanyPostCode}
				fieldLabel={companyPostcodeLabel}
				fieldError={formErrors.userCompanyPostCode || null}
				fieldControlID={"user-company-post-code"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={userCompanyCity}
				fieldValueHandler={changeUserCompanyCity}
				fieldLabel={companyCityLabel}
				fieldError={formErrors.userCompanyCity}
				fieldControlID={"user-company-city"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			</>}
			
			
			<Col xs={12} className={"form-field radio-field transaction-type-switch"}>
				<Form.Label>{userTypeLabel}</Form.Label>
				<Row className={"form-field-radio"}>
					<Col xs={12} className={"radio-field-option"}>
						<span
							className={"radio-input" + (userTransactionType === 1 ? " checked" : "")}
							onClick={() => changeUserTransactionType(userTransactionType === 1 ? 0 : 1)}
						></span>
						<Form.Label
							onClick={() => changeUserTransactionType(userTransactionType === 1 ? 0 : 1)}
							className={"radio-input-label"}
						>{buyLabel}
						</Form.Label>
					</Col>
					{userTransactionType === 1 && <>
					<Col xs={12} className={"radio-field-option radio-field-sub-option"}>
							<span
								className={"radio-input" + (userTransactionSubType === 1 ? " checked" : "")}
								onClick={() => setUserTransactionSubType(userTransactionSubType === 1 ? 0 : 1)}
							></span>
						<Form.Label
							onClick={() => setUserTransactionSubType(userTransactionSubType === 1 ? 0 : 1)}
							className={"radio-input-label"}
						>{forMyselfLabel}
						</Form.Label>
					</Col>
					<Col xs={12} className={"radio-field-option radio-field-sub-option"}>
							<span
								className={"radio-input" + (userTransactionSubType === 2 ? " checked" : "")}
								onClick={() => setUserTransactionSubType(userTransactionSubType === 2 ? 0 : 2)}
							></span>
						<Form.Label
							onClick={() => setUserTransactionSubType(userTransactionSubType === 2 ? 0 : 2)}
							className={"radio-input-label"}
						>{forInvestmentLabel}
						</Form.Label>
					</Col>
					</>}
					<Col xs={12} className={"radio-field-option"}>
						<span
							className={"radio-input" + (userTransactionType === 2 ? " checked" : "")}
							onClick={() => changeUserTransactionType(userTransactionType === 2 ? 0 : 2)}
						></span>
						<Form.Label
							onClick={() => changeUserTransactionType(userTransactionType === 2 ? 0 : 2)}
							className={"radio-input-label"}
						>{sellLabel}
						</Form.Label>
					</Col>
					{userTransactionType === 2 && <>
					<Col xs={12} className={"radio-field-option radio-field-sub-option"}>
							<span
								className={"radio-input" + (userTransactionSubType === 1 ? " checked" : "")}
								onClick={() => setUserTransactionSubType(userTransactionSubType === 1 ? 0 : 1)}
							></span>
						<Form.Label
							onClick={() => setUserTransactionSubType(userTransactionSubType === 1 ? 0 : 1)}
							className={"radio-input-label"}
						>{forMyselfLabel}
						</Form.Label>
					</Col>
					<Col xs={12} className={"radio-field-option radio-field-sub-option"}>
							<span
								className={"radio-input" + (userTransactionSubType === 2 ? " checked" : "")}
								onClick={() => setUserTransactionSubType(userTransactionSubType === 2 ? 0 : 2)}
							></span>
						<Form.Label
							onClick={() => setUserTransactionSubType(userTransactionSubType === 2 ? 0 : 2)}
							className={"radio-input-label"}
						>{forInvestmentLabel}
						</Form.Label>
					</Col>
					</>}
					<Col xs={12} className={"radio-field-option"}>
						<span
							className={"radio-input" + (userTransactionType === 3 ? " checked" : "")}
							onClick={() => changeUserTransactionType(userTransactionType === 3 ? 0 : 3)}
						></span>
						<Form.Label
							onClick={() => changeUserTransactionType(userTransactionType === 3 ? 0 : 3)}
							className={"radio-input-label"}
						>{middlemanLabel}
						</Form.Label>
					</Col>
				</Row>
			</Col>
			
			
			<FormSwitchFieldComponent
				switchMainLabelText={birthdayPresentLabel}
				option1LabelText={noText}
				option2LabelText={yesText}
				checkedStatus={userBirthdayPresent}
				changeHandler={changeBirthdayPresentStatus}
				switchId={"birthday-status-switch"}
				containerCssClasses={"birthday-status-switch"}
			/>
			
			{userBirthdayPresent &&
			<FormTextFieldComponent
				fieldValue={userBirthdayDate}
				fieldValueHandler={changeUserBirthdayDate}
				fieldLabel={birthdayDateLabel}
				fieldError={formErrors.userBirthdayDate || null}
				fieldControlID={"user-birthday-date"}
				fieldType={"date"}
			/>
			}
			
			
			<FormCheckboxFieldComponent
				fieldLabel={newsletterLabel}
				checkedStatus={userNewsletterStatus}
				clickHandler={() => changeUserNewsletterStatus(!userNewsletterStatus)}
				containerCssClasses={"newsletter-field"}
			/>
			
			
			<FormCheckboxFieldComponent
				fieldLabel={regulationsLabel}
				checkedStatus={regulationAcceptedStatus}
				clickHandler={() => changeRegulationAcceptedStatus(!regulationAcceptedStatus)}
				fieldError={formErrors.regulationAcceptedStatus}
			/>
			
			
			<FormSubmitFieldComponent
				buttonText={buttonText}
				submitHandler={e => submitRegisterForm(e)}
			/>
			
		
		</Row>
	);
	
};


export default RegisterFormComponent;
