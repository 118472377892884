import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Podaj liczbę pomieszczeń",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		
	},
	EN: {
		texts: {
			step_header: "Enter the number of rooms",
			button_text: "Next",
			return_text: "Back"
		},
		
	}
};


export const CheckFormStep7SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatRooms
	} = formData;
	
	
	const [checkFlatFormDataFlatRooms, setCheckFlatFormDataFlaRooms] = useState(flatRooms || "0");
	const [formStepSubmitAttemptStatus, setFormStepSubmitAttemptStatus] = useState(false);
	const [formStepErrors, setFormStepErrors] = useState({flatRooms: null});
	
	const changeCheckFlatFormDataFlatRooms = (rooms) => {
		if (formStepSubmitAttemptStatus) {
			setFormStepErrors({
				flatRooms: checkFlatFormDataFlatRooms !== "0" ? null : {message: "Proszę wybrać ilość pomieszczeń"}
			});
		}
		setCheckFlatFormDataFlaRooms(rooms);
	};
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const submitCheckFlatFormStep = (reset = false) => {
		setFormStepSubmitAttemptStatus(true);
		if (checkFlatFormDataFlatRooms === "0" && !reset) {
			setFormStepErrors({
				flatRooms: {message: "Proszę wybrać ilość pomieszczeń"}
			});
			return null;
		}
		formDataHandler({
			flatRooms: reset ? null : checkFlatFormDataFlatRooms
		}, reset ? 5 : 7);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-7-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Liczba pomieszczeń" : "Number of rooms"}</Form.Label>
						<Form.Control
							as={"select"}
							value={checkFlatFormDataFlatRooms}
							onChange={e => changeCheckFlatFormDataFlatRooms(e.target.value)}
						>
							<option value={"0"}>- Wybierz -</option>
							<option value={"1"}>1</option>
							<option value={"2"}>2</option>
							<option value={"3"}>3</option>
							<option value={"4"}>4</option>
							<option value={"5"}>5</option>
						</Form.Control>
						{formStepErrors.flatRooms &&
						<Form.Label className={"error"}>{formStepErrors.flatRooms.message}</Form.Label>
						}
					</Col>
				</Row>
			</Col>
			
			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} />
			
		
		</Row>
	);
	
};


export default CheckFormStep7SectionComponent;
