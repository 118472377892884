import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Jakie jest prawo do tego mieszkania?",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		
	},
	EN: {
		texts: {
			step_header: "What is the right to this apartment?",
			button_text: "Next",
			return_text: "Back"
		},
		
	}
};


const RIGHTS = {
	PL: {1: "Własnościowe", 2: "Spółdzielcze"},
	EN: {1: "Ownership", 2: "Cooperative"},
};


export const CheckFormStep6SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler,
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatPropertyType
	} = formData;
	
	
	const [checkFlatFormDataFlatPropertyType, setCheckFlatFormDataFlatPropertyType] = useState(flatPropertyType || null);
	
	const changeCheckFlatFormDataFlatPropertyType = (type) => {
		setCheckFlatFormDataFlatPropertyType(type);
		setFormStepErrors({flatPropertyType: null});
	};
	
	const [formStepErrors, setFormStepErrors] = useState({flatPropertyType: null});
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const submitCheckFlatFormStep = (reset = false) => {
		if (!checkFlatFormDataFlatPropertyType && !reset) {
			setFormStepErrors({
				flatPropertyType: {message:
					activeLanguage === "PL" ? "Proszę wybrać rodzaj własności": "Please choose rights type"
				}
			});
			return null;
		}
		formDataHandler({
			flatPropertyType: reset ? null : checkFlatFormDataFlatPropertyType
		}, reset ? 4 : 6);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-6-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatPropertyType === "1" ? " selected" : "")}
							onClick={() => changeCheckFlatFormDataFlatPropertyType("1")}
						></span>
						<label
							className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatPropertyType === "1" ? " active" : "")}
							onClick={() => changeCheckFlatFormDataFlatPropertyType("1")}
						>
							{RIGHTS[activeLanguage][1]}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatPropertyType === "2" ? " selected" : "")}
							onClick={() => changeCheckFlatFormDataFlatPropertyType("2")}
						></span>
						<label
							className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatPropertyType === "2" ? " active" : "")}
							onClick={() => changeCheckFlatFormDataFlatPropertyType("2")}
						>
							{RIGHTS[activeLanguage][2]}
						</label>
					</Col>
				</Row>
				
				{formStepErrors.flatPropertyType &&
				<Row>
					<Form.Label className={"error"}>{formStepErrors.flatPropertyType.message}</Form.Label>
				</Row>
				}
				
			</Col>
			
			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} />
			
		</Row>
	);
	
};


export default CheckFormStep6SectionComponent;
