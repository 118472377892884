import React from "react";


import {Col, Row} from "react-bootstrap";

import {AiOutlinePlaySquare} from "react-icons/all";


import checkFlatLogo from "@Assets/Images/checkflat192.png";


export const StepsHeaderProgressBarComponent = ({
	checkFormStep = null
}) => {
	
	
	if (!checkFormStep) return null;
	
	
	let progressBarEstimatedTime = "~3min";
	if (checkFormStep > 3) {
		progressBarEstimatedTime = "~2min";
	}
	if (checkFormStep > 7) {
		progressBarEstimatedTime = "~1min";
	}
	if (checkFormStep > 9) {
		progressBarEstimatedTime = "sprawdź!";
	}
	
	
	return (
		<Col xs={12} className={"check-form-steps-progress-bar"}>
			<AiOutlinePlaySquare className="progress-bar-icon start-icon" />
			<Row className="progress-bar-track"></Row>
			<Row className="progress-bar-level" style={{width: `${(checkFormStep - 1) * 11 + 1}%`}}></Row>
			<img src={checkFlatLogo} alt={`CheckFlat`} className="progress-bar-icon finish-icon" />
			<span className="progress-bar-time">{progressBarEstimatedTime}</span>
		</Col>
	);
	
};


export default StepsHeaderProgressBarComponent;

