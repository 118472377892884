import React from "react";


import {Container, Row, Col, Button} from "react-bootstrap"


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import imagePlaceholder from "@Assets/Images/no-image_s.png";


export const PricingPageBenefitsSectionComponent = ({sectionData}) => {

	
	if (!sectionData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		headerText, buttonText, benefitsElements
	} = sectionData;
	
	
	const benefitsElementsList = benefitsElements.map((benefit, i) => {
		const {benefit_text: benefitText} = benefit;
		return (
			<Col key={i} xs={12} lg={4} className={"pricing-single-benefit"}>
				<Row className={"pricing-single-benefit-content"}>
					<img src={imagePlaceholder} alt={""} />
					<span>{benefitText}</span>
				</Row>
			</Col>
		);
	});
	
	
	return (
		<Container as="section" fluid={true} id="pricing-page-benefits-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col xs={12} className={"block-section-header text-center"}>
						<span>{headerText}</span>
					</Col>
					
					<Col xs={12} className={"pricing-page-benefits"}>
						<Row className={"pricing-page-benefits-list"}>
							{benefitsElementsList}
						</Row>
					</Col>
					
					<Col xs={12} className={"block-section-footer text-center"}>
						<Button onClick={e => e.preventDefault()} className={"btn-large"}>
							<span>{buttonText}</span>
						</Button>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);

};


export default PricingPageBenefitsSectionComponent;

