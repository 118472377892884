import React from "react";


import {Col} from "react-bootstrap";


import OwnedTeamHeaderComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Header";
import OwnedTeamMembersComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members";


export const OwnedTeamDataComponent = ({
	userEmailAddress,
	teamData = null, teamDataHandler = null,
	teamMaximumMembers = 0
}) => {
	
	
	if (!teamData) return null;
	
	
	const {
		url: teamApiURL,
		users_team_name: teamName,
		users_team_default_checks_option: teamDefaultChecksOption,
		users_team_users: teamMembers
	} = teamData;
	
	
	return (
		<Col xs={12} className={"user-owned-team-component"}>
		
			<OwnedTeamHeaderComponent
				usersTeamName={teamName}
			/>
			
			<OwnedTeamMembersComponent
				userEmailAddress={userEmailAddress}
				teamApiURL={teamApiURL}
				teamMembers={teamMembers}
				teamMembersHandler={teamDataHandler}
				teamDefaultChecksOption={teamDefaultChecksOption}
				teamMaximumMembers={teamMaximumMembers}
			/>
		
		</Col>
	);
	
};


export default OwnedTeamDataComponent;
