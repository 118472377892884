import React, {useContext} from "react";

import {useSelector} from "react-redux";

import TranslationsContext from "@Contexts";

import {Col} from "react-bootstrap";


import {BsArrowLeft} from "react-icons/all";


export const CheckFormBackButtonComponent = ({
	xs = 6, lg = 6,
	buttonText = "",
	clickHandler = null,
	...props
}) => {
	
	
	const activeLanguage = useSelector(state => state.localization).activeLanguage.languageCode;
	const WORDS = useContext(TranslationsContext).SINGLE_WORDS;

	buttonText = buttonText || (WORDS?.back[activeLanguage] || "previous");

	
	const buttonClickHandler = (e) => {
		e.preventDefault();
		if (clickHandler) {
			clickHandler();
		}
	};
	
	
	return (
		<Col xs={xs} lg={lg} {...props} className={"user-form-footer-return"}>
			<a href={"#back"} title={""} onClick={e => buttonClickHandler(e)} className={"arrow-return-button"}>
				<BsArrowLeft />
				<span>{buttonText}</span>
			</a>
		</Col>
	)
	
};


export default CheckFormBackButtonComponent;
