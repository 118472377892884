import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


const TEXTS = {
	PL: {load_all_text: "Załaduj wszystkie", load_less_text: "Załaduj mniej"},
	EN: {load_all_text: "Load all", load_less_text: "Load less"},
};


export const LoadAllPaymentsComponent = ({
	loadAllPaymentsStatus, loadAllPaymentsStatusHandler
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {load_all_text: loadAllText, load_less_text: loadLessText} = TEXTS[ACTIVE_LANGUAGE];
	
	return (
		<Row className={"load-all-payments"}>
			<Col xs={12} className={"all-payments-switcher"}>
				<a href={"#load-all-payments"} title={loadAllPaymentsStatus ? loadLessText : loadAllText} onClick={e => loadAllPaymentsStatusHandler(e)}>
					<span>{loadAllPaymentsStatus ? loadLessText : loadAllText}</span>
				</a>
			</Col>
		</Row>
	)
	
};


export default LoadAllPaymentsComponent;
