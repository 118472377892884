import React from "react";


import {Col} from "react-bootstrap";


export const FormLegendComponent = ({children, cssClasses = ""}) => {
	
	
	if (!children) return null;
	
	
	cssClasses = "form-legend" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col xs={12} as={"legend"} className={cssClasses}>
			{children}
		</Col>
	);
	
}


export default FormLegendComponent;
