import React, {useEffect, useState} from "react";


import {Row, Col} from "react-bootstrap"


import CheckFormNextButtonComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import {CheckFormCheckboxComponent} from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Fields/Checkbox";
import CheckFormHeaderComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";
import CheckFormContentComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Content";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Powiedz nam co planujesz, żebyśmy mogli lepiej pomóc Ci w Twojej decyzji",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		fields: {
			user_type: {
				1: {
					label: "Chcę kupić mieszkanie",
					user_sub_type: {
						1: {label: "dla siebie"},
						2: {label: "pod inwestycję"}
					}
				},
				2: {
					label: "Chcę sprzedać mieszkanie",
					user_sub_type: {
						1: {label: "dla siebie"},
						2: {label: "pod inwestycję"}
					}
				},
				3: {label: "Jestem pośrednikiem",}
			}
		}
	},
	EN: {
		texts: {
			step_header: "Tell us what you are planning so that we can better help you with your decision",
			button_text: "Next",
			return_text: "Back"
		},
		fields: {
			user_type: {
				1: {
					label: "I want to buy an apartment",
					user_sub_type: {
						1: {label: "for myself"},
						2: {label: "for investment"}
					}
				},
				2: {
					label: "I want to sell my flat",
					user_sub_type: {
						1: {label: "for myself"},
						2: {label: "for investment"}
					}
				},
				3: {label: "I am an intermediary",}
			}
		}
	},
};


export const CheckFormStep1SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler,
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage];
	
	
	const {
		userType, userSubType
	} = formData;
	
	
	const [checkFlatFormDataUserType, setCheckFlatFormDataUserType] = useState(userType || "1");
	const [checkFlatFormDataUserSubType, setCheckFlatFormDataUserSubType] = useState(userSubType || "1");
	
	
	const changeCheckFlatFormDataUserType = (type) => {
		setCheckFlatFormDataUserType(type);
		setCheckFlatFormDataUserSubType("1");
	};
	
	useEffect(() => {
		return () => {
			setCheckFlatFormDataUserType("1");
			setCheckFlatFormDataUserSubType("1");
		};
	}, []);
	
	
	const submitCheckFlatFormStep = () => {
		formDataHandler({
			userType: checkFlatFormDataUserType,
			userSubType: checkFlatFormDataUserSubType
		}, 1);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-1-component user-form-component"}>
			
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			
			<CheckFormContentComponent lg={6}>
				
				<CheckFormCheckboxComponent
					labelText={SECTION_DATA["fields"]["user_type"]["1"]["label"]}
					selected={checkFlatFormDataUserType === "1"}
					clickHandler={() => changeCheckFlatFormDataUserType("1")}
				>
					{checkFlatFormDataUserType === "1" &&
					<Col xs={12} className={"step-option-sub-options user-form-option-sub-options"}>
						<CheckFormCheckboxComponent
							xs={12}
							labelText={SECTION_DATA["fields"]["user_type"]["1"]["user_sub_type"]["1"]["label"]}
							selected={checkFlatFormDataUserSubType === "1"}
							clickHandler={() => setCheckFlatFormDataUserSubType("1")}
							cssClasses={{fieldInput: "small-input", fieldLabel: "small-label"}}
						/>
						<CheckFormCheckboxComponent
							xs={12}
							labelText={SECTION_DATA["fields"]["user_type"]["1"]["user_sub_type"]["2"]["label"]}
							selected={checkFlatFormDataUserSubType === "2"}
							clickHandler={() => setCheckFlatFormDataUserSubType("2")}
							cssClasses={{fieldInput: "small-input", fieldLabel: "small-label"}}
						/>
					</Col>
					}
				</CheckFormCheckboxComponent>
				
				<CheckFormCheckboxComponent
					labelText={SECTION_DATA["fields"]["user_type"]["2"]["label"]}
					selected={checkFlatFormDataUserType === "2"}
					clickHandler={() => changeCheckFlatFormDataUserType("2")}
				>
					{checkFlatFormDataUserType === "2" &&
					<Col xs={12} className={"step-option-sub-options user-form-option-sub-options"}>
						<CheckFormCheckboxComponent
							xs={12}
							labelText={SECTION_DATA["fields"]["user_type"]["2"]["user_sub_type"]["1"]["label"]}
							selected={checkFlatFormDataUserSubType === "1"}
							clickHandler={() => setCheckFlatFormDataUserSubType("1")}
							cssClasses={{fieldInput: "small-input", fieldLabel: "small-label"}}
						/>
						<CheckFormCheckboxComponent
							xs={12}
							labelText={SECTION_DATA["fields"]["user_type"]["2"]["user_sub_type"]["2"]["label"]}
							selected={checkFlatFormDataUserSubType === "2"}
							clickHandler={() => setCheckFlatFormDataUserSubType("2")}
							cssClasses={{fieldInput: "small-input", fieldLabel: "small-label"}}
						/>
					</Col>
					}
				</CheckFormCheckboxComponent>
				
				<CheckFormCheckboxComponent
					labelText={SECTION_DATA["fields"]["user_type"]["3"]["label"]}
					selected={checkFlatFormDataUserType === "3"}
					clickHandler={() => changeCheckFlatFormDataUserType("3")}
				/>
				
			</CheckFormContentComponent>

			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} />
			
			
		</Row>
	);
	
	
};


export default CheckFormStep1SectionComponent;
