import React, {useState, useEffect} from "react";

import {useSelector} from "react-redux";


import isEmail from "validator/es/lib/isEmail";


import {Row, Col} from "react-bootstrap";


import FormTextFieldComponent from "@Elements/Forms/Fields/Text";
import FormSelectFieldComponent from "@Elements/Forms/Fields/Select";
import FormTextareaFieldComponent from "@Elements/Forms/Fields/Textarea";
import FormSubmitFieldComponent from "@Elements/Forms/Fields/Submit";


const TEXTS = {
	PL: {
		first_name_label_text: "Twoje imię ", last_name_label_text: "Twoje  nazwisko", email_address_label_text: "Twój adres e-mail",
		message_title_label_text: "Napisz jak możemy Ci pomóc", message_content_label_text: "Opisz temat, żebyśmy mogli Ci pomóc ...",
		send_button_text: "Wyślij"
	},
	EN: {
		first_name_label_text: "Your Name", last_name_label_text: "Your Surname", email_address_label_text: "Your Email Address",
		message_title_label_text: "Write how can we help You", message_content_label_text: "Describe subject as can we help You ...",
		send_button_text: "Send"
	}
}


const CONTACT_FORM_SUBJECTS = {
	PL: [
		{optionValue: "1", optionText: "Dedykowany / Indyvidual pakiet"},
		{optionValue: "2", optionText: "Niestandardowe sprawdzenie"},
		{optionValue: "3", optionText: "Mam pytania do analizy"},
		{optionValue: "4", optionText: "Potrzebuję pomocy technicznej"},
		{optionValue: "5", optionText: "Płatności"},
		{optionValue: "6", optionText: "Mam propozycje współpracy"},
		{optionValue: "6", optionText: "Inne"},
	],
	EN: [
		{optionValue: "1", optionText: "Dedicated / Individual package"},
		{optionValue: "2", optionText: "Custom check"},
		{optionValue: "3", optionText: "I have questions to analyze"},
		{optionValue: "4", optionText: "I need technical assistance"},
		{optionValue: "5", optionText: "Payment"},
		{optionValue: "6", optionText: "I have cooperation proposals"},
		{optionValue: "6", optionText: "Other"},
	]
};


const FORM_ERROR_MESSAGES = {
	PL: {
		first_name_field_error_message: "Proszę podać imię",
		last_name_field_error_message: "Proszę podać nazwisko",
		email_address_field_error_message: "Podany adres email jest niepoprawny",
		message_subject_field_error_message: "Proszę wybrać temat wiadomości",
		message_title_field_error_message: "Proszę podać tytuł wiadomości",
		message_content_field_error_message: "Proszę podać treść wiadomośći",
	},
	EN: {
		first_name_field_error_message: "Please enter Your Name",
		last_name_field_error_message: "Please enter Your Surname",
		email_address_field_error_message: "Please enter Your Email Address",
		message_subject_field_error_message: "Please select Message Subject",
		message_title_field_error_message: "Please enter Message Title",
		message_content_field_error_message: "Please enter Message Content",
	}
};


export const ContactPageFormSectionComponent = ({
	contactFormErrors, contactFormErrorsHandler,
	sendMessageHandler,
	messageSubject
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const {
		first_name_label_text: firstNameLabel, last_name_label_text: lastNameLabel, email_address_label_text: emailAddressLabel,
		message_title_label_text: messageTitleLabel, message_content_label_text: messageContentLabel,
		send_button_text: sendButtonText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	const {
		first_name_field_error_message: firstNameFieldErrorMessage, last_name_field_error_message: lastNameFieldErrorMessage,
		email_address_field_error_message: emailAddressFieldErrorMessage, message_subject_field_error_message: messageSubjectFieldErrorMessage,
		message_title_field_error_message: messageTitleFieldErrorMessage, message_content_field_error_message: messageContentFieldErrorMessage
	} = FORM_ERROR_MESSAGES[ACTIVE_LANGUAGE];
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	
	
	const [contactFormFirstName, setContactFormFirstName] = useState("");
	const [contactFormLastName, setContactFormLastName] = useState("");
	const [contactFormEmailAddress, setContactFormEmailAddress] = useState("");
	const [contactFormMessageSubject, setContactFormMessageSubject] = useState(messageSubject);
	const [contactFormMessageTitle, setContactFormMessageTitle] = useState("");
	const [contactFormMessageContent, setContactFormMessageContent] = useState("");
	
	useEffect(() => {
		return () => {
			setContactFormFirstName("");
			setContactFormLastName("");
			setContactFormEmailAddress("");
			setContactFormMessageSubject("0");
			setContactFormMessageTitle("");
			setContactFormMessageTitle("");
		};
	}, []);
	
	
	const changeContactFormFirstName = (value) => {
		if (value.length > 2) contactFormErrorsHandler({...contactFormErrors, firstName: null});
		setContactFormFirstName(value);
	};
	
	const changeContactFormLastName = (value) => {
		if (value.length > 2) contactFormErrorsHandler({...contactFormErrors, lastName: null});
		setContactFormLastName(value);
	};
	
	const changeContactFormEmailAddress = (value) => {
		if (isEmail(value)) contactFormErrorsHandler({...contactFormErrors, emailAddress: null});
		setContactFormEmailAddress(value);
	};
	
	const changeContactFormMessageSubject = (value) => {
		if (value !== "0") contactFormErrorsHandler({...contactFormErrors, messageSubject: null});
		setContactFormMessageSubject(value);
	};
	
	const changeContactFormMessageTitle = (value) => {
		if (value.length > 5) contactFormErrorsHandler({...contactFormErrors, messageTitle: null});
		setContactFormMessageTitle(value);
	};
	
	const changeContactFormMessageContent = (value) => {
		if (value.length > 10) contactFormErrorsHandler({...contactFormErrors, messageContent: null});
		setContactFormMessageContent(value);
	};
	
	
	const contactFormSubmitHandler = () => {
	
		const formErrors = {};
		
		if (contactFormFirstName.length < 3) formErrors.firstName = firstNameFieldErrorMessage;
		if (contactFormLastName.length < 3) formErrors.lastName = lastNameFieldErrorMessage;
		if (!isEmail(contactFormEmailAddress)) formErrors.emailAddress = emailAddressFieldErrorMessage;
		if (contactFormMessageSubject === "0") formErrors.messageSubject = messageSubjectFieldErrorMessage;
		if (contactFormMessageTitle.length < 6) formErrors.messageTitle = messageTitleFieldErrorMessage;
		if (contactFormMessageContent.length < 11) formErrors.messageContent = messageContentFieldErrorMessage;
		
		contactFormErrorsHandler(formErrors);
		
		if (Object.entries(formErrors).length > 0) return null;
		
		const messageSubject = CONTACT_FORM_SUBJECTS[ACTIVE_LANGUAGE][contactFormMessageSubject].optionText;
		
		const messageContent = `
			${contactFormFirstName} ${contactFormLastName}\n
			${contactFormMessageTitle}\n
			${contactFormMessageContent}
		`;
		const messageEmail = contactFormEmailAddress;
		
		sendMessageHandler({messageSubject, messageContent, messageEmail});
		
	};
	
	
	return (
		<Col xs={12} lg={{span: 10, offset: 1}} className={"contact-form-component"}>
			<Row className={"contact-form-content"}>
				
				<FormTextFieldComponent
					lg={6}
					fieldValue={contactFormFirstName}
					fieldValueHandler={changeContactFormFirstName}
					fieldError={contactFormErrors.firstName}
					fieldPlaceholder={firstNameLabel}
				/>
				
				<FormTextFieldComponent
					lg={6}
					fieldValue={contactFormLastName}
					fieldValueHandler={changeContactFormLastName}
					fieldError={contactFormErrors.lastName}
					fieldPlaceholder={lastNameLabel}
				/>
				
				<FormTextFieldComponent
					lg={6}
					fieldValue={contactFormEmailAddress}
					fieldValueHandler={changeContactFormEmailAddress}
					fieldError={contactFormErrors.emailAddress}
					fieldPlaceholder={emailAddressLabel}
				/>
				
				<FormSelectFieldComponent
					lg={6}
					fieldOptions={CONTACT_FORM_SUBJECTS[ACTIVE_LANGUAGE]}
					selectedFieldValue={contactFormMessageSubject}
					fieldValueHandler={changeContactFormMessageSubject}
					fieldError={contactFormErrors.messageSubject}
				/>
				
				<FormTextFieldComponent
					fieldValue={contactFormMessageTitle}
					fieldValueHandler={changeContactFormMessageTitle}
					fieldError={contactFormErrors.messageTitle}
					fieldPlaceholder={messageTitleLabel}
				/>
				
				<FormTextareaFieldComponent
					fieldValue={contactFormMessageContent}
					fieldValueHandler={changeContactFormMessageContent}
					fieldError={contactFormErrors.messageContent}
					fieldPlaceholder={messageContentLabel}
				/>
				
				<FormSubmitFieldComponent buttonText={sendButtonText} submitHandler={contactFormSubmitHandler} />
			
			</Row>
		</Col>
	);
	
};


export default ContactPageFormSectionComponent;
