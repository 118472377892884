import React from "react";

import PropTypes from 'prop-types';

import parse from "html-react-parser";


import {BLOCK_HTML_TAGS} from "@Utilities/Data/HTML";


import {Col, Form} from "react-bootstrap";


import FormErrorFieldComponent from "@Elements/Forms/Fields/Error";



export const FormCheckboxFieldComponent = ({
	checkedStatus = false, clickHandler = null,
	fieldLabel, fieldError = null, fieldDisabledStatus = false,
	containerCssClasses = "", checkboxCssClasses = "",
	xs = 12, sm = 12, lg = 12, xl = 12, as = "aside",
	...props
}) => {
	
	
	containerCssClasses = "form-field checkbox-field" + (containerCssClasses ? ` ${containerCssClasses}` : "");
	
	checkboxCssClasses = "checkbox-field" + (checkboxCssClasses ? ` ${checkboxCssClasses}` : "");
	checkboxCssClasses += checkedStatus ? " checked" : "";
	checkboxCssClasses += fieldError ? " error" : "";
	
	
	return (
	<>
		
		<Col
			as={as}
			xs={xs} sm={sm} lg={lg} xl={xl}
			className={containerCssClasses}
			{...props}
		>
			<span
				className={checkboxCssClasses}
				onClick={fieldDisabledStatus ? null : clickHandler}
			></span>
			<Form.Label
				className={"checkbox-label"}
				onClick={fieldDisabledStatus ? null : clickHandler}
			>
				{parse(fieldLabel)}
			</Form.Label>
		</Col>
		
		{fieldError && <FormErrorFieldComponent errorMessage={fieldError} />}
		
	</>
	);
	
};


FormCheckboxFieldComponent.propTypes = {
	checkedStatus: PropTypes.bool.isRequired,
	clickHandler: PropTypes.func,
	fieldLabel: PropTypes.string.isRequired,
	fieldError: PropTypes.string,
	as: PropTypes.oneOf(BLOCK_HTML_TAGS),
	xs: PropTypes.number,
	sm: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	containerCssClasses: PropTypes.string,
	buttonVariant: PropTypes.string,
	buttonCssClasses: PropTypes.string,
};


export default FormCheckboxFieldComponent;
