import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";
import {useSelector} from "react-redux";


export const NavigationLinkComponent = ({linkData}) => {
	
	
	const activeLanguage = useSelector(state => state.localization.activeLanguage?.languageCode)
	
	
	const {
		// navigation_link_id: linkID,
		navigation_link_url: linkURL,
		navigation_link_title: linkTitle
	} = linkData;
	
	
	return (
		<Col as="li" className="header-navigation-item">
			<NavLink to={linkURL[activeLanguage]} title={linkTitle[activeLanguage]} className="header-navigation-link">
				<span>{linkTitle[activeLanguage]}</span>
			</NavLink>
		</Col>
	);
	
};


export default NavigationLinkComponent;
