import React from "react";

import parse from "html-react-parser";


import {Col} from "react-bootstrap";


export const FormHeaderComponent = ({headerText = "", cssClasses = ""}) => {
	
	
	if (!headerText) return null;
	
	
	cssClasses = "form-header" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col xs={12} as={"h2"} className={cssClasses}>
			<span>{parse(headerText)}</span>
		</Col>
	);
	
}


export default FormHeaderComponent;
