import React from "react";


import {Col} from "react-bootstrap";


export const CheckFormContentComponent = ({xs = 12, lg = 12, children, ...props}) => {
	
	
	return (
		<Col {...props} xs={xs} lg={lg} className="check-form-step-content user-form-content">
			{children}
		</Col>
	);
	
};


export default CheckFormContentComponent;
