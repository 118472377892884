import React, {useEffect, useRef} from "react";

import parse from "html-react-parser";


import {Row, Col} from "react-bootstrap"


import scrollToDomElement from "@Utilities/Scrolling";


import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";
import CheckFormConfiguredOptionComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Fields/Configured";
import CheckFormContentComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Content";


import imagePlaceholder from "@Assets/Images/no-image_s.png";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Jeszcze chwila i poznasz wartość mieszkania",
			localization_label: "Lokalizacja",
			market_label: "Rynek",
			yardage_label: "Metraż",
			storey_label: "Kondygnacja",
			rights_label: "Prawo do mieszkania",
			rooms_label: "Liczba pomieszczeń",
			extras_label: "Dodatkowo posiada",
			standard_label: "Standard Nieruchomości",
			button_text: "Sprawdź wartość",
			return_text: "Wróć",
			alert_text: "Wkrótce"
		},
	},
	EN: {
		texts: {
			step_header: "What market is the apartment you are checking from?",
			localization_label: "Localization",
			market_label: "Market",
			yardage_label: "Yardage",
			storey_label: "Storey",
			rights_label: "Right to housing",
			rooms_label: "Number of rooms",
			extras_label: "Has additionally",
			standard_label: "Property Standard",
			button_text: "Check the value",
			return_text: "Back",
			alert_text: "Soon"
		},
		
	}
};


const EXTRAS = {
	PL: {
		flatLuxuryKitchen: "Osobną jasną kuchnię",
		flatBalcony: "Balkon / Taras",
		flatLoggie: "Loggie",
		flatCellar: "Piwnicę",
		flatAttic: "Poddasze",
		flatBin: "Komórka Lokatorska",
		flatElevator: "Winda"
	},
	EN: {
		flatLuxuryKitchen: "A separate bright kitchen",
		flatBalcony: "Balcony / Terrace",
		flatLoggie: "Loggie",
		flatCellar: "Basement",
		flatAttic: "Attic",
		flatBin: "A storage room",
		flatElevator: "Elevator"
	}
};


const MARKET = {
	PL: {1: "Pierwotny", 2: "Wtórny"},
	EN: {1: "Primary", 2: "Secondary"},
};


const FLOOR = {
	PL: "piętro",
	EN: "floor",
};


const RIGHTS = {
	PL: {1: "Własnościowe", 2: "Spółdzielcze"},
	EN: {1: "Ownership", 2: "Cooperative"},
};


const ROOMS = {
	PL: {1: "pomieszczenie", 2: "pomieszczenia", 3: "pomieszczeń"},
	EN: {1: "room", 2: "rooms", 3: "rooms"},
};


const STANDARDS = {
	PL: {1: "Wysoki", 2: "Dobry", 3: "Do odświeżenia", 4: "Do generalnego remontu", 0: "Nie wybrano"},
	EN: {1: "High", 2: "Good", 3: "To refresh", 4: "For general renovation", 0: "Not selected"},
};


export const CheckFormFinalStepComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler,
	formStepHandler
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage];
	
	
	const {
		addressCity, addressStreet, addressHouseNo,
		marketType,
		flatYardage, flatStorey, flatPropertyType, flatRooms, flatStandard,
		flatLuxuryKitchen, flatBalcony, flatLoggie, flatCellar, flatAttic, flatBin, flatElevator
	} = formData;
	
	
	const addressValue = addressCity + (addressStreet ? ", " + addressStreet : "") + (addressHouseNo ? " " + addressHouseNo : "");
	const marketTypeValue = MARKET[activeLanguage][marketType];
	const flatYardageValue = flatYardage + " m<sup>2</sup>";
	const flatStoreyValue = flatStorey + " " + FLOOR[activeLanguage];
	const flatPropertyTypeValue = RIGHTS[activeLanguage][flatPropertyType];
	const flatRoomsValue = flatRooms + " " + (+flatRooms === 1 ? ROOMS[activeLanguage][1] : (flatRooms < 5 ? ROOMS[activeLanguage][2] :ROOMS[activeLanguage][3]));
	
	
	const extras = Object.entries(
		{flatLuxuryKitchen, flatBalcony, flatLoggie, flatCellar, flatAttic, flatBin, flatElevator}
	).filter(([key, value]) => value).map(([key, value]) => EXTRAS[activeLanguage][key]);
	const extrasValue = extras.join(", ");
	
	
	const submitCheckFlatFormStep = (reset = false) => {
		formDataHandler({
			flatStandard: reset ? null : null
		}, reset ? 8 : "REQUEST");
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-6-component user-form-component"}>
			
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			
			<CheckFormContentComponent lg={6}>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["localization_label"]}
					elementValue={addressValue}
					elementHandler={() => formStepHandler(2)}
				/>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["market_label"]}
					elementValue={marketTypeValue}
					elementHandler={() => formStepHandler(3)}
				/>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["yardage_label"]}
					elementValue={parse(flatYardageValue)}
					elementHandler={() => formStepHandler(4)}
				/>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["storey_label"]}
					elementValue={flatStoreyValue}
					elementHandler={() => formStepHandler(5)}
				/>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["rights_label"]}
					elementValue={flatPropertyTypeValue}
					elementHandler={() => formStepHandler(6)}
				/>
				
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["rooms_label"]}
					elementValue={flatRoomsValue}
					elementHandler={() => formStepHandler(7)}
				/>
				
				{extras.length > 0 &&
				<CheckFormConfiguredOptionComponent
					elementLabel={SECTION_DATA["texts"]["extras_label"]}
					elementValue={extrasValue}
					elementHandler={() => formStepHandler(8)}
				/>
				}
				
			</CheckFormContentComponent>
			
			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} cssClasses={"final-step-info"}>
				<Row className={"user-form-info-label"}>
					<CheckFormConfiguredOptionComponent
						elementLabel={SECTION_DATA["texts"]["standard_label"]}
						elementValue={flatStandard ? STANDARDS[activeLanguage][flatStandard] : STANDARDS[activeLanguage][0]}
						elementHandler={() => formStepHandler(9)}
					/>
				</Row>
				<Row as="picture" className={"check-form-step-info-picture"}>
					<img src={imagePlaceholder} alt={""}/>
				</Row>
			</CheckFormInformationComponent>
			
		</Row>
	);
	
};


export default CheckFormFinalStepComponent;
