import React from "react";


import {Row, Col} from "react-bootstrap";


import {GiCheckMark} from "react-icons/all";


const EXTRA_PRODUCTS = {
	application_tutorial_webinar: {
		product_description: {
			PL: "Webinar jak efektywnie korzystać z dostępu do apki",
			EN: "Webinar on how to effectively use access to the app"
		},
		product_price: {price_value: 0, price_label: {PL: "Gratis!", EN: "Free!"}}
	},
	estates_market_webinars: {
		product_description: {
			PL: "Dostęp do webinarów pokazujących sytuację na rynku nieruchomości - jedynie 16,99 zł / miesiąc",
			EN: "Access to webinars showing the situation on the real estate market - only PLN 16.99 / month"
		},
		product_price: {price_value: 16.99, price_payment_type: "2", price_label: {PL: "Gratis!", EN: "Free!"}}
	},
	add_radar_discount: {
		product_description: {
			PL: "Odbierz -25% na Adradar",
			EN: "Receive -25% on Adradar"
		},
		product_price: {price_value: 0, price_label: {PL: "Gratis!", EN: "Free!"}},
		product_extra_label: {
			PL: "Rabat podeślemy mailem",
			EN: "We will send the discount by e-mail"
		}
	}
};


export const CartExtraProductsComponent = ({
	activeLanguage,
	extraProducts, extraProductsHandler
}) => {
	
	
	const {
		appWebinar, estWebinars, addRadar
	} = extraProducts;


	const {
		application_tutorial_webinar: appWebinarData,
		estates_market_webinars: estWebinarsData,
		add_radar_discount: addRadarData
	} = EXTRA_PRODUCTS;

	const {
		product_description: appWebinarDescription,
		product_price: appWebinarPrice
	} = appWebinarData;

	const {
		product_description: estWebinarsDescription,
		product_price: estWebinarsPrice
	} = estWebinarsData;

	const {
		product_description: addRadarDescription,
		product_price: addRadarPrice,
		product_extra_label: productExtraLabel
	} = addRadarData;
	
	
	return (
		<Row className={"cart-extra-products"}>
			<Col xs={12} className={"cart-extra-products-content"}>
				
				<Row className={"cart-extra-product"}>
					<Col xs={9} lg={10} className={"extra-product-checkbox"}>
						<span className={"checkbox" + (appWebinar ? " checked" : "")} onClick={() => extraProductsHandler("appWebinar")}>
							{appWebinar && <GiCheckMark />}
						</span>
						<span className={"checkbox-label"}>{appWebinarDescription[activeLanguage]}</span>
					</Col>
					<Col xs={3} lg={2} className={"extra-product-price"}>
						{appWebinarPrice[activeLanguage] && <span>{appWebinarPrice[activeLanguage]}</span>}
						<span>{appWebinarPrice["price_value"] === 0 ? appWebinarPrice["price_label"][activeLanguage] : `${appWebinarPrice["price_value"]} PLN`}</span>
					</Col>
				</Row>
				
				<Row className={"cart-extra-product"}>
					<Col xs={9} lg={10} className={"extra-product-checkbox"}>
						<span className={"checkbox" + (estWebinars ? " checked" : "")} onClick={() => extraProductsHandler("estWebinars")}>
							{estWebinars && <GiCheckMark />}
						</span>
						<span className={"checkbox-label"}>{estWebinarsDescription[activeLanguage]}</span>
					</Col>
					<Col xs={3} lg={2} className={"extra-product-price"}>
						<span>{estWebinarsPrice[activeLanguage]}</span>
						<span>{estWebinarsPrice["price_value"] === 0 ? estWebinarsPrice["price_label"][activeLanguage] : `${estWebinarsPrice["price_value"]} PLN`}</span>
					</Col>
				</Row>
				
				
				<Row className={"cart-extra-product premium-product"}>
					<Col xs={9} lg={10} className={"extra-product-checkbox"}>
						<span className={"checkbox" + (addRadar ? " checked" : "")} onClick={() => extraProductsHandler("addRadar")}>
							{addRadar && <GiCheckMark />}
						</span>
						<span className={"checkbox-label"}>{addRadarDescription[activeLanguage]}</span>
					</Col>
					<Col xs={3} lg={2} className={"extra-product-price"}>
						<span>{addRadarPrice["price_value"] === 0 ? addRadarPrice["price_label"][activeLanguage] : `${addRadarPrice["price_value"]} PLN`}</span>
					</Col>
					<Col xs={12} className={"extra-product-label"}>
						<span>{productExtraLabel[activeLanguage]}</span>
					</Col>
				</Row>
				
			</Col>
		</Row>
	);
	
};


export default CartExtraProductsComponent;
