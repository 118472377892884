import React from "react";

import {useSelector} from "react-redux";


import {Row, Col} from "react-bootstrap";


import WarningCommunicateComponent from "@Elements/Communicates/Warning";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import PaymentHistoryListComponent from "@UIPages/User/Authenticated/Profile/Sections/PaymentsHistory/List";


const TEXTS = {
	PL: {section_header_text: "Historia Płatności"},
	EN: {section_header_text: "Payments History"},
};


const MESSAGES = {
	PL: {no_payments_history_message: "Nie dokonałeś do tej pory żadnej płatności"},
	EN: {no_payments_history_message: "You don't have any payments history"},
};


export const UserPaymentHistoryComponent = ({CHECK_FLAT_USER_DATA}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {section_header_text: sectionHeaderText} = TEXTS[ACTIVE_LANGUAGE];
	const {no_payments_history_message: noPaymentsHistoryMessage} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	if (!CHECK_FLAT_USER_DATA) return null;
	
	
	const {userPayments} = CHECK_FLAT_USER_DATA;
	
	
	return (
		<Col xs={12} lg={4} className={"profile-section user-payment-history"}>
			<Row className={"profile-section-content"}>
				
				<ProfileSectionHeaderComponent headerText={sectionHeaderText} />
				
				{!userPayments?.length &&
				<WarningCommunicateComponent communicateText={noPaymentsHistoryMessage} />
				}
				
				{!!userPayments?.length &&
				<PaymentHistoryListComponent paymentsList={userPayments} />
				}
				
			</Row>
		</Col>
	);
	
};


export default UserPaymentHistoryComponent;
