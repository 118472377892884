import {Col} from "react-bootstrap";
import React from "react";


export const StepsHeaderHeadComponent = ({
	checkFormStep = 0, headerText
}) => {
	
	
	if (checkFormStep !== 1) return null
	
	
	return (
		<Col xs={12} className={"check-form-steps-heading"}>
			<span>{headerText}</span>
		</Col>
	);
	
};


export default StepsHeaderHeadComponent;
