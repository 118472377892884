import React from "react";


import {Col} from "react-bootstrap";


export const FormCheckboxComponent = ({xs = 12, labelText = "", selected = false, clickHandler = null, cssClasses = {}, ...props}) => {
	
	
	const fieldContainerCSS = cssClasses?.fieldContainer || "";
	const fieldInputCSS = cssClasses?.fieldInput || "";
	const fieldLabelCSS = cssClasses?.fieldLabel || "";
	
	
	if (!labelText) {
		return null;
	}
	
	
	return (
		<Col xs={12} className={"user-form-option-content" + (fieldContainerCSS ? ` ${fieldContainerCSS}` : "")}>
			<span
				className={"user-form-option-input radio-input" + (selected ? " selected" : "") + (fieldInputCSS ? ` ${fieldInputCSS}` : "")}
				onClick={clickHandler}
			></span>
			<label
				className={"user-form-option-label radio-input-label" + (selected ? " active" : "") + (fieldLabelCSS ? ` ${fieldLabelCSS}` : "")}
				onClick={clickHandler}
			>
				<span>{labelText}</span>
			</label>
		</Col>
	);
	
};


export default FormCheckboxComponent;
