import isEmail from "validator/es/lib/isEmail";
import isDate from "validator/es/lib/isDate";


const MESSAGES = {
	PL: {
		userFirstName: "Imię powinno składać się z conajmniej 3 znaków",
		userLastName: "Nazwisko powinno składać się z conajmniej 3 znaków",
		userEmailAddress: "Adres Email jest niepraidłowy",
		userPassword: "Podane Hasło jest zbyt krótkie",
		userPasswordRepeat: "Hasła nie są identyczne",
		userCompanyName: "Prosimy podać Nazwę Firmy",
		userCompanyDNI: "Numer NIP jest niepoprawny",
		userCompanyStreet: "Prosimy podać Ulicę",
		userCompanyHouseNo: "Prosimy podać Numer domu / mieszkania",
		userCompanyPostCode: "Podany Kod Pocztowy jest nieprawidłowy",
		userCompanyCity: "Prosimy podać Miejscowość",
		userBirthdayDate: "Data jest nieprawidłowa",
		regulationAcceptedStatus: "Akceptacja Regulaminu jest obowiązkowa dla rejestracji"
	},
	EN: {
		userFirstName: "Please enter your Name",
		userLastName: "Please enter your Surname",
		userEmailAddress: "Incorrect Email Address",
		userPassword: "Password is too short",
		userPasswordRepeat: "Passwords are not equal",
		userCompanyName: "Please Enter Company Name",
		userCompanyDNI: "Incorrect Value",
		userCompanyStreet: "Please Enter the street",
		userCompanyHouseNo: "Please enter Building nr / Flat nr",
		userCompanyPostCode: "Invalid Postcode",
		userCompanyCity: "Please enter the City",
		userBirthdayDate: "Incorrect Date",
		regulationAcceptedStatus: "You have to accept Regulations to register"
	}
}


export const registerFormSubmitAttempt = (
	registerFormData, registerFormErrorsHandler, activeLanguage = "PL"
) => {
	
	if (!registerFormData || !registerFormErrorsHandler) return null;
	
	const {
		userFirstName, userLastName, userEmailAddress, userPassword, userPasswordRepeat,
		userInvoice, userCompanyName, userCompanyDNI, userCompanyStreet, userCompanyHouseNo, userCompanyPostCode, userCompanyCity,
		userBirthdayPresent, userBirthdayDate,
		regulationAcceptedStatus
	} = registerFormData;
	
	const registerFormErrors = {};
	
	if (userFirstName.length < 3) registerFormErrors.userFirstName = MESSAGES[activeLanguage].userFirstName;
	if (userLastName.length < 3) registerFormErrors.userLastName = MESSAGES[activeLanguage].userLastName;
	if (!isEmail(userEmailAddress)) registerFormErrors.userEmailAddress = MESSAGES[activeLanguage].userEmailAddress;
	if (userPassword.length < 8) registerFormErrors.userPassword = MESSAGES[activeLanguage].userPassword;
	if (userPasswordRepeat !== userPassword) registerFormErrors.userPasswordRepeat = MESSAGES[activeLanguage].userPasswordRepeat;
	
	if (userInvoice) {
		if (userCompanyName.length < 3) registerFormErrors.userCompanyName = MESSAGES[activeLanguage].userCompanyName;
		if (userCompanyDNI.length < 10) registerFormErrors.userCompanyDNI = MESSAGES[activeLanguage].userCompanyDNI;
		if (userCompanyStreet.length < 3) registerFormErrors.userCompanyStreet = MESSAGES[activeLanguage].userCompanyStreet;
		if (userCompanyHouseNo.length < 1) registerFormErrors.userCompanyHouseNo = MESSAGES[activeLanguage].userCompanyHouseNo;
		if (userCompanyPostCode.length < 6) registerFormErrors.userCompanyPostCode = MESSAGES[activeLanguage].userCompanyPostCode;
		if (userCompanyCity.length < 3) registerFormErrors.userCompanyCity = MESSAGES[activeLanguage].userCompanyCity;
	}
	
	if (userBirthdayPresent) {
		if (!isDate(userBirthdayDate)) registerFormErrors.userBirthdayDate = MESSAGES[activeLanguage].userBirthdayDate;
	}
	
	if (!regulationAcceptedStatus) registerFormErrors.regulationAcceptedStatus = MESSAGES[activeLanguage].regulationAcceptedStatus;
	
	registerFormErrorsHandler(registerFormErrors);
	
	if (Object.entries(registerFormErrors).length) {
		return null;
	}
	
	return registerFormData;
	
};


export default registerFormSubmitAttempt;
