import React from "react";


import {Row} from "react-bootstrap";


import FormCheckboxComponent from "@Elements/Forms/Fields/CheckboxField";


export const CheckFormCheckboxComponent = ({xs = 12, labelText = "", children = null, selected = false, clickHandler = null, cssClasses = {}, ...props}) => {
	
	
	if (!labelText) {
		return null;
	}
	
	
	return (
		<Row className={"check-form-step-option user-form-option checkbox-option"}>
			<FormCheckboxComponent
				xs={xs}
				labelText={labelText}
				selected={selected}
				clickHandler={clickHandler}
				cssClasses={cssClasses}
			/>
			{children}
		</Row>
	);
	
};

