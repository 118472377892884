import React, {useState, useEffect, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";
import {LOG_USER_OUT_ACTION} from "@Store/Actions";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import {FaUserAlt} from "react-icons/all";


const USER_LINKS = {
	PL: {
		login: {url: "logowanie", title: "Zaloguj się"},
		logout: {url: "wyloguj", title: "Wyloguj się"},
		register: {url: "rejestracja", title: "Zarejestruj się"},
		profile: {url: "profil", title: "Mój Profil"},
	},
	EN: {
		login: {url: "login", title: "Sign in"},
		logout: {url: "logout", title: "Logout"},
		register: {url: "register", title: "Sign up"},
		profile: {url: "profile", title: "My Profile"},
	}
}


export const HeaderUserMenuComponent = ({activeLanguage}) => {
	
	
	const userMenuSwitcherRef = useRef(null);
	const userMenuRef = useRef(null);
	
	
	const loggedAggeUser = useSelector(state => state?.authentication?.loggedUser);
	const DISPATCHER = useDispatch();
	
	
	useEffect(() => {
		const handleClickOutside = (e) => {
			if (userMenuSwitcherRef.current && userMenuSwitcherRef.current.contains(e.target)) {
				return null;
			}
			if (!(userMenuRef.current && userMenuRef.current.contains(e.target))) {
				setMenuDisplayStatus(false);
			}
		};
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	
	
	
	const [menuDisplayStatus, setMenuDisplayStatus] = useState(false)
	
	const changeMenuDisplayStatus = (e) => {
		e.preventDefault();
		setMenuDisplayStatus(!menuDisplayStatus);
	};
	
	
	const userLinks = USER_LINKS[activeLanguage];
	
	
	const loginLink = !loggedAggeUser ? userLinks.login : userLinks.profile;
	const registerLink = !loggedAggeUser ? userLinks.register : userLinks.logout;
	
	const logUserOut = () => {
		localStorage.removeItem("aggeUserPackage");
		DISPATCHER(LOG_USER_OUT_ACTION());
	};
	
	
	const profileLinkClickHandler = () => {
		setMenuDisplayStatus(false);
	};
	
	const accountLinkClickHandler = (e) => {
		if (loggedAggeUser) {
			e.preventDefault();
			logUserOut();
		}
		setMenuDisplayStatus(false);
	};
	
	
	return (
		<Col xs={6} lg={2} className="application-header-user-menu">
		
			<a
				href={`#menu`}
				title={"Pokaż Menu"}
				onClick={e => changeMenuDisplayStatus(e)}
				className={"user-menu-display-switcher" + (menuDisplayStatus ? " active" : "")}
				ref={userMenuSwitcherRef}
			>
				<FaUserAlt />
				<span>{activeLanguage === "PL" ? "Moj Checkflat" : "My Checkflat"}</span>
			</a>
			
			{menuDisplayStatus &&
			<Row className="user-menu-list" ref={userMenuRef}>
				<Col xs={12} className="menu-list-item">
					<NavLink
						to={`${loginLink.url}`}
						title={loginLink.title}
						className="menu-list-item-link"
						onClick={e => profileLinkClickHandler(e)}
					>
						<span>{loginLink.title}</span>
					</NavLink>
				</Col>
				<Col xs={12} className="menu-list-item">
					{!loggedAggeUser &&
					<span className="menu-list-item-label">{activeLanguage === "PL" ? "nie masz konta?" : "don't have account"}</span>
					}
					<NavLink
						to={`${registerLink.url}`}
						title={registerLink.title}
						className="menu-list-item-link"
						onClick={e => accountLinkClickHandler(e)}
					>
						<span>{registerLink.title}</span>
					</NavLink>
				</Col>
			</Row>
			}
		
		</Col>
	);
	
};


export default HeaderUserMenuComponent;
