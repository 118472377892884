import React from "react";


import {Container, Row, Col} from "react-bootstrap";


import {RiseLoader} from "react-spinners";


export const MainContentLoaderComponent = ({
	loaderText = `Loading...`, loaderSize = 25, loaderElementsMargin = 2,
	loaderColor = "#45B7A7",
	forwardRef = null
}) => {

	const loaderContainerStyles = {marginTop: 100, marginBottom: 100};
	const loaderTextStyles = {marginBottom: 50, color: loaderColor, fontWeight: 600, textTransform: "uppercase"};
	
	
	return (
		<Container fluid={true} className="main-content-loader main-content-loader-component" ref={forwardRef} style={loaderContainerStyles}>
			<Container>
				<Row className={"justify-content-center"}>
					
					{loaderText &&
					<Col xs={12} className="loader-text text-center" style={loaderTextStyles}>
						<span>{loaderText}</span>
					</Col>
					}
					
					<Col xs={12} className="loader-animation text-center">
						<RiseLoader
							size={loaderSize}
							margin={loaderElementsMargin}
							color={loaderColor}
						/>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default MainContentLoaderComponent;
