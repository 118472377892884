import React from "react";

import {useSelector} from "react-redux";


import {Alert, Col, Row} from "react-bootstrap";


import camelCaseToSnakeCase from "@Utilities/Tools/Strings/CamelCaseToSnakeCase";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import ProfileTextFieldComponent from "@UIPages/User/Authenticated/Profile/Elements/Fields/Text";


const TEXTS = {
	PL: {
		header_text: "Moje Dane",
		first_name_label_text: "Imię", last_name_label_text: "Nazwisko", email_address_label_text: "Email",
		birthday_gift_label_text: "chce otrzymać prezent na urodziny", birthday_date_label_text: "Data Urodzenia",
		birthday_legend_text: "Jeśli chcesz otrzymać prezent, podaj datę urodzin"
	},
	EN: {
		header_text: "Personal Data",
		first_name_label_text: "First Name", last_name_label_text: "Last Name", email_address_label_text: "Email",
		birthday_gift_label_text: "I want a birthday gift", birthday_date_label_text: "Birthday Date",
		birthday_legend_text: "If You want birthday gift, enter birthday date"
	}
};


export const MainDataSectionPersonalComponent = ({userData = null, userDataHandler = null}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		header_text: headerText,
		first_name_label_text: firstNameLabelText, last_name_label_text: lastNameLabelText, email_address_label_text: emailAddressLabelText,
		birthday_gift_label_text: birthdayGiftLabelText, birthday_date_label_text: birthdayDateLabelText,
		birthday_legend_text: birthdayLegendText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	if (!userData) return null;
	
	
	const {
		userFirstName, userLastName, userEmailAddress,
		userBirthdayPresentStatus, userBirthdayDate
	} = userData;
	
	
	return (
		<Col xs={12} lg={{span: 4}} className={"profile-section user-personal-data user-data-form"}>
			<Row className={"personal-data-content profile-section-content"}>
				
				<ProfileSectionHeaderComponent
					headerText={headerText}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={firstNameLabelText}
					fieldName={"userFirstName"}
					fieldValue={userFirstName}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={lastNameLabelText}
					fieldName={"userLastName"}
					fieldValue={userLastName}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={emailAddressLabelText}
					fieldName={"userEmailAddress"}
					fieldValue={userEmailAddress}
					fieldValueHandler={userDataHandler}
					fieldType={"email"}
				/>
				
				<Col xs={12} className={"user-data-field profile-section-field checkbox-field"}>
					<span
						className={"checkbox" + (userBirthdayPresentStatus ? " checked" : "")}
						onClick={() => userDataHandler(camelCaseToSnakeCase("userBirthdayPresentStatus"), !userBirthdayPresentStatus)}
					></span>
					<span
						className={"data-field-label"}
						onClick={() => userDataHandler(camelCaseToSnakeCase("userBirthdayPresentStatus"), !userBirthdayPresentStatus)}
					>
						{birthdayGiftLabelText}
					</span>
				</Col>
				
				{userBirthdayPresentStatus &&
				<ProfileTextFieldComponent
					fieldLabel={birthdayDateLabelText}
					fieldName={"userBirthdayDate"}
					fieldValue={userBirthdayDate}
					fieldValueHandler={userDataHandler}
					fieldType={"date"}
				/>
				}
				{userBirthdayPresentStatus && !!!userBirthdayDate &&
				<Col xs={9} as={Alert} variant={"warning"} className="user-data-field profile-section-field communicate-field">
					<span>{birthdayLegendText}</span>
				</Col>
				}
			
			
			</Row>
		</Col>
	);
	
};


export default MainDataSectionPersonalComponent;
