import React from "react";

import {useSelector} from "react-redux";


import {Row, Col} from "react-bootstrap";


import WarningCommunicateComponent from "@Elements/Communicates/Warning";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import GoToCheckFlatComponent from "@UIPages/User/Authenticated/Profile/Sections/ChecksHistory/GoToCheckflat";


const TEXTS = {
	PL: {section_header_text: "Historia Sprawdzeń"},
	EN: {section_header_text: "Checks History"},
};

const MESSAGES = {
	PL: {no_history_checks_message: "Nie wykonałeś jeszcze sprawdzenia"},
	EN: {no_history_checks_message: "You don't have generated reports"},
};


export const UserChecksHistoryComponent = ({CHECK_FLAT_USER_DATA}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {section_header_text: sectionHeaderText,} = TEXTS[ACTIVE_LANGUAGE];
	
	const {no_history_checks_message: noHistoryChecksMessage} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	if (!CHECK_FLAT_USER_DATA) return null;
	
	
	const {userActivePackage, userReports} = CHECK_FLAT_USER_DATA;
	
	
	const generatedReports = userReports.filter(report => {
		const {estate_report_date: estateReportDate} = report;
		return !!estateReportDate;
	});
	const generatedReportsNumber = generatedReports?.length;
	
	
	let availableReports = null;
	if (userActivePackage) {
		const {orderReports} = userActivePackage;
		availableReports = orderReports.filter(report => {
			const {estate_report_date: estateReportDate} = report;
			return !estateReportDate;
		}).length;
	}
	
	
	return (
		<Col xs={12} lg={4} className={"profile-section user-checks-history"}>
			<Row className={"profile-section-content"}>
				
				<ProfileSectionHeaderComponent headerText={sectionHeaderText} />
				
				{!generatedReportsNumber &&
				<WarningCommunicateComponent communicateText={noHistoryChecksMessage}/>
				}
				
				{!!availableReports &&
				<GoToCheckFlatComponent availableReportsNumber={availableReports} />
				}
				
			</Row>
		</Col>
	);
	
};


export default UserChecksHistoryComponent;
