import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormSkipButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Skip";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Czy mieszkanie posiada",
			button_text: "Dalej",
			return_text: "Wróć",
			skip_text: "pomiń"
		},
		
	},
	EN: {
		texts: {
			step_header: "Does the apartment have",
			button_text: "Next",
			return_text: "Back",
			skip_text: "skip"
		},
		
	}
};


const EXTRAS = {
	PL: {
		flatLuxuryKitchen: "Osobną jasną kuchnię",
		flatBalcony: "Balkon / Taras",
		flatLoggie: "Loggie",
		flatCellar: "Piwnicę",
		flatAttic: "Poddasze",
		flatBin: "Komórkę Lokatorską",
		elevator: "Windę"
	},
	EN: {
		flatLuxuryKitchen: "A separate bright kitchen",
		flatBalcony: "Balcony / Terrace",
		flatLoggie: "Loggie",
		flatCellar: "Basement",
		flatAttic: "Attic",
		flatBin: "A storage room",
		elevator: "Elevator"
	}
};


export const CheckFormStep8SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler,
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatLuxuryKitchen,
		flatBalcony,
		flatLoggie,
		flatCellar,
		flatAttic,
		flatBin,
		flatElevator
	} = formData;
	
	const [checkFlatFormDataFlatLuxuryKitchen, setCheckFlatFormDataFlatLuxuryKitchen] = useState(flatLuxuryKitchen || false);
	const [checkFlatFormDataFlatBalcony, setCheckFlatFormDataFlatBalcony] = useState(flatBalcony || false);
	const [checkFlatFormDataFlatLoggie, setCheckFlatFormDataFlatLoggie] = useState(flatLoggie || false);
	const [checkFlatFormDataFlatCellar, setCheckFlatFormDataFlatCellar] = useState(flatCellar || false);
	const [checkFlatFormDataFlatAttic, setCheckFlatFormDataFlatAttic] = useState(flatAttic || false);
	const [checkFlatFormDataFlatBin, setCheckFlatFormDataFlatBin] = useState(flatBin || false);
	const [checkFlatFormDataElevator, setCheckFlatFormDataElevator] = useState(flatElevator || false);
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const skipStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(false, true);
	};
	
	const submitCheckFlatFormStep = (reset = false, skip = false) => {
		formDataHandler({
			flatLuxuryKitchen: reset || skip ? null : checkFlatFormDataFlatLuxuryKitchen,
			flatBalcony: reset || skip ? null : checkFlatFormDataFlatBalcony,
			flatLoggie: reset || skip ? null : checkFlatFormDataFlatLoggie,
			flatCellar: reset || skip ? null : checkFlatFormDataFlatCellar,
			flatAttic: reset || skip ? null : checkFlatFormDataFlatAttic,
			flatBin: reset || skip ? null : checkFlatFormDataFlatBin,
			flatElevator: reset || skip ? null : checkFlatFormDataElevator
		}, reset ? 6 : 8);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-8-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatLuxuryKitchen ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatLuxuryKitchen(!checkFlatFormDataFlatLuxuryKitchen)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatLuxuryKitchen ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatLuxuryKitchen(!checkFlatFormDataFlatLuxuryKitchen)}
						>
							{EXTRAS[activeLanguage].flatLuxuryKitchen}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatBalcony ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatBalcony(!checkFlatFormDataFlatBalcony)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatBalcony ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatBalcony(!checkFlatFormDataFlatBalcony)}
						>
							{EXTRAS[activeLanguage].flatBalcony}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatLoggie ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatLoggie(!checkFlatFormDataFlatLoggie)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatLoggie ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatLoggie(!checkFlatFormDataFlatLoggie)}
						>
							{EXTRAS[activeLanguage].flatLoggie}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatCellar ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatCellar(!checkFlatFormDataFlatCellar)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatCellar ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatCellar(!checkFlatFormDataFlatCellar)}
						>
							{EXTRAS[activeLanguage].flatCellar}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatAttic ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatAttic(!checkFlatFormDataFlatAttic)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatAttic ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatAttic(!checkFlatFormDataFlatAttic)}
						>
							{EXTRAS[activeLanguage].flatAttic}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataFlatBin ? " selected" : "")}
							onClick={() => setCheckFlatFormDataFlatBin(!checkFlatFormDataFlatBin)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataFlatBin ? " active" : "")}
							onClick={() => setCheckFlatFormDataFlatBin(!checkFlatFormDataFlatBin)}
						>
							{EXTRAS[activeLanguage].flatBin}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input checkbox-input" + (checkFlatFormDataElevator ? " selected" : "")}
							onClick={() => setCheckFlatFormDataElevator(!checkFlatFormDataElevator)}
						></span>
						<label
							className={"step-option-label user-form-option-label checkbox-input-label" + (checkFlatFormDataElevator ? " active" : "")}
							onClick={() => setCheckFlatFormDataElevator(!checkFlatFormDataElevator)}
						>
							{EXTRAS[activeLanguage].elevator}
						</label>
					</Col>
				</Row>
				
			</Col>
			
			<CheckFormInformationComponent xs={12} lg={{span: 5, offset: 1}} formStep={formStep} />
			
		</Row>
	);

};


export default CheckFormStep8SectionComponent;
