import {createStore, combineReducers} from "redux";

import {authenticationReducer, localizationReducer, InterfaceReducer} from "@Store/Reducers";


export const applicationStore = createStore(
	combineReducers({
		authentication: authenticationReducer,
		localization: localizationReducer,
		interface: InterfaceReducer
	})
);


export default applicationStore;
