import React from "react";

import {useSelector} from "react-redux";


const TEXTS = {
	PL: {create_team_text: "Załóż Zespół", cancel_create_text: "Zrezygnuj"},
	EN: {create_team_text: "Create Team", cancel_create_text: "Cancel"},
};


export const CreateTeamSwitcherComponent = ({createTeamActiveStatus, createTeamActiveStatusHandler}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		create_team_text: createTeamText, cancel_create_text: cancelCreateText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const buttonText = createTeamActiveStatus ? cancelCreateText : createTeamText;
	
	
	return (
		<a href={"#create-team"} title={buttonText} className={"create-team-switcher" + (createTeamActiveStatus ? " active" : "")} onClick={e => createTeamActiveStatusHandler(e)}>
			<span>{buttonText}</span>
		</a>
	);
	
};


export default CreateTeamSwitcherComponent;
