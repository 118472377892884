import React, {useState} from "react";


import {Col} from "react-bootstrap";


import PaymentListHeaderComponent from "@UIPages/User/Authenticated/Profile/Sections/PaymentsHistory/List/Header";
import PaymentListItemComponent from "@UIPages/User/Authenticated/Profile/Sections/PaymentsHistory/List/Item";
import LoadAllPaymentsComponent from "@UIPages/User/Authenticated/Profile/Sections/PaymentsHistory/Switcher";


export const PaymentHistoryListComponent = ({paymentsList}) => {
	
	
	const [loadAllPaymentsStatus, setLoadAllPaymentsStatus] = useState(false);
	
	const loadAllPaymentsStatusHandler = (e) => {
		e.preventDefault();
		setLoadAllPaymentsStatus(!loadAllPaymentsStatus);
	};
	
	
	if (!paymentsList) return null;
	
	
	const UserPaymentsList = paymentsList.slice(0, loadAllPaymentsStatus ? paymentsList.length : 3).map((payment, i) =>
		<PaymentListItemComponent key={i} paymentData={payment} />
	);
	
	
	return (
		<Col xs={12} className={"payment-history-list"}>
			
			<PaymentListHeaderComponent />
			
			{UserPaymentsList}
			
			{paymentsList > 3 &&
			<LoadAllPaymentsComponent
				loadAllPaymentsStatus={loadAllPaymentsStatus}
				loadAllPaymentsStatusHandler={loadAllPaymentsStatusHandler}
			/>
			}
			
		</Col>
	);
	
};


export default PaymentHistoryListComponent;
