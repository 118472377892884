import React, {useEffect, useState} from "react";


import ApplicationOpinionsComponent from "@UIParts/Opinions";


import PricingPageHeaderSectionComponent from "@UIPages/Common/PricingPage/Sections/HeaderSection";
import PricingPagePricingSectionComponent from "@UIPages/Common/PricingPage/Sections/PricingSection";
import PricingPageBenefitsSectionComponent from "@UIPages/Common/PricingPage/Sections/BenefitsSection";
import PricingPageMapSectionComponent from "@UIPages/Common/PricingPage/Sections/MapSection";


const PAGE_DATA = {
	header_section_data: {
		PL: {
			section_title: "Cennik",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
		},
		EN: {
			section_title: "Pricing",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
		}
	},
	pricing_section_data: {
		PL: {
			payment_label_text: "Wybierz pakiet pod swoje potrzeby",
			payment_option1_text: "Płacę jednorazowo",
			payment_option2_text: "Płacę miesięcznie",
			checks_number_label: "Wybierz liczbę sprawdzeń",
			singular_check_text: "pojedyncze",
			package_check_text: "pakiet",
			price_label_text: "Cena"
		},
		EN: {
			payment_label_text: "Choose a package according to your needs",
			payment_option1_text: "I pay one time",
			payment_option2_text: "I pay monthly",
			checks_number_label: "Select the number of checks",
			singular_check_text: "single",
			package_check_text: "package",
			price_label_text: "Price"
		}
	},
	benefits_section_data: {
		header_text: {PL: "Co zyskujesz?", EN: "What do you get?"},
		button_label: {PL: "Sprawdź jak to działa", EN: "Check how it works"},
		benefits_elements: {
			PL: [
				{benefit_id: 1, benefit_text: "Czas i łatwiejszą decyzję"},
				{benefit_id: 2, benefit_text: "Ekspercką wiedzę"},
				{benefit_id: 3, benefit_text: "Fachowe porady"},
			],
			EN: [
				{benefit_id: 1, benefit_text: "Time and easier decision"},
				{benefit_id: 2, benefit_text: "Expert knowledge"},
				{benefit_id: 3, benefit_text: "Professional advice"},
			]
		}
	},
	map_section: {
		PL: {
			search_label: "Sprawdź w jakich miastach analizujemy",
			search_placeholder: "Wpisz lokalizację",
			not_found_text: "Lokalizacja nie jest obsługiwana"
		},
		EN: {
			search_label: "Check in which cities we analyze",
			search_placeholder: "Enter a location",
			not_found_text: "Location is not supported"
		},
	}
}

export const ApplicationPricingPageComponent = ({activeLanguage}) => {
	
	
	const [pricingPageData, setPricingPageData] = useState(null);
	
	useEffect(() => {
		
		const {
			header_section_data: headerSectionData,
			benefits_section_data: benefitsSectionData,
			map_section: mapSection,
			pricing_section_data: pricingSectionData
		} = PAGE_DATA;
		
		setPricingPageData({
			headerSectionData: headerSectionData[activeLanguage],
			benefitsSectionData: {
				headerText: benefitsSectionData["header_text"][activeLanguage],
				buttonText: benefitsSectionData["button_label"][activeLanguage],
				benefitsElements: benefitsSectionData["benefits_elements"][activeLanguage],
			},
			mapSection: mapSection[activeLanguage],
			pricingSectionData: pricingSectionData[activeLanguage]
		});
		
		return () => {
			setPricingPageData(null);
		};
		
	}, []);
	
	
	const headerSectionData = pricingPageData?.headerSectionData || null;
	const benefitsSectionData = pricingPageData?.benefitsSectionData || null;
	const mapSection = pricingPageData?.mapSection || null;
	const pricingSectionData = pricingPageData?.pricingSectionData || null;
	
	
	return (
		<>
		
			<PricingPageHeaderSectionComponent
				sectionData={headerSectionData}
			/>
			
			<PricingPagePricingSectionComponent
				activeLanguage={activeLanguage}
				sectionData={pricingSectionData}
			/>
			
			<PricingPageBenefitsSectionComponent
				sectionData={benefitsSectionData}
			/>
			
			<PricingPageMapSectionComponent
				sectionData={mapSection}
			/>
			
			<ApplicationOpinionsComponent />
		
		</>
	)
	
};


export default ApplicationPricingPageComponent;
