import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Z jakiego rynku jest mieszkanie które sprawdzasz?",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		
	},
	EN: {
		texts: {
			step_header: "What market is the apartment you are checking from?",
			button_text: "Next",
			return_text: "Back"
		},
		
	}
};


const MARKET = {
	PL: {1: "Pierwotny", 2: "Wtórny"},
	EN: {1: "Primary", 2: "Secondary"},
};


export const CheckFormStep3SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler,
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		marketType
	} = formData;
	
	
	const [checkFlatFormDataMarketType, setCheckFlatFormDataMarketType] = useState(marketType || null);
	
	const changeCheckFlatFormDataMarketType = (type) => {
		setCheckFlatFormDataMarketType(type);
		setFormStepErrors({marketType: null});
	};
	
	const [formStepErrors, setFormStepErrors] = useState({marketType: null});
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const submitCheckFlatFormStep = (reset = false) => {
		if (!checkFlatFormDataMarketType && !reset) {
			setFormStepErrors({
				marketType: {message:
					activeLanguage === "PL" ? "Proszę wybrać rodzaj rynku" : "Please choose market type"
				}
			});
			return null;
		}
		formDataHandler({
			marketType: reset ? null : checkFlatFormDataMarketType
		}, reset ? 1 : 3);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-3-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={12} className="check-form-step-content user-form-content">
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataMarketType === "1" ? " selected" : "")}
							onClick={() => changeCheckFlatFormDataMarketType("1")}
						></span>
						<label
							className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataMarketType === "1" ? " active" : "")}
							onClick={() => changeCheckFlatFormDataMarketType("1")}
						>
							{MARKET[activeLanguage][1]}
						</label>
					</Col>
				</Row>
				
				<Row className={"check-form-step-option user-form-option checkbox-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<span
							className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataMarketType === "2" ? " selected" : "")}
							onClick={() => changeCheckFlatFormDataMarketType("2")}
						></span>
						<label
							className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataMarketType === "2" ? " active" : "")}
							onClick={() => changeCheckFlatFormDataMarketType("2")}
						>
							{MARKET[activeLanguage][2]}
						</label>
					</Col>
				</Row>
				
				{formStepErrors.marketType &&
				<Row>
					<Form.Label className={"error"}>{formStepErrors.marketType.message}</Form.Label>
				</Row>
				}
				
			</Col>
			
		</Row>
	);
	
};


export default CheckFormStep3SectionComponent;
