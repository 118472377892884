import React, {useEffect, useState} from "react";


import {Container, Row, Col} from "react-bootstrap";


export const ApplicationCopyrightsComponent = ({activeLanguage}) => {
	
	
	return (
		<Container as="aside" fluid={true} id="application-copyrights-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col as="aside" xs={12} lg={{span: 2, offset: 4}} className="copyrights-label">
						<span>Copywrongs ©2021 AGGE</span>
					</Col>
					
					<Col as="aside" xs={12} lg={{span: 2, offset: 4}} className="copyrights-developers">
						<span>developed by</span>
						<a href="https://webpros.pl" title="WebPros">webpros.pl</a>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default ApplicationCopyrightsComponent;
