import React from "react";


import {NavLink} from "react-router-dom";


import {Container, Row, Col} from "react-bootstrap"

import {IoArrowForwardCircleOutline} from "react-icons/all";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import imagePlaceholder from "@Assets/Images/no-image.png";


export const HomePageOurOfferSectionComponent = ({sectionData}) => {
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent/>;
	}
	
	
	const {
		section_title: sectionTitle,
		section_text: sectionText,
		button_text: buttonText
	} = sectionData;
	
	
	return (
		<Container as="section" fluid={true} id="home-page-our-offer-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} as="h2" className="section-main-heading text-lg-center">
						<span>{sectionTitle}</span>
					</Col>
					
					<Col xs={12} className="block-section-image">
						<Row className="block-section-image-content">
							<Col xs={12} as="picture" className="block-section-image-picture">
								<img src={imagePlaceholder} alt={sectionTitle} />
							</Col>
						</Row>
					</Col>
					
					<Col xs={12} as="article" className="block-section-text">
						<p>
							{sectionText}
						</p>
					</Col>
					
					<Col xs={12} as="aside" className="block-section-footer">
						<NavLink to={"/oferta"} title={sectionTitle} className={"button main-button"} onClick={e => e.preventDefault()}>
							<span>{buttonText}</span>
							<IoArrowForwardCircleOutline />
						</NavLink>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default HomePageOurOfferSectionComponent;
