import React from "react";


import {Col} from "react-bootstrap";


export const MainHeaderComponent = ({headerText, headerCssClasses = ""}) => {

	
	if (!headerText) {
		return null;
	}
	
	
	headerCssClasses = "main-header" + (headerCssClasses ? ` ${headerCssClasses}` : "")
	
	
	return (
		<Col as="h1" xs={12} className={headerCssClasses}>
			<span>{headerText}</span>
		</Col>
	)
	
};


export default MainHeaderComponent;
