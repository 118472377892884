import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


const PAYMENT_STATUSES = {
	PL: {1: "Rozpoczęta", 2: "W trakcie Weryfikacji", 3: "Zakończona", 4: "Anulowana"},
	EN: {1: "Started", 2: "In verification", 3: "Done", 4: "Cancelled"},
};

const PAYMENT_TYPES = {
	PL: {1: "Płatność Online", 2: "Przelew Bankowy"},
	EN: {1: "Online Payment", 2: "Bank Transfer"},
};


export const PaymentListItemComponent = ({paymentData}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const PAYMENT_STATUSES_LIST = PAYMENT_STATUSES[ACTIVE_LANGUAGE];
	const PAYMENT_TYPES_LIST = PAYMENT_TYPES[ACTIVE_LANGUAGE];
	
	
	const {
		// id: paymentID, payment_uuid: paymentUUID,
		payment_type: paymentType, payment_status: paymentStatus,
		payment_value: paymentValue, payment_date: paymentDate
	} = paymentData;
	
	
	return (
		<Row className={"single-payment-item"}>
			<Col xs={3} className={"payment-date"}>{(new Date(paymentDate)).toLocaleDateString()}</Col>
			<Col xs={3} className={"payment-value"}>{+paymentValue.toFixed(2)} PLN</Col>
			<Col xs={3} className={"payment-type"}>{PAYMENT_TYPES_LIST[paymentType]}</Col>
			<Col xs={3} className={`payment-status status-${paymentStatus}`}>{PAYMENT_STATUSES_LIST[paymentStatus]}</Col>
		</Row>
	);
	
};


export default PaymentListItemComponent;
