import React from "react";


import {Col} from "react-bootstrap";


import {PropagateLoader} from "react-spinners";


export const SingleContentLoaderComponent = ({
	loaderSize = 15, loaderElementsMargin = 2,
	loaderColor = "#45B7A7",
	forwardRef = null
}) => {

	
	return (
		<Col xs={12}
		     className="single-content-loader text-center"
		     ref={forwardRef}
		     style={{position: "relative", marginTop: 15, marginBottom: 15}}
		>
			
			<Col xs={12} className={"single-content-loader-content"} style={{height: 45, paddingTop: 15, border: `1px dotted ${loaderColor}`, borderRadius: 6}}>
				
				<PropagateLoader
					size={loaderSize}
					margin={loaderElementsMargin}
					color={loaderColor}
				/>
				
			</Col>
			
		</Col>
	);
	
};


export default SingleContentLoaderComponent;
