import React from "react";

import {useSelector} from "react-redux";


import {Row, Col} from "react-bootstrap";


const TEXTS = {
	PL: {team_name_text: "Nazwa zespołu", no_name_text: "bez nazwy"},
	EN: {team_name_text: "Team name", no_name_text: "no name"}
};


export const OwnedTeamHeaderComponent = ({usersTeamName = ""}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {team_name_text: teamNameText, no_name_text: noNameText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<Row className={"team-name"}>
			<Col xs={12} className={"user-team-option"}>
				<span className={"data-label"}>{teamNameText}:</span>
				<span className={"data-value"}>{usersTeamName || noNameText}</span>
			</Col>
		</Row>
	);
	
};


export default OwnedTeamHeaderComponent;
