import React, {useContext} from "react";

import GlobalDataContext from "@Contexts/Global";


import {Col, Row} from "react-bootstrap";


import PackageOptionComponent from "@UIPages/Common/PricingPage/Sections/PricingSection/Pricing/Option";


export const PricingSectionPricingComponent = ({
	componentData,
	chosenPaymentOption, chosenPackageOption, chosenPackageOptionHandler,
	addToCartHandler,
	activeLanguage
}) => {
	
	
	const packagesProducts = useContext(GlobalDataContext).checksPackages;
	
	
	const packagesProductsList = Object.entries(packagesProducts).map(([key, packageData]) =>
		<PackageOptionComponent
			key={key}
			activeLanguage={activeLanguage}
			optionTexts={componentData}
			optionId={key}
			optionData={packageData}
			chosenPaymentOption={chosenPaymentOption}
			chosenPackageOption={chosenPackageOption}
			chosenPackageOptionHandler={chosenPackageOptionHandler}
			addToCartHandler={addToCartHandler}
		/>
	);
	
	
	return (
		<Col xs={12} className="pricing-section-options">
			<Row className="pricing-section-options-list">
				{packagesProductsList}
			</Row>
		</Col>
	);
	
};


export default PricingSectionPricingComponent;
