import React from "react";

import parse from "html-react-parser";

import {useSelector} from "react-redux";


import {NavLink} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import ImagePlaceholder from "@Images/no-image_s.png";


const URLS = {
	PL: {articles_page: "nasze-rady"},
	EN: {articles_page: "our-advices"},
};


export const ArticlesPageItemComponent = ({articleData}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	const {articles_page: articlesPageURL} = URLS[ACTIVE_LANGUAGE];
	
	
	if (!articleData) return null;
	
	const {
		// article_id: articleID,
		article_title: articleTitle,
		article_short_content: articleShortContent,
		article_slug: articleSlug
	} = articleData;
	
	
	return (
		<Col as={"article"} xs={12} className={"articles-list-article"} >
			<Row className={"article-content"}>
				
				<Col xs={12} lg={2} className={"article-image"}>
					<NavLink to={articleSlug} title={articleTitle}>
						<img src={ImagePlaceholder} alt={articleTitle} />
					</NavLink>
				</Col>
				
				<Col xs={12} lg={{span: 9, offset: 1}} className={"article-data"}>
					<Row as={"h2"} className={"article-title"}>
						<NavLink to={`/${articlesPageURL}/${articleSlug}`} title={articleTitle}>
							<span>{articleTitle}</span>
						</NavLink>
					</Row>
					<Row as={"article"} className={"article-short-content"}>
						{parse(articleShortContent)}
					</Row>
					<Row as={"aside"} className={"article-information"}>
						<Col xs={6} className={"article-author"}>{`John Doe`}</Col>
						<Col xs={6} className={"article-date"}>{(new Date()).toLocaleDateString()}</Col>
					</Row>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default ArticlesPageItemComponent;
