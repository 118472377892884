import React from 'react';
import ReactDOM from 'react-dom';

import {Provider as ApplicationStoreProvider} from "react-redux";
import applicationStore from "@Store";


import TranslationsContext from "@Contexts";
import TRANSLATIONS from "@Contexts/Localization/Languages";


import GlobalDataContext, {GLOBAL_DATA} from "@Contexts/Global";
import RestApiContext, {REST_API_SETTINGS} from "@Contexts/REST";


import reportWebVitals from './reportWebVitals';


import Application from "./Application";


ReactDOM.render(
    <React.StrictMode>
        <ApplicationStoreProvider store={applicationStore}>
            <RestApiContext.Provider value={REST_API_SETTINGS}>
                <TranslationsContext.Provider value={TRANSLATIONS}>
                    <GlobalDataContext.Provider value={GLOBAL_DATA}>
                        <Application />
                    </GlobalDataContext.Provider>
                </TranslationsContext.Provider>
            </RestApiContext.Provider>
        </ApplicationStoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


reportWebVitals();
