import React, {useEffect, useRef} from "react";

import {useSelector} from "react-redux";


import {NavLink, Redirect} from "react-router-dom";


import {Container, Row, Col, Button} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import LoginFormComponent from "@Elements/Forms/Authentication/Login";
import MainHeaderComponent from "@Elements/Blocks/Sections/Headers/MainHeader";


const TEXTS = {
	PL: {
		i_dont_have_acc_text: "Nie mam jeszcze konta",
		register_button_text: "Zarejestruj się",
		login_text: "Zaloguj się",
		email_label: "Adres Email",
		password_label: "Hasło"
	},
	EN: {
		i_dont_have_acc_text: "I already have an account",
		register_button_text: "Register",
		login_text: "Login",
		email_label: "Email",
		password_label: "Password"
	}
}


const URLS = {
	PL: {profile_page_url: "profil"},
	EN: {profile_page_url: "profile"},
}


export const ApplicationLoginPageComponent = ({}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	
	const CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 150);
	}, []);
	
	
	const checkFlatUser = useSelector(state => state?.authentication?.loggedUser) || null;
	
	if (checkFlatUser) {
		return <Redirect to={`/${URLS[ACTIVE_LANGUAGE]["profile_page_url"]}`} />;
	}
	
	
	const {
		i_dont_have_acc_text: iDontHaveAccText,
		register_button_text: registerButtonText,
		login_text: loginText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<Container ref={CONTAINER_REF} as="section" fluid={true} id="login-page-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<MainHeaderComponent headerText={loginText} headerCssClasses={"text-center"} />
					
					<Col xs={12} lg={5} className={"authenticate-column register-form"}>
						<Row className={"register-form form-component"}>
							<Col as="h2" className={"section-header form-header"}>
								<span xs={12}>{iDontHaveAccText}</span>
							</Col>
							<Col xs={12} className={"form-field submit-field text-center"}>
								<Button as={NavLink} to={ACTIVE_LANGUAGE === "PL" ? "/rejestracja" : "/register"} variant={"primary"} className={"btn-large"}>
									<span>{registerButtonText}</span>
								</Button>
							</Col>
						</Row>
					</Col>
					
					<Col xs={12} lg={{span: 5, offset: 1}} className={"authenticate-column login-form"}>
						
						<LoginFormComponent
							activeLanguage={ACTIVE_LANGUAGE}
						/>
						
					</Col>
					
				</Row>
			</Container>
		</Container>
	)
	
};


export default ApplicationLoginPageComponent;
