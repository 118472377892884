import React from "react";

import {Row, Col, Form} from "react-bootstrap";


import {FaEdit, MdRemoveShoppingCart} from "react-icons/all";

const TEXTS = {
	PL: {
		package_label: "Wybrany Pakiet",
		checks_label: "Liczba sprawdzeń",
		payment_type_label: "Płatność",
		monthly_text: "miesięcznie",
		one_time_text: "jednorazowo",
		edit_text: "Zmień",
		remove_text: "Usuń"
	},
	EN: {
		package_label: "Chosen Package",
		checks_label: "Number of checks",
		payment_type_label: "Payment Type",
		monthly_text: "monthly",
		one_time_text: "one time",
		edit_text: "Edit",
		remove_text: "Remove"
	}
}


export const CartPackageProductComponent = ({
	activeLanguage,
	paymentType,
	chosenPackage, chosenPackageData,
	changePackageChecksNumberHandler,
	editProductHandler = null, removeProductHandler = null
}) => {

	
	if (!paymentType || !chosenPackage || !chosenPackageData) {
		return null;
	}
	
	
	const {
		// packageID,
		packageChecks
	} = chosenPackage;
	
	const {
		package_name: packageName,
		package_checks_number: packageChecksNumber
	} = chosenPackageData;
	
	
	const {
		checks_number_value: packageChecksValue,
		checks_number_prices: packageChecksPrices
	} = packageChecksNumber[packageChecks];
	
	
	const {
		package_label: packageLabel,
		checks_label: checksLabel,
		payment_type_label: paymentTypeLabel,
		monthly_text: monthlyText,
		one_time_text: oneTimeText,
		edit_text: editText,
		remove_text: removeText
	} = TEXTS[activeLanguage]
	
	
	const packageChecksNumberOptions = Object.entries(packageChecksNumber).map(([optionID, optionData]) => {
		const {checks_number_value: checksNumberValue} = optionData;
		return (
			<option key={optionID} value={optionID}>{checksNumberValue}</option>
		);
	});
	
	
	const packagePrice = packageChecksValue * packageChecksPrices[paymentType];
	
	
	const editPackage = (e) => {
		e.preventDefault();
		if (editProductHandler) {
			return editProductHandler();
		}
	};
	
	
	const removePackage = (e) => {
		e.preventDefault();
		if (removeProductHandler) {
			return removeProductHandler();
		}
	};
	
	
	return (
		<Row className={"cart-package-product"}>
			<Col xs={12} lg={8} className={"package-product-content"}>
				
				<Col xs={12} className={"package-product-name"}>
					<span className={"information-label"}>{packageLabel}:</span>
					<span className={"information-value"}>{packageName}</span>
				</Col>
				
				<Col xs={12} className={"package-product-checks"}>
					<span className={"information-label"}>{checksLabel}:</span>
					<Form.Control
						as={"select"}
						value={packageChecks}
						onChange={e => changePackageChecksNumberHandler(e.target.value)}
					>
						{packageChecksNumberOptions}
					</Form.Control>
				</Col>
				
				<Col xs={12} className={"package-product-price"}>
					<span className={"information-label"}>{paymentTypeLabel}:</span>
					<span className={"information-value"}>
						{paymentType === "1" ? oneTimeText : monthlyText}
					</span>
					<span className={"package-price"}>{packagePrice} PLN</span>
				</Col>
				
			</Col>
			
			<Col xs={12} lg={{span: 2, offset: 2}} className={"package-product-actions"}>
				<a href={"#edit"} onClick={e => removePackage(e)} className={"remove-package-product"}>
					<MdRemoveShoppingCart />
					<span>{removeText}</span>
				</a>
				<a href={"#remove"} onClick={e => editPackage(e)} className={"edit-package-product"}>
					<FaEdit />
					<span>{editText}</span>
				</a>
			</Col>
			
		</Row>
	);
	
}


export default CartPackageProductComponent;
