import React from "react";

import {useDispatch, useSelector} from "react-redux";


import {BrowserRouter as Router, Route} from "react-router-dom";


import ApplicationRouter from "@UIParts/_Router";

import ApplicationHeaderComponent from "@UIParts/Header";
import ApplicationFollowUsComponent from "@UIParts/FollowUs";
import ApplicationCopyrightsComponent from "@UIParts/Copyrights";
import ApplicationFooterComponent from "@UIParts/Footer";
import ApplicationBottomBarComponent from "@UIParts/Navigation/Bottom";


import {FadeLoader} from "react-spinners";
import {FaWindowClose} from "react-icons/all";
import {GLOBAL_POPUP_CONTENT_ACTION, STATE_UPDATE_HANDLER_ACTION} from "@Actions";


export const Application = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	const GLOBAL_POPUP_CONTENT = useSelector(state => state?.interface)?.globalPopupContent || null;
	
	
	const previousLanguage = useSelector(state => state?.localization.previousLanguage?.languageCode);
	const activeLanguage = useSelector(state => state?.localization.activeLanguage?.languageCode);

	
	return (
		<Router>
			
			{REST_API_REQUEST_STATUS &&
			<div style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.2)", zIndex: 10000}}>
				<div style={{position: "absolute", top: "calc(50% - 30px)", left: "calc(50% - 30px)"}}>
					<FadeLoader color={"#45B7A7"} />
				</div>
			</div>
			}
			
			{GLOBAL_POPUP_CONTENT &&
			<div className={"global-popup-component"}>
				{REST_API_REQUEST_STATUS &&
				<div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.5)", zIndex: 10000}}></div>
				}
				<div className={"global-popup-content"}>
					<a href={"#close-popup"} title={""} className={"close-global-popup"} onClick={e => {
						e.preventDefault();
						DISPATCHER(STATE_UPDATE_HANDLER_ACTION(true));
						DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(null));
					}}>
						<FaWindowClose />
					</a>
					{GLOBAL_POPUP_CONTENT}
				</div>
			</div>
			}
			
			{/** @Component ApplicationHeader */}
			<Route path={`/`}
				render={
					(props) =>
						<ApplicationHeaderComponent
							{...props}
							activeLanguage={activeLanguage}
						/>
				}
			/>
			
			{/** @Component ApplicationRouter */}
			<Route
				path={`/`} render={
					props =>
						<ApplicationRouter
							{...props}
							previousLanguage={previousLanguage}
							activeLanguage={activeLanguage}
						/>
				}
			/>
			
			{/** @Component ApplicationFollowUs */}
			<Route path={`/`}
			       render={
				       (props) =>
					       <ApplicationFollowUsComponent
						       {...props}
						       activeLanguage={activeLanguage}
					       />
			       }
			/>
			
			<Route path={`/`} render={() => null} />
			
			{/** @Component ApplicationFooter */}
			<Route path={`/`}
			       render={
				       (props) =>
					       <ApplicationFooterComponent
						       {...props}
						       activeLanguage={activeLanguage}
					       />
			       }
			/>
			
			{/** @Component ApplicationCopyrights */}
			<Route path={`/`}
			       render={
				       (props) =>
					       <ApplicationCopyrightsComponent
						       {...props}
						       activeLanguage={activeLanguage}
					       />
			       }
			/>
			
			{/** @Component ApplicationCopyrights */}
			<Route path={`/`}
			       render={
				       (props) =>
					       <ApplicationBottomBarComponent
						       {...props}
						       activeLanguage={activeLanguage}
					       />
			       }
			/>
			
		</Router>
	);
	
};


export default Application;
