import React, {useEffect, useState} from "react";


import {Container, Row, Col} from "react-bootstrap";


import {FaFacebookSquare, FaYoutube} from "react-icons/all";


export const ApplicationFollowUsComponent = ({activeLanguage}) => {
	
	console.log(activeLanguage)
	
	const sectionTitle = activeLanguage === "PL" ? "Śledź nas na" : "Follow us on";
	
	return (
		<Container as="aside" fluid={true} id="application-follow-us-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col as="h2" xs={12} className="block-section-header text-lg-center">
						<span>{sectionTitle}</span>
					</Col>
					
					<Col xs={12} className="follow-us-socials">
						<Row as="ul" className="follow-us-socials-list">
							<Col as="li" className="socials-list-item">
								<a href="https://facebook.com" title="Facebook" className="socials-list-link" target="_blank" rel="noreferrer noopener" onClick={e => e.preventDefault()}>
									<FaFacebookSquare />
								</a>
							</Col>
							<Col as="li" className="socials-list-item">
								<a href="https://youtube.com" title="YouTube" className="socials-list-link" target="_blank" rel="noreferrer noopener" onClick={e => e.preventDefault()}>
									<FaYoutube />
								</a>
							</Col>
						</Row>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default ApplicationFollowUsComponent;
