import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


const TEXTS = {
	PL: {payment_date_text: "Data", payment_value_text: "Kwota", payment_status_text: "Status"},
	EN: {payment_date_text: "Date", payment_value_text: "Amount", payment_status: "Status"},
};


export const PaymentListHeaderComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		payment_date_text: paymentDateText, payment_value_text: paymentValueText, payment_status_text: paymentStatusText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<Row className={"payment-list-header"}>
			<Col xs={3} className={"payment-date"}>{paymentDateText}</Col>
			<Col xs={3} className={"payment-value"}>{paymentValueText}</Col>
			<Col xs={3} className={"payment-type"}></Col>
			<Col xs={3} className={"payment-status"}>{paymentStatusText}</Col>
		</Row>
	);
	
};


export default PaymentListHeaderComponent;
