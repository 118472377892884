import React from "react";


import {Container, Row, Col} from "react-bootstrap"


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import imagePlaceholder from "@Assets/Images/no-image.png";


export const PricingPageHeaderSectionComponent = ({sectionData}) => {
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const  {
		section_title: sectionTitle,
		section_text: sectionText,
	} = sectionData;
	
	
	return (
		<Container as="section" fluid={true} id="pricing-page-header-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} lg={4} className="block-section-texts">
						<Row className="block-section-texts-content">
							
							<Col xs={12} as="h3" className="block-section-heading">
								<span>{sectionTitle}</span>
							</Col>
							
							<Col xs={12} as="article" className="block-section-text">
								<p>
									{sectionText}
								</p>
							</Col>
							
						</Row>
					</Col>
					
					<Col xs={12} lg={8} className="block-section-image">
						<Row className="block-section-image-content">
							<Col xs={12} as="picture" className="block-section-image-picture">
								<img src={imagePlaceholder} alt={sectionTitle} />
							</Col>
						</Row>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default PricingPageHeaderSectionComponent;
