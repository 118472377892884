import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormSkipButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Skip";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";



const CONTENT = {
	PL: {
		texts: {
			step_header: "Jaki standard ma mieszkanie",
			button_text: "Dalej",
			return_text: "Wróć",
			skip_text: "Pomiń",
		},
		
	},
	EN: {
		texts: {
			step_header: "What standard is the apartment",
			button_text: "Next",
			return_text: "Back",
			skip_text: "skip",
		},
		
	}
};


const STANDARDS = {
	PL: {1: "Wysoki", 2: "Dobry", 3: "Do odświeżenia", 4: "Do generalnego remontu", 0: "Nie wybrano"},
	EN: {1: "High", 2: "Good", 3: "To refresh", 4: "For general renovation", 0: "Not selected"},
};


export const CheckFormStep9SectionComponent = ({
	activeLanguage,
	formData, formDataHandler,
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatStandard
	} = formData;
	
	
	const [checkFlatFormDataFlatStandard, setCheckFlatFormDataFlatStandard] = useState(flatStandard || null);
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const skipStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(false, true);
	};
	
	const submitCheckFlatFormStep = (reset = false, skip = false) => {
		formDataHandler({
			flatStandard: reset || skip ? null : checkFlatFormDataFlatStandard
		}, reset ? 7 : 9);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-6-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={12} className="check-form-step-content user-form-content">
				
				<Row className={"check-form-standards-list"}>
				
					<Col xs={6} lg={3} className={"check-form-standards-item"}>
						<Row className={"check-form-standards-item-content"}>
							<Col xs={12} className={"standards-item-header"}>
								<span
									className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatStandard === "1" ? " selected" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("1")}
								></span>
								<label
									className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatStandard === "1" ? " active" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("1")}
								>
									{STANDARDS[activeLanguage][1]}
								</label>
							</Col>
							<Col xs={12} className={"standards-item-image"}>
								<img src={imagePlaceholder} alt={STANDARDS[activeLanguage][1]} onClick={() => setCheckFlatFormDataFlatStandard("1")}/>
							</Col>
						</Row>
					</Col>
					
					<Col xs={6} lg={3} className={"check-form-standards-item"}>
						<Row className={"check-form-standards-item-content"}>
							<Col xs={12} className={"standards-item-header"}>
								<span
									className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatStandard === "2" ? " selected" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("2")}
								></span>
								<label
									className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatStandard === "2" ? " active" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("2")}
								>
									{STANDARDS[activeLanguage][2]}
								</label>
							</Col>
							<Col xs={12} className={"standards-item-image"}>
								<img src={imagePlaceholder} alt={STANDARDS[activeLanguage][2]} onClick={() => setCheckFlatFormDataFlatStandard("2")}/>
							</Col>
						</Row>
					</Col>
					
					<Col xs={6} lg={3} className={"check-form-standards-item"}>
						<Row className={"check-form-standards-item-content"}>
							<Col xs={12} className={"standards-item-header"}>
								<span
									className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatStandard === "3" ? " selected" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("3")}
								></span>
								<label
									className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatStandard === "3" ? " active" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("3")}
								>
									{STANDARDS[activeLanguage][3]}
								</label>
							</Col>
							<Col xs={12} className={"standards-item-image"}>
								<img src={imagePlaceholder} alt={STANDARDS[activeLanguage][3]} onClick={() => setCheckFlatFormDataFlatStandard("3")}/>
							</Col>
						</Row>
					</Col>
					
					<Col xs={6} lg={3} className={"check-form-standards-item"}>
						<Row className={"check-form-standards-item-content"}>
							<Col xs={12} className={"standards-item-header"}>
								<span
									className={"step-option-input user-form-option-input radio-input" + (checkFlatFormDataFlatStandard === "4" ? " selected" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("4")}
								></span>
								<label
									className={"step-option-label user-form-option-label radio-input-label" + (checkFlatFormDataFlatStandard === "4" ? " active" : "")}
									onClick={() => setCheckFlatFormDataFlatStandard("4")}
								>
									{STANDARDS[activeLanguage][4]}
								</label>
							</Col>
							<Col xs={12} className={"standards-item-image"}>
								<img src={imagePlaceholder} alt={STANDARDS[activeLanguage][4]} onClick={() => setCheckFlatFormDataFlatStandard("4")}/>
							</Col>
						</Row>
					</Col>
					
				</Row>
				
			</Col>
			
		</Row>
	);
	
};


export default CheckFormStep9SectionComponent;
