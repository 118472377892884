import React, {useState, useEffect} from "react";


import {Row, Col, Form, Button} from "react-bootstrap"

import {GiCheckMark} from "react-icons/all";
import {NavLink} from "react-router-dom";


export const PackageOptionComponent = ({
	optionData, optionId,
	chosenPackageOption, chosenPackageOptionHandler, chosenPaymentOption,
	activeLanguage, optionTexts,
	addToCartHandler
}) => {
	
	
	const {
		package_name: packageName,
		package_information: packageInformation,
		package_checks_number: packageChecksNumber,
		package_legend_label: packageLegendLabel,
		package_button_label: packageButtonLabel
	} = optionData;
	
	
	const [selectedChecksNumber, setSelectedChecksNumber] = useState("1");
	
	const changeSelectedChecksNumber = (option) => {
		setSelectedChecksNumber(option);
	};
	
	
	const defaultPackagePrice =
		packageChecksNumber[1] ? packageChecksNumber[selectedChecksNumber]["checks_number_prices"]["2"] : null
	;
	
	const oneTimePackagePrice =
		packageChecksNumber[1] ? packageChecksNumber[selectedChecksNumber]["checks_number_prices"]["1"] : null
	;
	
	const packagePrice = chosenPaymentOption === "1" ? oneTimePackagePrice : defaultPackagePrice


	useEffect(() => {
		return () => {
			setSelectedChecksNumber("1");
		};
	}, []);
	
	
	const {
		singularCheckText, packageCheckText, priceLabelText
	} = optionTexts;
	
	
	const packageInformationList = packageInformation[0] ? packageInformation.map((information, i) => {
		const {
			information_icon: informationIcon, information_text: informationText
		} = information;
		return (
			<Col key={i} xs={12} className="single-info">
				{informationIcon && <GiCheckMark />}
				<span>{informationText[activeLanguage]}</span>
			</Col>
		);
	}) : (
		<Col xs={12} className="single-info individual-info">
			<span>{packageInformation[activeLanguage]}</span>
		</Col>
	);
	
	
	const addPackageToCart = () => {
		const packageData = {
			packageID: optionId,
			packageChecks: selectedChecksNumber
		}
		addToCartHandler(packageData);
	};
	
	
	return (
		<Col xs={12} lg={3} className={"options-list-item text-center" + (chosenPackageOption === optionId ? " active" : "")}>
			<Row className="options-list-item-content justify-content-center">
				
				<Col xs={12} className="options-list-item-header" onClick={() => chosenPackageOptionHandler(optionId)}>
					<span>{packageName}</span>
				</Col>
				
				<Col xs={12} className="options-list-item-info">
					{packageInformationList}
				</Col>
				
				<Col xs={12} className="options-list-item-legend">
					{packageLegendLabel &&
					<span>{packageLegendLabel[activeLanguage]}</span>
					}
				</Col>
				
				<Col xs={12} className="options-list-item-choice">
					{packageChecksNumber[1] &&
					<Form.Group controlId="checks-choice">
						<Form.Label>{packageChecksNumber[activeLanguage]}</Form.Label>
						<span className="label-info">{selectedChecksNumber === "1" ? singularCheckText : packageCheckText}</span>
						<Form.Control
							as={"select"}
							disabled={!(chosenPackageOption === optionId)}
							onChange={(e) => changeSelectedChecksNumber(e.target.value)}
							value={selectedChecksNumber}
						>
							{Object.entries(packageChecksNumber).map(([id, value]) => {
								const {
									checks_number_value: checksNumberValue,
									checks_number_prices: checksNumberPrices
								} = value;
								return (
									<option key={id} value={id}>{checksNumberValue}</option>
								);
							})}
						</Form.Control>
					</Form.Group>
					}
					{!packageChecksNumber[1] &&
					<span>{packageChecksNumber[activeLanguage]}</span>
					}
				</Col>
				
				<Col xs={12} className="options-list-item-actions">
					<Col xs={12} className="options-list-item-price">
						{packagePrice !== null && <>
						<span className="price-label">{priceLabelText}</span>
						{chosenPaymentOption === "1" &&
						<span className="price-old-value">{defaultPackagePrice * packageChecksNumber[selectedChecksNumber]["checks_number_value"]} {"PLN"}</span>
						}
						<span className="price-new-value">
							{packagePrice * packageChecksNumber[selectedChecksNumber]["checks_number_value"]} {"PLN"}
						</span>
						</>}
					</Col>
					<Col xs={12} className="options-list-item-submit">
						{+optionId !== 4 &&
						<Button disabled={!(chosenPackageOption === optionId)} onClick={() => addPackageToCart()}>
							<span>{packageButtonLabel[activeLanguage]}</span>
						</Button>
						}
						{+optionId === 4 &&
						<NavLink to={{pathname: activeLanguage === "PL" ? "/kontakt" : "contact-us", state: {contactFormSubject: "1"}}} title={""} className={"btn"}>
							<span>{packageButtonLabel[activeLanguage]}</span>
						</NavLink>
						}
					</Col>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default PackageOptionComponent;
