import React from "react";

import {useSelector} from "react-redux";

import {Col, Form} from "react-bootstrap";


export const FormTextFieldComponent = ({
	fieldValue = "", fieldValueHandler = null,
	fieldLabel = "", fieldPlaceholder = "",
	fieldMandatoryStatus = true, fieldError = null, fieldDisabledStatus = false,
	fieldType = "text", fieldControlID = null,
	xs = 12, sm = null, lg = 12, xl = null
}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	
	
	return (
		<Form.Group
			as={Col} xs={xs} sm={sm} lg={lg} xl={xl}
			className={`form-field ${fieldType}-field`}
			controlId={fieldControlID}
		>
			
			{fieldLabel &&
			<Form.Label>
				<span>{fieldLabel}</span>
				{fieldMandatoryStatus && <span className={"asterisk"}>*</span>}
			</Form.Label>
			}
			
			<Form.Control
				type={fieldType}
				value={fieldValue || ""}
				onChange={e => fieldValueHandler(e.target.value)}
				placeholder={fieldPlaceholder}
				disabled={fieldDisabledStatus || REST_API_REQUEST_STATUS}
				className={fieldError !== null ? "error" : ""}
			/>
			
			{fieldError &&
			<Form.Label className={"error"}>{fieldError}</Form.Label>
			}
			
		</Form.Group>
	);
	
};


export default FormTextFieldComponent;
