import React from "react";

import {useSelector} from "react-redux";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


const TEXTS = {
	PL: {
		you_have_text: "masz", available_reports_text: "dostępnych sprawdzeń", go_to_check_flat_text: "Przejdź do CheckFlata"
	},
	EN: {
		you_have_text: "you have", available_reports_text: "available checks", go_to_check_flat_text: "Go to CheckFlat"
	}
};


export const GoToCheckFlatComponent = ({availableReportsNumber}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		you_have_text: youHaveText,
		available_reports_text: availableReportsText,
		go_to_check_flat_text: goToCheckFlatText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<Col xs={12} className={"go-to-checkflat"}>
			<span className={"go-to-label"}>
				{youHaveText} <strong>{availableReportsNumber}</strong> {availableReportsText}.
			</span>
			<NavLink to={`/checkflat`} title={goToCheckFlatText} className={"go-to-link"}>
				<span>{goToCheckFlatText}</span>
			</NavLink>
		</Col>
	);
	
};


export default GoToCheckFlatComponent;
