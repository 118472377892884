import React, {useEffect, useRef} from "react";

import {Container, Row, Col} from "react-bootstrap";


import CartPageHeaderSectionComponent from "@UIPages/Common/CartPage/_Parts/Header";
import CartPackageProductComponent from "@UIPages/Common/CartPage/Views/Cart/PackageProduct";
import CartExtraProductsComponent from "@UIPages/Common/CartPage/Views/Cart/ExtraProducts";
import CartShoppingCartComponent from "@UIPages/Common/CartPage/Views/Cart/ShoppingCart";
import scrollToDomElement from "@Utilities/Scrolling";


const TEXTS = {
	PL: {header_text: "Twój koszyk (1)"},
	EN: {header_text: "Your Cart (1)"},
}


export const CartPageCartComponent = ({
	activeLanguage,
	paymentType,
	chosenPackage, chosenPackageData,
	changePackageChecksNumberHandler,
	extraProducts, extraProductsHandler,
	cartTotalPrices, discountCode, discountCodeHandler,
	editProductHandler, removeProductHandler, cartStepHandler
}) => {
	
	
	const containerRef = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(containerRef.current, 250);
		return () => {};
	}, []);
	
	
	if (!paymentType || !chosenPackage || !chosenPackageData) {
		return null;
	}
	
	
	const COMPONENT_TEXTS = TEXTS[activeLanguage];
	
	const {
		header_text: headerText
	} = COMPONENT_TEXTS;

	
	return (
		<>
			
			
			<CartPageHeaderSectionComponent
				sectionData={{headerText}}
			/>
			
			
			<Container ref={containerRef} as="section" fluid={true} id="cart-page-content-section-component" className="block-section-container-fluid">
				<Container className="block-section-container">
					<Row className="block-section-content">
					
						<Col xs={12} lg={8} className={"cart-content-column cart-content-left-column"}>
						
							<CartPackageProductComponent
								activeLanguage={activeLanguage}
								paymentType={paymentType}
								chosenPackage={chosenPackage}
								chosenPackageData={chosenPackageData}
								changePackageChecksNumberHandler={changePackageChecksNumberHandler}
								editProductHandler={editProductHandler}
								removeProductHandler={removeProductHandler}
							/>
							
							<CartExtraProductsComponent
								activeLanguage={activeLanguage}
								extraProducts={extraProducts}
								extraProductsHandler={extraProductsHandler}
							/>
							
						</Col>
						
						<Col xs={12} lg={4} className={"cart-content-column cart-content-right-column"}>
							<CartShoppingCartComponent
								activeLanguage={activeLanguage}
								cartTotalPrices={cartTotalPrices}
								discountCode={discountCode}
								discountCodeHandler={discountCodeHandler}
								cartStepHandler={cartStepHandler}
							/>
						</Col>
						
					</Row>
				</Container>
			</Container>
		
		</>
	)
	
};


export default CartPageCartComponent;
