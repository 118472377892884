import React, {useState, useEffect} from "react";

import {useSelector} from "react-redux";


import {Row, Col, Form, Button} from "react-bootstrap";


const TEXTS = {
	PL: {
		checks_option_1_text: "członkowie mogą korzystać z całej puli sprawdzeń",
		checks_option_2_text: "sprawdzenia ustalane indywidualnie",
		checks_option_3_text: "członkowie nie mogą korzystać z puli sprawdzeń",
		create_text: "utwórz"
	},
	EN: {
		checks_option_1_text: "members can use the entire pool of checks",
		checks_option_2_text: "checks determined individually",
		checks_option_3_text: "members cannot use the check pool",
		create_text: "create"
	},
};

const LABELS = {
	PL: {team_name_label: "Nazwa zespołu (opcjonalnie)", team_checks_label: "Domyślna opcja sprawdzeń"},
	EN: {team_name_label: "Team Name (optional)", team_checks_label: "Default checks type"},
};

const MESSAGES = {
	PL: {},
	EN: {},
};


export const CreateTeamFormComponent = ({createTeamRequestHandler}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		checks_option_1_text: checksOption1Text, checks_option_2_text: checksOption2Text, checks_option_3_text: checksOption3Text,
		create_text: createText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	const {team_name_label: teamNameLabel, team_checks_label: teamChecksLabel} = LABELS[ACTIVE_LANGUAGE];
	
	const {} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const [createTeamFormTeamName, setCreateTeamFormTeamName] = useState("");
	const [createTeamFormChecksType, setCreateTeamFormChecksType] = useState("1");
	
	useEffect(() => {
		return () => {
			setCreateTeamFormTeamName("");
			setCreateTeamFormChecksType("");
		};
	}, []);
	
	
	const createTeamSubmitHandler = () => {
		createTeamRequestHandler(createTeamFormTeamName, createTeamFormChecksType);
	};
	
	
	return (
		<Row className={"create-team-form-component create-team-form"}>
			
			<Col xs={12} className={"team-form-name team-form-field"}>
				<Form.Label>{teamNameLabel}</Form.Label>
				<Form.Control
					value={createTeamFormTeamName}
					onChange={e => setCreateTeamFormTeamName(e.target.value)}
				/>
			</Col>
			
			<Col xs={12} className={"team-form-checks-type team-form-field"}>
				<Form.Label>{teamChecksLabel}</Form.Label>
				<Form.Control
					as={"select"}
					value={createTeamFormChecksType}
					onChange={e => setCreateTeamFormChecksType(e.target.value)}
				>
					<option value={"1"}>{checksOption1Text}</option>
					<option value={"2"}>{checksOption2Text}</option>
					<option value={"3"}>{checksOption3Text}</option>
				</Form.Control>
				
			</Col>
			
			<Col xs={12} className={"team-form-submit team-form-field text-center"}>
				<Button variant={"primary"} className={"btn-large"} onClick={() => createTeamSubmitHandler()}>
					<span>{createText}</span>
				</Button>
			</Col>
			
		</Row>
	);
	
};


export default CreateTeamFormComponent;
