import React, {useState, useEffect} from "react";

// import parse from 'html-react-parser';


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";

import {HiArrowRight} from "react-icons/all";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


const HyperLinkComponent = ({url, clickHandler = null, text}) => {
	return (
		<NavLink to={url} title={text} onClick={clickHandler}>
			<span>{text}</span>
			<HiArrowRight />
		</NavLink>
	)
}


const COMPONENT_DATA = {
	"add_radar_text_label": {
		"PL": "Dodatkowo zniżki na Adradar i webinary w promocyjnych cenach!",
		"EN": "Plus discounts on Adradar and webinars at promotional prices!"
	},
	"legend_paragraphs_content": [
		{"paragraph_text": {
			"PL": "Każde sprawdzenie do wykorzystania przez rok!",
			"EN": "Each check can be used for a year!"
		}, "paragraph_extra_css_class": "legend-label"},
		{"paragraph_text": {
			"PL": <>
				Na ten moment sprawdzamy mieszkania w wybranych lokalizacjach.
				<HyperLinkComponent url={"#localization"} text={"Sprawdź lokalizację"} clickHandler={e => e.preventDefault()} />
			</>,
			"EN": <>
				Do you want to check other properties or locations?
				<HyperLinkComponent url={"#localization"} text={"Check the location"} clickHandler={e => e.preventDefault()} />
			</>
		}},
		{"paragraph_text": {
			"PL": <>
				Chcesz sprawdzić inne nieruchomości lub lokalizacje?.
				<HyperLinkComponent url={"/kontakt"} text={"Skontaktuj się z nami"} />
			</> ,
			"EN": <>
				Do you want to check other properties or locations?
				<HyperLinkComponent url={"/contact"} text={"Contact Us"} />
			</>
		}},
	]
};


export const PricingSectionLegendComponent = ({componentData, activeLanguage}) => {
	
	
	const [pricingSectionLegendData, setPricingSectionLegendData] = useState(null);
	
	useEffect(() => {
		setPricingSectionLegendData(COMPONENT_DATA);
		return () => {
			setPricingSectionLegendData(null);
		};
	}, []);
	
	
	if (!pricingSectionLegendData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		add_radar_text_label: addRadarTextLabel,
		legend_paragraphs_content: legendParagraphsContent
	} = pricingSectionLegendData
	
	
	const legendParagraphsList = legendParagraphsContent.map((paragraph, i) => {
		const {
			paragraph_text: paragraphText, paragraph_extra_css_class: paragraphExtraCssClass
		} = paragraph;
		return (
			<Row key={i} className={"legend-information-paragraph" + (paragraphExtraCssClass ? ` ${paragraphExtraCssClass}` : "")}>
				<p>{paragraphText[activeLanguage]}</p>
			</Row>
		);
	});
	
	
	return (
		<Col as={"section"} xs={12} className={"pricing-section-legend-component"}>
			<Row className={"pricing-section-legend-component-content"}>
			
				<Col xs={12} lg={{span: 10, offset: 1}} className={"legend-component-add-radar"}>
					<span>{addRadarTextLabel[activeLanguage]}</span>
				</Col>
				
				<Col xs={12} className={"legend-component-information"}>
					{legendParagraphsList}
				</Col>
			
			</Row>
		</Col>
	);
	
};


export default PricingSectionLegendComponent;
