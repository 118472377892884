import React from "react";

import {Container, Row, Col} from "react-bootstrap";


export const CartPageHeaderSectionComponent = ({sectionData}) => {
	
	
	if (!sectionData) {
		return null;
	}
	
	
	const {
		headerText
	} = sectionData;
	
	
	return (
		<Container as="section" fluid={true} id="cart-page-header-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col as={"h1"} xs={12} className="main-section-header">
						<span>{headerText}</span>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default CartPageHeaderSectionComponent;
