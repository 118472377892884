import React, {useContext, useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Col} from "react-bootstrap";


import CreateTeamSwitcherComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/CreateTeam/Switcher";
import CreateTeamFormComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/CreateTeam/Form";


export const CreateTeamComponent = ({CHECK_FLAT_USER_DATA, teamDataHandler = null}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	
	const [createTeamActiveStatus, setCreateTeamActiveStatus] = useState(false);
	const [createTeamSubmitAttemptStatus, setCreateTeamSubmitAttemptStatus] = useState(false);
	const [createTeamSubmitSuccessStatus, setCreateTeamSubmitSuccessStatus] = useState(false);
	
	
	useEffect(() => {
		return () => {
			setCreateTeamActiveStatus(false);
			setCreateTeamSubmitAttemptStatus(false);
			setCreateTeamSubmitSuccessStatus(false);
		};
	}, []);
	
	
	const createTeamActiveStatusHandler = (e) => {
		e.preventDefault();
		setCreateTeamActiveStatus(!createTeamActiveStatus);
	};
	
	
	const createTeamRequestHandler = async (teamName, teamDefaultChecks) => {
	
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const createTeamFormData = new FormData();
		createTeamFormData.append("users_team_super_user", `${REST_API_URL}/app/users/list/${userID}/`);
		createTeamFormData.append("users_team_name", teamName);
		createTeamFormData.append("users_team_default_checks_option", teamDefaultChecks);
		
		try {
			const response = await fetch(`${REST_API_URL}/app/users/teams/`, {method: "POST", body: createTeamFormData});
			if (!response.ok) {
				console.log(response.status, response.statusText, response.text());
			} else {
				try {
					const responseContent = await response.json();
					if (responseContent.code === 201) {
						const {created_object_data: createdTeam} = responseContent.data;
						teamDataHandler();
					} else {
						alert("Błąd Tworzenia zespołu");
					}
					DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
				} catch (e) {
					console.log(e, "JSON");
				}
			}
		} catch (e) {
			console.log(e, "FETCH");
		}
		
	};
 
	
	if (!CHECK_FLAT_USER_DATA) return null;
	
	
	const {userID} = CHECK_FLAT_USER_DATA
	
	console.log(userID)
	if (createTeamSubmitSuccessStatus) return null;
	
	
	return (
		<Col xs={12} className={"create-team-component create-team-form"}>
			
			<CreateTeamSwitcherComponent
				createTeamActiveStatus={createTeamActiveStatus}
				createTeamActiveStatusHandler={createTeamActiveStatusHandler}
			/>
			
			{createTeamActiveStatus &&
			<CreateTeamFormComponent
				createTeamSubmitAttemptStatus={createTeamSubmitAttemptStatus}
				createTeamRequestHandler={createTeamRequestHandler}
			/>
			}
			
		</Col>
	);
	
};


export default CreateTeamComponent;
