import {LOG_USER_IN, LOG_USER_OUT} from "@Store/Actions";


const loggedUser = JSON.parse(localStorage.getItem("aggeLoggedUser"));

const initialState = {
	loggedUser: loggedUser
};


export const authenticationReducer = (state = initialState, action) => {
	
	switch (action.type) {
		
		case LOG_USER_IN:
			localStorage.setItem("aggeLoggedUser", JSON.stringify(action.userData))
			return {...state, loggedUser: action.userData};
			
		case LOG_USER_OUT:
			localStorage.clear();
			return {...state, loggedUser: null};
	
		default:
			return state;
			
	}
	
};


export default authenticationReducer;
