import React, {useContext} from "react";

import {useSelector} from "react-redux";


import TranslationsContext from "@Contexts";


import {Col} from "react-bootstrap";


export const CheckFormSkipButtonComponent = ({buttonText = "", cssClasses = "", clickHandler = null, ...props}) => {
	
	
	const activeLanguage = useSelector(state => state.localization).activeLanguage.languageCode;
	const WORDS = useContext(TranslationsContext).SINGLE_WORDS;
	
	buttonText = buttonText || (WORDS?.skip[activeLanguage] || "skip");
	
	
	return (
		<Col {...props} className={"user-form-footer-skip"}>
			<a href={"#back"} title={buttonText} onClick={clickHandler} className={"skip-button"}>
				<span>{buttonText}</span>
			</a>
		</Col>
	);
	
};


export default CheckFormSkipButtonComponent;
