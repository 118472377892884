import React from "react";


import {Container, Row, Col} from "react-bootstrap";

import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/all";

import Carousel, {slidesToShowPlugin, autoplayPlugin, arrowsPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


import img1 from "./_images/S1.png";
import img2 from "./_images/S2.jpg";
import img3 from "./_images/S3.jpg";
import img4 from "./_images/S4.jpg";
import {useSelector} from "react-redux";


const OPINIONS = [
	{
		id: 1, user: {id: 1, avatar: img1, name: "Ferdynand Lipski"},
		title: {PL: "Kupił mieszkanie w Warszawie", EN: "He bought an apartment in Warsaw"},
		opinion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."
	},
	{
		id: 2, user: {id: 2, avatar: img2, name: "Stefan “Siara” Siarzewski"},
		title: {PL: "Sprzedał dom w Pruszkowie", EN: "He sold a house in Pruszków"},
		opinion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."
	},
	{
		id: 3, user: {id: 3, avatar: img3, name: "Wąski"},
		title: {PL: "Kupił mieszkanie w Warszawie", EN: "He bought an apartment in Warsaw"},
		opinion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."
	},
	{
		id: 4, user: {id: 4, avatar: img4, name: "Jose Arcadio Morales"},
		title: {PL: "Sprzedał dom w Pruszkowie", EN: "He sold a house in Pruszków"},
		opinion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."
	}
];


const SECTION_DATA = {
	section_title: {PL: "Zaufali nam", EN: "They trusted us"}
}


export const ApplicationOpinionsComponent = ({}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode
	
	
	const {
		section_title: sectionTitle
	} = SECTION_DATA;
	
	
	const opinionsList = OPINIONS.map(opinion => {
		
		return (
			<div key={opinion.id} className="single-opinion">
				<Row className="single-opinion-content">
					<Col xs={12} className="single-opinion-user">
						<Row>
							<Col xs={4} className="opinion-user-avatar">
								<span>
									<img src={opinion.user.avatar} alt={opinion.user.name} />
								</span>
							</Col>
							<Col xs={8} className="opinion-user-name">
								<span>{opinion.user.name}</span>
							</Col>
						</Row>
					</Col>
					<Col xs={12} className="single-opinion-content">
						<Row>
							<Col xs={12} className="opinion-content-title">
								<span>{opinion.title[ACTIVE_LANGUAGE]}</span>
							</Col>
							<Col xs={12} className="opinion-content-text">{opinion.opinion}</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	});
	
	
	return (
		<Container as="aside" fluid={true} id="application-opinions-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col as="h2" xs={12} className="block-section-header">
						<span>{sectionTitle[ACTIVE_LANGUAGE]}</span>
					</Col>
					
					<Carousel
						plugins={[
							"autoplay",
							"infinite",
							{resolve: arrowsPlugin, options: {
								arrowLeft: <MdKeyboardArrowLeft />,
								arrowLeftDisabled: <MdKeyboardArrowLeft />,
								arrowRight: <MdKeyboardArrowRight />,
								arrowRightDisabled: <MdKeyboardArrowRight />,
								addArrowClickHandler: true,
							}},
							{resolve: slidesToShowPlugin, options: {numberOfSlides: 2}},
							{resolve: autoplayPlugin, options: {interval: 4000}},
						]}
						breakpoints={{
							640: {
								plugins: [{
									resolve: slidesToShowPlugin,
									options: {numberOfSlides: 1}
								}]
							},
							900: {
								plugins: [{
									resolve: slidesToShowPlugin,
									options: {numberOfSlides: 2}
								},
							]}
						}}
					>
						{opinionsList}
					</Carousel>
				
				</Row>
			</Container>
		</Container>
	);


};


export default ApplicationOpinionsComponent;

