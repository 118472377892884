import React, {useEffect, useState} from "react";


import CheckFlatPageHeaderSectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/HeaderSection";
import CheckFlatPageCheckFormSectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection";


const DATA = {
	PL: {header_section: {
			section_title: "Checkflat",
			section_text: "Sprawdź wartość mieszkania"
		},
	},
	EN: {
		header_section: {
			section_title: "Checkflat",
			section_text: "Check the value of the apartment"
		}
	}
};


export const ApplicationCheckFlatPageComponent = ({activeLanguage}) => {
	
	
	const [checkFlatPageData, setCheckFlatPageData] = useState(null);
	
	
	const storedCheckFlatFormData = JSON.parse(localStorage.getItem("checkFlatFormData"));
	
	const [checkFlatFormData, setCheckFlatFormData] = useState(
		storedCheckFlatFormData?.formData || {}
	);
	const [checkFlatFormStep, setCheckFlatFormStep] = useState(
		+storedCheckFlatFormData?.formStep || 1
	);
	
	
	useEffect(() => {
		setCheckFlatPageData(DATA[activeLanguage]);
		return () => {
			setCheckFlatPageData(null);
			setCheckFlatFormData({formStep: 1});
			setCheckFlatFormStep(1);
		};
	}, []);
	
	
	const headerSectionData = checkFlatPageData?.header_section || null;
	
	
	return (
		<>
			
			{checkFlatFormStep !== 1 &&
			<h2 className="chekckflat-steps-header">
				<span className="header-label">{`Checkflat`}</span>
				<span className="header-value"> - Sprawdź wartość mieszkania w kilku krokach</span>
			</h2>
			}
			
			<CheckFlatPageHeaderSectionComponent
				sectionData={headerSectionData}
				checkFlatFormStep={checkFlatFormStep}
			/>
			
			<CheckFlatPageCheckFormSectionComponent
				activeLanguage={activeLanguage}
				checkFlatFormStep={checkFlatFormStep}
				checkFlatFormStepHandler={setCheckFlatFormStep}
				checkFlatFormData={checkFlatFormData}
				checkFlatFormDataHandler={setCheckFlatFormData}
			/>
		
		</>
	);
	
};


export default ApplicationCheckFlatPageComponent;
