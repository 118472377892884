import React from "react";

import {useSelector} from "react-redux";

import parse from "html-react-parser";


import {Col, Row} from "react-bootstrap";


import StepsHeaderProgressBarComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Header/ProgressBar";
import StepsHeaderHeadComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Header/Head";


const TEXTS = {
	PL: {
		header_text: "Sprawdź wartość mieszkania",
		check_word_text: "Twoje Sprawdzenie",
		of_word_text: "z",
		in_package_word_text: "w pakiecie"
	},
	EN: {
		header_text: "Check the value of the apartment",
		check_word_text: "Check",
		of_word_text: "of",
		in_package_word_text: "in package"
	}
};


export const CheckFormStepsHeaderComponent = ({
	checkFormStep = null, checkFormHeaderLabel = ""
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage.languageCode || "PL";
	
	const {
		header_text: headerText,
		check_word_text: checkWordText, of_word_text: ofWordText, in_package_word_text: inPackageWordText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const LOGGED_USER = useSelector(state => state?.authentication?.loggedUser);
	console.log(LOGGED_USER)
	const {
		user_orders: userOrders
	} = LOGGED_USER || {};
	
	const userActiveOrder = userOrders && userOrders.length > 0 ? userOrders[0] : null;
	
	let userTotalReports = 0;
	let userUsedReports = 0;
	let userActiveReports = 0;

	if (userActiveOrder) {
		const {estate_reports_order_reports: userOrderReports} = userActiveOrder;
		userTotalReports = userOrderReports.length;
		userUsedReports = userOrderReports.filter(report => {
			const {estate_report_date: estateReportDate} = report;
			return !!estateReportDate;
		}).length;
		userActiveReports = userTotalReports - userUsedReports;
	}
	
	
	return (
		<Row className={"check-form-steps-header"}>
			
			<StepsHeaderHeadComponent checkFormStep={checkFormStep} headerText={headerText} />
			
			
			<Col xs={12} className={"check-form-steps-legend"}>
				{LOGGED_USER && userActiveReports > 0 &&
				<span className="checks-number-information">
					{checkWordText} <span className="checks-number-value">{userUsedReports + 1}</span> {ofWordText} {userTotalReports} {inPackageWordText}
				</span>
				}
				{LOGGED_USER && userActiveReports > 0  && checkFormHeaderLabel &&
				<span className="separator">:</span>
				}
				{checkFormHeaderLabel &&
				<span className="legend-information-label">{parse(checkFormHeaderLabel)}</span>
				}
			</Col>
			
			
			<StepsHeaderProgressBarComponent checkFormStep={checkFormStep} />
			
		</Row>
	);
	
};


export default CheckFormStepsHeaderComponent;
