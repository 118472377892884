import React, {useState, useEffect, useRef} from "react";


import {Container, Row, Col, Form} from "react-bootstrap"


import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api';


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


export const PricingPageMapSectionComponent = ({sectionData}) => {
	
	
	const supportedLocalizationsObjects = [
		{localization_name: "Warszawa", localization_coords: {lat: 52.13, lng: 21.00}},
		{localization_name: "Gdańsk", localization_coords: {lat: 54.20, lng: 18.38}},
		{localization_name: "Gdynia", localization_coords: {lat: 54.31, lng: 18.32}},
		{localization_name: "Kraków", localization_coords: {lat: 50.03, lng: 19.56}},
		{localization_name: "Wrocław", localization_coords: {lat: 51.06, lng: 17.01}},
	];
	
	const [mapSupportedLocalizations, setMapSupportedLocalizations] = useState(supportedLocalizationsObjects);
	
	
	const {isLoaded: isMapLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyDIH0ikRFe6yGyi68nLEumr0evrbg5fjTM"
	});
	
	const [googleMapComponent, setGoogleMapComponent] = React.useState(null);
	
	useEffect(() => {
		setGoogleMapComponent(googleMapComponentRef);
		return () => {
			setGoogleMapComponent(null);
		};
	}, [isMapLoaded]);
	
	const googleMapComponentRef = useRef(null);

	
	const [searchLocationValue, setSearchLocationValue] = useState("");
	const [selectedLocalization, setSelectedLocalization] = useState({lat: 52.13, lng: 21.01});
	const [foundLocalizationsList, setFoundLocalizationsList] = useState([]);
	
	const changeSearchLocationValue = (value, trigger = false) => {
		
		setSearchLocationValue(value);
		
		if (value === "") {
			setSelectedLocalization({lat: 52.13, lng: 21.01});
			setMapSupportedLocalizations(supportedLocalizationsObjects);
			return null;
		}
		
		const supportedLocalizations = mapSupportedLocalizations.filter(localization =>
			localization.localization_name.toLowerCase().includes(value.toLowerCase())
		);
		
		let foundLocalizations;
		
		if (supportedLocalizations.length > 1) {
			setMapSupportedLocalizations([...supportedLocalizationsObjects]);
			foundLocalizations = supportedLocalizations;
		} else if (supportedLocalizations.length === 1) {
			setSelectedLocalization(supportedLocalizations[0].localization_coords);
			if (trigger) {
				setSearchLocationValue(supportedLocalizations[0].localization_name);
				foundLocalizations = [];
			} else {
				foundLocalizations = supportedLocalizations
			}
		} else {
			setSelectedLocalization({lat: 52.13, lng: 21.01});
			foundLocalizations = [{localization_name: notFoundText, error: true}];
		}
		
		setFoundLocalizationsList([...foundLocalizations]);
		
	};
	
	useEffect(() => {
		return () => {
			setSearchLocationValue("");
		};
	}, []);
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const  {
		search_label: searchLabel,
		search_placeholder: searchPlaceholder,
		not_found_text: notFoundText
	} = sectionData;
	
	
	const supportedLocalizationsList = mapSupportedLocalizations.map(localization => {
		const {
			localization_name: localizationName, localization_coords: localizationCoords
		} = localization;
		return (
			<Marker
				key={localizationName}
				position={localizationCoords}
				title={localizationName}
				localization={localizationName}
			/>
		)
	});
	
	
	const foundLocalizations = foundLocalizationsList.map(localization => {
		const {
			localization_name: localizationName
		} = localization;
		return (
			<div
				key={localizationName}
				className={"single-localization" + (localization.error ? " error": "")}
				onClick={() => changeSearchLocationValue(localization.error ? "" : localizationName, true)}
			>
				<span>{localizationName}</span>
			</div>
		);
	});
	
	
	return (
		<Container as="section" fluid={true} id="pricing-page-map-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} className="map-section-content">
						
						<Row className={"map-section-search"}>
							<Col xs={12} lg={6} className={"map-section-search-label"}>
								<span>{searchLabel}</span>
							</Col>
							<Col xs={12} lg={6} className={"map-section-search-field"}>
								<Form.Control
									placeholder={searchPlaceholder}
									value={searchLocationValue}
									onChange={e => changeSearchLocationValue(e.target.value)}
								/>
								{foundLocalizations.length > 0 &&
								<div className="found-localizations-list">
									{foundLocalizations}
								</div>
								}
							</Col>
						</Row>
						
						<Row className="map-section-map">
							<Col xs={12}>
								{isMapLoaded &&
								<GoogleMap
									ref={googleMapComponentRef}
									mapContainerStyle={{
										width: "100%",
										height: "100%"
									}}
									center={selectedLocalization}
									zoom={6}
								>
									{supportedLocalizationsList}
								</GoogleMap>
								}
								{!isMapLoaded && <MainContentLoaderComponent />}
							</Col>
						</Row>
						
					</Col>
					
				</Row>
			</Container>
		</Container>
	)
	
};


export default PricingPageMapSectionComponent;
