import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


import avatarPlaceholder from "@Assets/Images/no-avatar.jpg";


const TEXTS = {
	PL: {change_avatar_text: "zmień avatar"},
	EN: {change_avatar_text: "change avatar"},
}


export const MainDataSectionAvatarComponent = ({}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	const {
		change_avatar_text: changeAvatarText
	} = TEXTS[ACTIVE_LANGUAGE]
	
	
	return (
		<Col xs={12} lg={2} className={"profile-section user-avatar-data"}>
			<Row className={"user-avatar profile-section-content"}>
				
				<Col xs={12} as={"picture"} className={"user-avatar-picture"}>
					<img src={avatarPlaceholder} alt={""} />
				</Col>
				
				<Col xs={12} className={"user-avatar-change"}>
					<a href={"#"} onClick={e => e.preventDefault()} title={changeAvatarText}>
						<span>{changeAvatarText}</span>
					</a>
				</Col>
			
			</Row>
		</Col>
	);
	
};


export default MainDataSectionAvatarComponent;
