import React, {useEffect, useState} from "react";


import {Container, Row} from "react-bootstrap";


import HeaderLogoComponent from "@UIParts/Header/Logo";
import HeaderNavigationComponent from "@UIParts/Header/Navigation";
import HeaderUserMenuComponent from "@UIParts/Header/User";
import HeaderLanguagesComponent from "@UIParts/Header/Languages";


export const ApplicationHeaderComponent = ({activeLanguage}) => {
	
	
	const applicationHeaderInitialContent = {};
	
	const [applicationHeaderContent, setApplicationHeaderContent] = useState(
		applicationHeaderInitialContent
	);
	
	useEffect(
		() => {
			setApplicationHeaderContent({siteTitle: "Agge PWA"})
		},
		[]
	);
	
	if (!applicationHeaderContent) {
		return null;
	}
	
	const {
		siteTitle
	} = applicationHeaderContent;
	
	
	return (
		<Container as="header" fluid={true} id="application-header-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<HeaderLogoComponent
						siteTitle={siteTitle}
					/>
					
					<HeaderNavigationComponent />
					
					<HeaderUserMenuComponent
						activeLanguage={activeLanguage}
					/>
					
					<HeaderLanguagesComponent />
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default ApplicationHeaderComponent;
