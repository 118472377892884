import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {GLOBAL_POPUP_CONTENT_ACTION, STATE_UPDATE_HANDLER_ACTION} from "@Actions";


import {Button, Col} from "react-bootstrap";


const TEXTS = {
	PL: {
		header_message: "Już za Tobą tęsknimy!",
		message_1_text: "Twój pakiet jest już nieaktywny. Zwrot pieniędzy zgodnie z warunkami rezygnacji otrzymasz w ciągu 14 dni od rezygnacji",
		message_2_text: "Dziękujemy, że pomagasz nam stać się lepszym, dzięki zostawieniu informacji zwrotnej",
		button_text: "Do zobaczenia!"
	},
	EN: {
		header_message: "We miss you already!",
		message_1_text: "Your package is no longer active. The cancellation policy will be refunded within 14 days of cancellation",
		message_2_text: "Thank you for helping us become better by leaving feedback",
		button_text: "Do zobaczenia!"
	}
};


export const CancelledPackageMessageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		header_message: headerMessage,
		message_1_text: message1Text, message_2_text: message2Text,
		button_text: buttonText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<>
			
			<Col as={"h2"} xs={12} className={"global-popup-header"}>
				<span>{headerMessage}</span>
			</Col>
			
			<Col xs={12} className={"cancel-package-message"}>
				<p>{message1Text}</p>
			</Col>
			
			<Col xs={12} className={"cancel-package-message"}>
				<p>{message2Text}</p>
			</Col>
			
			<Col xs={12} className={"cancel-package-submit"}>
				<Button variant={"primary"} className={"btn-large"} onClick={e => {
					e.preventDefault();
					DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(null));
					DISPATCHER(STATE_UPDATE_HANDLER_ACTION(true));
				}}>
					<span>{buttonText}</span>
				</Button>
			</Col>
			
		</>
	);
	
	
};


export default CancelledPackageMessageComponent
