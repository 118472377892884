import React from "react";


import {NavLink} from "react-router-dom";


import {FaHome, GiHamburgerMenu, FaAt, BsFillPersonFill} from "react-icons/all";

import checkFlatIcon from "@Assets/Images/checkflat192.png";


const LINKS = {
	PL: {
		"home_page": {url: "", title: "Start"},
		"contact_page": {url: "kontakt", title: "Kontakt"},
		"checkflat_page": {url: "checkflat", title: "Checkflat"},
		"profile_page": {url: "profil", title: "Mój Checkflat"},
		// "home_page": {url: "", title: ""},
	},
	EN: {
		"home_page": {url: "", title: "Home"},
		"contact_page": {url: "/contact-us", title: "Contact"},
		"checkflat_page": {url: "checkflat", title: "Checkflat"},
		"profile_page": {url: "profile", title: "My Checkflat"},
	}
}


export const ApplicationBottomBarComponent = ({activeLanguage}) => {
	
	
	return (
		<nav id="application-bottom-bar-component" className={"bottom-bar-component"}>
			<ul className={"bottom-bar-navigation-links"}>
				
				<li className={"bottom-bar-navigation-link"}>
					<NavLink to={`/${LINKS[activeLanguage]["home_page"].url}`} title={`/${LINKS[activeLanguage]["home_page"].title}`}>
						<FaHome />
						<span>{`${LINKS[activeLanguage]["home_page"].title}`}</span>
					</NavLink>
				</li>
				
				<li className={"bottom-bar-navigation-link"}>
					<NavLink to={`/`} title={``} onClick={e => e.preventDefault()}>
						<GiHamburgerMenu />
						<span>{"Inne"}</span>
					</NavLink>
				</li>
				
				<li className={"bottom-bar-navigation-link"}>
					<NavLink to={`/${LINKS[activeLanguage]["checkflat_page"].url}`} title={`/${LINKS[activeLanguage]["checkflat_page"].title}`}>
						<img src={checkFlatIcon} alt={`/${LINKS[activeLanguage]["checkflat_page"].title}`} />
					</NavLink>
				</li>
				
				<li className={"bottom-bar-navigation-link"}>
					<NavLink to={`/${LINKS[activeLanguage]["contact_page"].url}`} title={`/${LINKS[activeLanguage]["contact_page"].title}`}>
						<FaAt />
						<span>{`${LINKS[activeLanguage]["contact_page"].title}`}</span>
					</NavLink>
				</li>
				
				<li className={"bottom-bar-navigation-link"}>
					<NavLink to={`/${LINKS[activeLanguage]["profile_page"].url}`} title={`/${LINKS[activeLanguage]["profile_page"].title}`}>
						<BsFillPersonFill />
						<span>{`${LINKS[activeLanguage]["profile_page"].title}`}</span>
					</NavLink>
				</li>
				
			</ul>
		</nav>
	);
	
};


export default ApplicationBottomBarComponent;
