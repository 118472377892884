const PARAGRAPHS = [
	`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac volutpat lectus, eget imperdiet mi. Fusce eget mauris et urna ornare molestie vel sit amet libero. Sed sed accumsan velit. Aliquam pretium commodo vulputate. Maecenas vehicula laoreet risus, eget porttitor felis. Donec efficitur rutrum est, ac rutrum ante porttitor eu. Nulla congue at nibh ut scelerisque.</p>`,
	`<p>Duis sapien est, volutpat iaculis consectetur vitae, iaculis in odio. Proin quam neque, aliquam vitae porta in, viverra a magna. Vestibulum lacinia tellus ut ante finibus fringilla. Etiam fringilla enim sit amet quam suscipit consequat. Etiam egestas elit nibh. Suspendisse est nisl, viverra sit amet semper vitae, pellentesque in nibh. Sed tristique purus vitae tincidunt venenatis. Nulla malesuada augue a vehicula pretium. Ut interdum sem in magna pharetra semper. Cras malesuada leo ex, eu gravida lectus sollicitudin a. Etiam sit amet purus ut lectus varius facilisis. Suspendisse faucibus neque sed ex fringilla tincidunt. Morbi ultrices aliquet volutpat.</p>`,
	`<p>In sit amet maximus enim, a malesuada sem. Sed egestas diam porta risus dignissim, sit amet tincidunt lorem dapibus. Etiam sagittis vehicula dapibus. Quisque et enim ut lorem hendrerit dignissim. Aliquam ex turpis, pulvinar quis efficitur eu, accumsan quis velit. Phasellus interdum tortor vel lacus laoreet varius. Ut nisi nibh, mollis et dolor in, tincidunt malesuada sem. Nam est nisl, consequat in consectetur id, bibendum vitae nisi. Fusce ornare odio ligula, quis viverra lacus porttitor in. Cras sit amet magna interdum, tempus felis id, facilisis sapien. Etiam commodo at nibh et bibendum. Pellentesque id nunc justo. Curabitur magna nulla, vulputate a turpis eget, fringilla convallis metus. Suspendisse potenti.</p>`,
	`<p>Phasellus faucibus ullamcorper posuere. Vestibulum commodo lorem eget neque pretium rutrum. Curabitur maximus mi eget mauris viverra accumsan. Aliquam egestas consequat nibh, ullamcorper tincidunt libero tincidunt quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec nunc lorem, porttitor sed risus tincidunt, vestibulum pretium mauris. Curabitur viverra tellus eu sodales ornare. Sed dignissim feugiat condimentum. Cras vitae risus ipsum. Sed eleifend, tortor et ullamcorper aliquam, turpis augue porta magna, ut imperdiet libero dui vitae ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce pellentesque hendrerit ultricies.`,
	`<p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer tempus nulla in iaculis dictum. Suspendisse dignissim tristique dignissim. Nullam erat neque, dapibus rutrum convallis ut, faucibus a metus. Aliquam vestibulum urna at vestibulum ultricies. Proin semper vestibulum metus, ac ullamcorper tellus accumsan id. Nunc lobortis maximus mi sed aliquet. Sed dapibus tincidunt sapien, sed ornare quam mattis at. Nulla malesuada metus id sodales gravida.</p>`
];


const TITLE = "Lorem ipsum dolor sit amet, consectetur adipiscing elit";


export default PARAGRAPHS.join("");


export {
	PARAGRAPHS, TITLE
};
