import React, {useState, useEffect} from "react";

import {useSelector} from "react-redux";


import {Col, Row, Form, Button} from "react-bootstrap";


import isEmail from "validator/es/lib/isEmail";


const TEXTS = {
	PL: {add_team_member_text: "Zaproś do zespołu"},
	EN: {add_team_member_text: "Invite to team"},
};

const LABELS = {
	PL: {
		email_label_text: "Email",
		checks_label_text: "Sprawdzenia",
	},
	EN: {
		email_label_text: "Email",
		checks_label_text: "Checks",
	}
};


const OPTIONS = {
	PL: {
		checks_option_1_text: "może korzystać z całej puli sprawdzeń",
		checks_option_3_text: "nie może korzystać z puli sprawdzeń",
	},
	EN: {
		checks_option_1_text: "can use the entire pool of checks",
		checks_option_3_text: "cannot use the check pool",
	},
};

const MESSAGES = {
	PL: {email_error_message: "Niepoprawny adres Email"},
	EN: {email_error_message: "Invalid Email Address"},
};


export const AddTeamMemberFormComponent = ({
	defaultFormUserChecks = "1", addTeamMemberRequestHandler = null
}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {add_team_member_text: addTeamMemberText} = TEXTS[ACTIVE_LANGUAGE];
	
	const {
		email_label_text: emailLabel, checks_label_text: checksLabel
	} = LABELS[ACTIVE_LANGUAGE];
	
	const {
		checks_option_1_text: checksOption1Text,
		// checks_option_2_text: checksOption2Text,
		checks_option_3_text: checksOption3Text
	} = OPTIONS[ACTIVE_LANGUAGE];
	
	const {
		email_error_message: emailErrorMessage
	} = MESSAGES[ACTIVE_LANGUAGE];
	console.log(defaultFormUserChecks)
	
	const [formErrors, setFormErrors] = useState({});
	
	const [formUserEmail, setFormUserEmail] = useState("");
	const [formUserChecks, setFormUserChecks] = useState(defaultFormUserChecks);
	
	const changeFormUserEmail = (email) => {
		if (isEmail(formUserEmail)) setFormErrors({...formErrors, emailAddress: null});
		setFormUserEmail(email);
	};
	
	
	useEffect(() => {
		
		return () => {
			setFormErrors({});
			setFormUserEmail("");
			setFormUserChecks(defaultFormUserChecks);
		};
		
	}, []);
	
	
	const addTeamMemberHandler = () => {
	
		const errors = {};

		if (!isEmail(formUserEmail)) errors.emailAddress = {errorMessage: emailErrorMessage};
		
		setFormErrors(errors);
		
		if (Object.entries(errors).length) return null;
		
		addTeamMemberRequestHandler(
			{formUserEmail, formUserChecks}
		);
		
	};
	
	
	return (
		<Row className={"add-team-member-form"}>
			
			<Col as={Form.Group} xs={12} controlId={"add-member-email"} className={"add-team-member-field"}>
				<Form.Label>{emailLabel}:</Form.Label>
				<Form.Control
					placeholder={emailLabel}
					value={formUserEmail}
					onChange={e => changeFormUserEmail(e.target.value)}
					className={formErrors?.emailAddress ? "error" : ""}
					disabled={REST_API_REQUEST_STATUS}
				/>
				{formErrors?.emailAddress &&
				<Form.Label className={"error"}>{formErrors?.emailAddress.errorMessage}</Form.Label>
				}
			</Col>
			
			<Col as={Form.Group} xs={12} controlId={"add-member-checks"} className={"add-team-member-field"}>
				<Form.Label>{checksLabel}:</Form.Label>
				<Form.Control
					as={"select"}
					value={formUserChecks}
					onChange={e => setFormUserChecks(e.target.value)}
					disabled={REST_API_REQUEST_STATUS}
				>
					<option value={"1"}>{checksOption1Text}</option>
					<option value={"3"}>{checksOption3Text}</option>
				</Form.Control>
			</Col>
			
			<Col as={Form.Group} xs={12} className={"add-team-member-field submit-field text-center"}>
				<Button variant={"primary"} className={"btn-large"} disabled={REST_API_REQUEST_STATUS} onClick={() => addTeamMemberHandler()}>
					<span>{addTeamMemberText}</span>
				</Button>
			</Col>
			
		</Row>
	);
	
};


export default AddTeamMemberFormComponent;
