import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import siteLogo from "./_images/checkflat.png";


export const HeaderLogoComponent = ({siteTitle = ""}) => {
	
	return (
		<Col as="aside" xs={4} lg={3} className="application-header-logo text-center text-lg-left">
			
			<NavLink to={`/`} title={siteTitle}>
				<img src={siteLogo} alt={siteTitle} />
			</NavLink>
			
		</Col>
	);
	
};


export default HeaderLogoComponent;
