import React from "react";


import {Col, Form} from "react-bootstrap";


export const FormSwitchFieldComponent = ({
	switchMainLabelText = "", switchId = "",
	option1LabelText = "", option2LabelText = "",
	checkedStatus = false, changeHandler = null, fieldDisabledStatus = false,
	containerCssClasses = "", switchContainerCssClasses = "",
	mainLabelCssClasses = "", optionLabelCssClasses = "",
	xs = 12, sm = 12, lg = 12, xl = 12, as = "aside",
	...props
}) => {
	
	
	containerCssClasses = "form-field switch-field" + (containerCssClasses ? ` ${containerCssClasses}` : "");
	switchContainerCssClasses = "form-switch-container" + (switchContainerCssClasses ? ` ${switchContainerCssClasses}` : "");
	mainLabelCssClasses = "switch-text-label" + (mainLabelCssClasses ? ` ${mainLabelCssClasses}` : "");
	optionLabelCssClasses = "switch-label" + (optionLabelCssClasses ? ` ${optionLabelCssClasses}` : "");
	
	
	return (
		<Col
			as={as}
			xs={12} sm={sm} lg={lg} xl={xl}
			className={containerCssClasses}
			{...props}
		>
			
			{switchMainLabelText &&
			<span className={mainLabelCssClasses}>
				{switchMainLabelText}
			</span>
			}
			
			<aside className={switchContainerCssClasses}>
				
				<Form.Label className={optionLabelCssClasses}>
					<span>{option1LabelText}</span>
				</Form.Label>
				
				<Form.Check
					id={switchId}
					type="switch"
					checked={checkedStatus}
					onChange={fieldDisabledStatus ? null : e => changeHandler(e.target.checked)}
				/>
				
				<Form.Label className={optionLabelCssClasses}>
					<span>{option2LabelText}</span>
				</Form.Label>
				
			</aside>
			
		</Col>
	);
	
};


export default FormSwitchFieldComponent;
