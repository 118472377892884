import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Podaj kondygnację",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		
	},
	EN: {
		texts: {
			step_header: "Enter a storey",
			button_text: "Next",
			return_text: "Back"
		},
		
	}
};


export const CheckFormStep5SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatStorey
	} = formData;
	
	
	const [checkFlatFormDataFlatStorey, setCheckFlatFormDataFlatStorey] = useState(flatStorey || "0");
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const submitCheckFlatFormStep = (reset = false) => {
		formDataHandler({
			flatStorey: reset ? null : checkFlatFormDataFlatStorey
		}, reset ? 3 : 5);
	};
	
	
	return (
		<Row className={"check-form-step-component check-form-step-4-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Kondygnacja mieszkania" : "Apartment storey"}</Form.Label>
						<Form.Control
							as={"select"}
							value={checkFlatFormDataFlatStorey}
							onChange={e => setCheckFlatFormDataFlatStorey(e.target.value)}
						>
							<option value={"0"}>0 {activeLanguage === "PL" ? "Piętro" : "Floor"}</option>
							<option value={"1"}>1 {activeLanguage === "PL" ? "Piętro" : "Floor"}</option>
							<option value={"-1"}>-1 {activeLanguage === "PL" ? "Piętro" : "Floor"}</option>
							<option value={"Inne"}>{activeLanguage === "PL" ? "Inne" : "Other"}</option>
						</Form.Control>
					</Col>
				</Row>
			</Col>
			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} />
			
		</Row>
	);
	
};


export default CheckFormStep5SectionComponent;
