import React from "react";

import {useSelector} from "react-redux";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import MainHeaderComponent from "@Elements/Blocks/Sections/Headers/MainHeader"


import MainDataSectionAvatarComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/AvatarData";
import MainDataSectionPersonalComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/PersonalData";
import MainDataSectionPackageComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/PackageData";
import MainDataSectionCompanyComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/InvoiceData";



const TEXTS = {
	PL: {header_text: "Mój Checkflat"},
	EN: {header_text: "My Checkflat"}
};


export const ProfilePageMainDataSectionComponent = ({
	checkFlatUserData, checkFlatUserDataHandler = null
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	const {header_text: headerText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	// const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	if (!checkFlatUserData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		userID,
		userFirstName, userLastName, userEmailAddress,
		userBirthdayPresentStatus, userBirthdayDate,
		userCompanyInvoiceStatus, userCompanyName, userCompanyDni,
		userCompanyStreet, userCompanyHouseNo,
		userCompanyPostCode, userCompanyCity,
		proceededOrders: userOrders
	} = checkFlatUserData;
	
	
	return (
		<>
					
					<MainHeaderComponent
						headerText={headerText}
					/>
					
					<MainDataSectionAvatarComponent
						activeLanguage={ACTIVE_LANGUAGE}
					/>
					
					<MainDataSectionPersonalComponent
						userData={{userFirstName, userLastName, userEmailAddress, userBirthdayPresentStatus, userBirthdayDate}}
						userDataHandler={checkFlatUserDataHandler}
					/>
					
					<MainDataSectionPackageComponent
						userID={userID}
						userOrders={userOrders}
					/>
					
					<MainDataSectionCompanyComponent
						userData={{userCompanyInvoiceStatus, userCompanyName, userCompanyDni, userCompanyStreet, userCompanyHouseNo, userCompanyPostCode, userCompanyCity}}
						userDataHandler={checkFlatUserDataHandler}
					/>
		
		</>
	);
	
};


export default ProfilePageMainDataSectionComponent;
