import React from "react";

import {useSelector} from "react-redux";


const TEXTS = {
	PL: {add_team_member_text: "Dodaj członka zespołu", cancel_text: "Zrezygnuj"},
	EN: {add_team_member_text: "Add team member", cancel_text: "Cancel"}
};


export const AddTeamMemberSwitcherComponent = ({addMemberFormActiveStatus, addTeamMemberFormStatusHandler}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {add_team_member_text: addTeamMemberText, cancel_text: cancelText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const switcherText = addMemberFormActiveStatus ? cancelText : addTeamMemberText;
	
	
	return (
		<a
			href={"#add-team-member"}
			title={switcherText}
			onClick={e => addTeamMemberFormStatusHandler(e)}
			className={addMemberFormActiveStatus ? "active" : ""}
		>
			<span>{switcherText}</span>
		</a>
	);
	
};


export default AddTeamMemberSwitcherComponent;
