import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import validator from "validator/es";


import {NavLink} from "react-router-dom";


import {Container, Row, Col, Form, Button, Alert} from "react-bootstrap";


const URLS = {
	PL: {contact_page_url: "kontakt", regulations_page: "regulamin", privacy_policy_page: "polityka-prywatnosci", how_to_use_page: "jak-korzystac"},
	EN: {contact_page_url: "contact-us", regulations_page: "regulations", privacy_policy_page: "privacy-policy", how_to_use_page: "how-to-use"},
}


export const ApplicationFooterComponent = ({activeLanguage}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	const {
		contact_page_url: contactPageUrl, regulations_page: regulationsPageURL,
		privacy_policy_page: privacyPolicyPageURL, how_to_use_page: howToUsePageURL
	} = URLS[ACTIVE_LANGUAGE];
	
	
	const [newsletterFormEmail, setNewsletterFormEmail] = useState("");
	const [newsletterFormSubmitStatus, setNewsletterFormSubmitStatus] = useState(false);
	const [newsletterFormSubmitSuccess, setNewsletterFormSubmitSuccess] = useState(false);
	const [newsletterFormErrors, setNewsletterFormErrors] = useState({
		newsletterFormEmail: null
	});
	
	useEffect(() => {
		return () => {
			setNewsletterFormEmail("");
			setNewsletterFormSubmitStatus(false);
			setNewsletterFormSubmitSuccess(false);
			setNewsletterFormErrors({newsletterFormEmail: null})
		};
	}, []);
	
	
	const changeNewsletterFormEmail = (email) => {
		if (newsletterFormSubmitStatus && validator.isEmail(newsletterFormEmail)) {
			setNewsletterFormErrors({newsletterFormEmail: null});
		}
		setNewsletterFormEmail(email);
	};
	
	const submitNewsletterForm = () => {
		
		setNewsletterFormSubmitStatus(true);
		
		if (!validator.isEmail(newsletterFormEmail)) {
			setNewsletterFormErrors({
				newsletterFormEmail: {
					errorMessage: activeLanguage === "PL" ?
						"nieprawidłowy adres email" :
						"incorrect e-mail address"
				}
			});
			return null;
		}
		
		setNewsletterFormSubmitSuccess(true);
		
	};
	
	
	const buttonText = activeLanguage === "PL" ? "zapisz się" : "sign up";
	
	
	return (
		<Container as="footer" fluid={true} id="application-footer-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col as="aside" xs={12} lg={6} className="footer-newsletter-component">
						{!newsletterFormSubmitSuccess &&
						<Row as="aside" className="footer-newsletter-form">
							<h2 className="section-main-heading"><span>Newsletter</span></h2>
							<Form.Control
								type="email"
								placeholder="e-mail"
								value={newsletterFormEmail}
								onChange={e => changeNewsletterFormEmail(e.target.value)}
								className={!!newsletterFormErrors?.newsletterFormEmail ? "error" : ""}
							/>
							{!!newsletterFormErrors?.newsletterFormEmail &&
							<Form.Label
								className="error">{newsletterFormErrors.newsletterFormEmail.errorMessage}</Form.Label>
							}
							<Button onClick={() => submitNewsletterForm()}>{buttonText}</Button>
						</Row>
						}
						{newsletterFormSubmitSuccess &&
						<Row as={Alert} variant={"success"}>
							<span>Dziękujemy za rejestrację!</span>
						</Row>
						}
					</Col>
					
					<Col as="address" xs={6} lg={3} className="footer-address-component">
						<strong>AGGE</strong><br/>
						1600 Amphitheatre Parkway<br/>
						Mountain View, CA 94043<br/>
						<a href="mailto:support@agge.com" title="napisz do nas">support@agge.com</a><br/>
						<a href="tel:+48123456789" title="zadzwnoń do nas">+48 123 456 789</a><br/>
					</Col>
					
					<Col as="aside" xs={6} lg={3} className="footer-links-component">
						<Row as="ul" className="footer-links-list">
							<Col as="li" xs={12} className="footer-links-item">
								<NavLink to={`/${contactPageUrl}`} title="">{activeLanguage === "PL" ? "Kontakt" : "Contact"}</NavLink>
							</Col>
							<Col as="li" xs={12} className="footer-links-item">
								<NavLink to={`/${howToUsePageURL}`} title="">{activeLanguage === "PL" ? "Jak Korzystać" : "How to use"}</NavLink>
							</Col>
							<Col as="li" xs={12} className="footer-links-item">
								<NavLink to={`/${regulationsPageURL}`} title="">{activeLanguage === "PL" ? "Regulamin" : "Regulations"}</NavLink>
							</Col>
							<Col as="li" xs={12} className="footer-links-item">
								<NavLink to={`${privacyPolicyPageURL}`} title="">{activeLanguage === "PL" ? "Polityka Cookies" : "Cookies policy"}</NavLink>
							</Col>
						</Row>
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default ApplicationFooterComponent;
