import React, {useContext, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION, GLOBAL_POPUP_CONTENT_ACTION} from "@Actions";

import RestApiContext from "@Contexts/REST";


import {Row, Col, Form, Button} from "react-bootstrap";


import CancelledPackageMessageComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/PackageData/CancelledPackagePopup";


const TEXTS = {
	PL: {
		i_want_to_resign_text: "Chcę zrezygnować",
		cancel_message_text: "możesz zrezygnować w ciągu 14 dni od zakupu, ale zwrócimy Ci cenę pakietu pomniejszoną o zrealizowane sprawdzenia, liczone po obecnej cenie pojedynczego sprawdzenia poza pakietem. Sprawdź szczegóły w warunkach rezygnacji",
		tell_us_reason_message_text: "powiedz nam, czemu chcesz zrezygnować",
		i_don_need_text: "już nie potrzebuję",
		expectations_not_meet_text: "nie spełnia moich oczekiwań",
		too_expensive_text: "za drogie",
		tell_us_your_price_text: "podaj nam swoją cenę, a zobaczymy co da się zrobić",
		tell_your_price_text: "podaj swoją cenę",
		others_word_text: "inne",
		tell_us_how_to_improve_text: "powiedz nam, co i jak możemy ulepszyć",
		required_fields_label_text: "pola obowiązkowe",
		im_staying_text: "Jednak zostaję",
		resigns_text: "Zrezygnuj"
	},
	EN: {
		i_want_to_resign_text: "I want to resign",
		cancel_message_text: "you can cancel within 14 days of purchase, but we will refund the package price minus any checks performed, calculated at the current price of a single out-of-package check. Please see the cancellation policy for details",
		tell_us_reason_message_text: "tell us why you want to quit",
		i_don_need_text: "I don't need anymore",
		expectations_not_meet_text: "does not meet my expectations",
		too_expensive_text: "too expensive",
		tell_us_your_price_text: "give us your price and we'll see what we can do",
		tell_your_price_text: "give your price",
		others_word_text: "other",
		tell_us_how_to_improve_text: "tell us what and how we can improve",
		required_fields_label_text: "required fields",
		im_staying_text: "however, I stay",
		resigns_text: "Give up"
	}
};


export const CancelPackageComponent = ({
	packageData = null, userID
}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	
	const [resignReasonAnswer, setResignReasonAnswer] = useState(0);
	const [resignReasonSubAnswer, setResignReasonSubAnswer] = useState(0);
	const [resignReasonAnswerMessage, setResignReasonAnswerMessage] = useState("");
	
	const changeResignReasonAnswer = (answer) => {
		setResignReasonAnswer(answer);
		setResignReasonAnswerMessage("");
	};
	
	const changeResignReasonSubAnswer = (answer) => {
		setResignReasonSubAnswer(answer);
		setResignReasonAnswerMessage("");
	};
	
	const changeResignReasonAnswerMessage = (text) => {
		setResignReasonAnswerMessage(text);
	};
	
	
	if (!packageData) return null;
	
	
	const {
		i_want_to_resign_text: iWantToResignText,
		cancel_message_text: cancelMessageText,
		tell_us_reason_message_text: tellUsReasonMessageText,
		i_don_need_text: iDonNeedText, expectations_not_meet_text: expectationsNotMeetText,
		too_expensive_text: tooExpensiveText, tell_us_your_price_text: tellUsYourPriceText,
		tell_your_price_text: tellYourPriceText, others_word_text: othersWordText,
		tell_us_how_to_improve_text: tellUsHowToImproveText, // required_fields_label_text: requiredFieldsLabelText,
		im_staying_text: imStayingText, resigns_text: resignsText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const {
		id: orderID
	} = packageData;
	
	
	const packageResignRequestHandler = async () => {
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const ORDER_API_URL = `${REST_API_URL}/estates/orders/list/${orderID}/`;
		
		const formData = new FormData();
		formData.append("estate_reports_order_status", "cancelled");
		
		try {
			const userTeamResponse = await fetch(`${REST_API_URL}/app/users/teams/?users_team_super_user=${userID}&all_results=true`);
			const userTeamResponseContent = await userTeamResponse.json();
			if (userTeamResponseContent.length) {
				for (let userTeam of userTeamResponseContent) {
					await fetch(userTeam.url, {method: "DELETE"});
				}
			}
		} catch (e) {
			console.log(e);
		}
		
		try {
			await fetch(ORDER_API_URL, {method: "PATCH", body: formData});
			DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(<CancelledPackageMessageComponent />));
		} catch (e) {
			console.log(e);
		}
		
		const userUpdateFormData = new FormData();
		userUpdateFormData.append("user_active_package_order", "");
		console.log(`${REST_API_URL}/app/users/list/${userID}/`)
		try {
			await fetch(`${REST_API_URL}/app/users/list/${userID}/`, {method: "PATCH", body: userUpdateFormData});
		} catch (e) {
			console.log("user", 500, e);
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};

	
	return (
		<>
			
			<Col as={"h2"} xs={12} className={"global-popup-header"}>
				<span>{iWantToResignText}</span>
			</Col>
			
			<Col xs={12} className={"cancel-package-data"}>
			
			</Col>
			
			<Col xs={12} className={"cancel-package-message"}>
				<p>{cancelMessageText}</p>
			</Col>
			
			<Col xs={12} className={"cancel-package-reason"}>
				
				<Col xs={12} className={"cancel-reason-header"}>
					<span>{tellUsReasonMessageText}</span>
				</Col>
				
				<Col xs={12} className={"cancel-reason-answers"}>
					<Row className={"cancel-reason-answer"}>
						<Col xs={12} className={"cancel-reason-option"}>
							<span className={"radio-option" + (resignReasonAnswer === 1 ? " checked" : "")} onClick={() => changeResignReasonAnswer(resignReasonAnswer === 1 ? null : 1)}></span>
							<span className={"radio-label"}>{iDonNeedText}</span>
						</Col>
					</Row>
					<Row className={"cancel-reason-answer"}>
						<Col xs={12} className={"cancel-reason-option"}>
							<span className={"radio-option" + (resignReasonAnswer === 2 ? " checked" : "")} onClick={() => changeResignReasonAnswer(resignReasonAnswer === 2 ? null : 2)}></span>
							<span className={"radio-label"}>{expectationsNotMeetText}</span>
						</Col>
						{resignReasonAnswer === 2 &&
						<Col xs={12} className={"cancel-reason-option"}>
							<Row className={"cancel-reason-sub-answer"}>
								<Col xs={12} className={"cancel-reason-option"}>
									<span className={"radio-option" + (resignReasonSubAnswer === 1 ? " checked" : "")} onClick={() => changeResignReasonSubAnswer(resignReasonSubAnswer === 1 ? null : 1)}></span>
									<span className={"radio-label"}>{tooExpensiveText}</span>
								</Col>
								{resignReasonSubAnswer === 1 &&
								<Col xs={12} className={"cancel-reason-option reason-text-message"}>
									<Form.Label>{tellUsYourPriceText}</Form.Label>
									<Form.Control
										placeholder={tellYourPriceText}
										value={resignReasonAnswerMessage}
										onChange={e => changeResignReasonAnswerMessage(e.target.value)}
									/>
								</Col>
								}
							</Row>
							<Row className={"cancel-reason-sub-answer"}>
								<Col xs={12} className={"cancel-reason-option"}>
									<span className={"radio-option" + (resignReasonSubAnswer === 2 ? " checked" : "")} onClick={() => changeResignReasonSubAnswer(resignReasonSubAnswer === 2 ? null : 2)}></span>
									<span className={"radio-label"}>{othersWordText}</span>
								</Col>
								{resignReasonSubAnswer === 2 &&
								<Col xs={12} className={"cancel-reason-option reason-text-message"}>
									<Form.Control
										placeholder={tellUsHowToImproveText}
										as={"textarea"}
										value={resignReasonAnswerMessage}
										onChange={e => changeResignReasonAnswerMessage(e.target.value)}
									/>
								</Col>
								}
							</Row>
						</Col>
						}
					</Row>
				</Col>
				
			</Col>
			
			{/*<Col xs={12} className={"cancel-package-legend"}>*/}
			{/*	<span className={"text-label"}>{requiredFieldsLabelText}</span>*/}
			{/*	<span className={"asterisk"}>*</span>*/}
			{/*</Col>*/}
			
			<Col xs={12} className={"cancel-package-submit"}>
				<a href={"#resign-cancel"} title={imStayingText} onClick={e => {e.preventDefault(); DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(null))}}>
					<span>{imStayingText}</span>
				</a>
				<Button variant={"primary"} className={"btn-large"} onClick={packageResignRequestHandler}>
					<span>{resignsText}</span>
				</Button>
			</Col>
			
		</>
	);
	
};


export default CancelPackageComponent;
