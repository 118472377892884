import React, {useContext, useState, useEffect, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Container, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import MainHeaderComponent from "@Elements/Blocks/Sections/Headers/MainHeader";


import ArticlesPageItemComponent from "@UIPages/Common/ArticlesPage/Article";
import ArticlesListPaginationComponent from "@UIPages/Common/ArticlesPage/Pagination";


import {PARAGRAPHS, TITLE} from "@UIPages/Common/ArticlesPage/_SAMPLE";
import scrollToDomElement from "@Utilities/Scrolling";


const TEXTS = {
	PL: {header_text: "Nasze Rady"},
	EN: {header_text: "Our Advices"},
};


export const ArticlesPageComponent = () => {
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	const {header_text: headerText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const [articlesList, setArticlesList] = useState(null);
	const [paginationPage, setPaginationPage] = useState(1);
	
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 150);
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		fetch(REST_API_URL).then(response => response).then(content => content).catch(error => error);
		const ARTICLES = [];
		for (let i = 0; i < 20; i++) {
			ARTICLES.push({
				article_id: i + 1,
				article_title: TITLE, article_short_content: PARAGRAPHS[0],
				article_slug: TITLE.toLowerCase().replaceAll(" ", "-"),
				article_information: {author: "John Doe", date: (new Date()).toLocaleDateString()}
			});
		}
		setArticlesList(ARTICLES.splice((paginationPage - 1) * 5, 5));
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	}, [ACTIVE_LANGUAGE, paginationPage]);
	
	
	useEffect(() => {
		return () => {
			setArticlesList(null);
			setPaginationPage(1);
		};
	}, []);
	
	
	
	if (!articlesList) {
		return <MainContentLoaderComponent />;
	}
	
	
	const PAGINATION_PAGES_NUMBER = 20 / 5;
	
	
	const ArticlesList = articlesList.map((article, i) =>
		<ArticlesPageItemComponent key={i} articleData={article} />
	);
	
	
	return (
		<Container ref={CONTAINER_REF} as="main" fluid={true} id="articles-list-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content articles-list-component">
				
					<MainHeaderComponent headerText={headerText} />
				
					{ArticlesList}
					
					<ArticlesListPaginationComponent
						activePaginationPage={paginationPage}
						activePaginationPageHandler={setPaginationPage}
						paginationPagesNumber={PAGINATION_PAGES_NUMBER}
					/>
					
				</Row>
			</Container>
		</Container>
	);
	
	
};


export default ArticlesPageComponent;
