import React, {useEffect, useState, useContext} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";

import RestApiContext from "@Contexts/REST";
import GlobalDataContext from "@Contexts/Global";


import {Redirect} from "react-router-dom";


import {Alert, Container, Row, Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import CartPageCartComponent from "@UIPages/Common/CartPage/Views/Cart";
import CartPageBackComponent from "@UIPages/Common/CartPage/_Parts/Back";
import CartPageAuthenticateComponent from "@UIPages/Common/CartPage/Views/Authenticate";
import CartPagePaymentComponent from "@UIPages/Common/CartPage/Views/Payment";
import CartPageSummaryComponent from "@UIPages/Common/CartPage/Views/Summary";





const URLS = {
	cart: {PL: "koszyk", EN: "cart"},
	pricing: {PL: "cennik", EN: "pricing"},
	contact: {PL: "kontakt", EN: "contact-us"}
};

const TEXTS = {
	PL: {user_package_active_message: "Posiadasz aktywny pakiet. Aby zmienić jego parametry skontaktuj się z nami"},
	EN: {user_package_active_message: "You have active package. To change it contact us"},
};


const EXTRA_PRODUCTS = {
	application_tutorial_webinar: {
		product_description: {
			PL: "Webinar jak efektywnie korzystać z dostępu do apki",
			EN: "Webinar on how to effectively use access to the app"
		},
		product_price: {price_value: 0, price_label: {PL: "Gratis!", EN: "Free!"}}
	},
	estates_market_webinars: {
		product_description: {
			PL: "Dostęp do webinarów pokazujących sytuację na rynku nieruchomości - jedynie 16,99 zł / miesiąc",
			EN: "Access to webinars showing the situation on the real estate market - only PLN 16.99 / month"
		},
		product_price: {price_value: 16.99, price_payment_type: "2", price_label: {PL: "Gratis!", EN: "Free!"}}
	},
	add_radar_discount: {
		product_description: {
			PL: "Odbierz -25% na Adradar",
			EN: "Receive -25% on Adradar"
		},
		product_price: {price_value: 0, price_label: {PL: "Gratis!", EN: "Free!"}},
		product_extra_label: {
			PL: "Rabat podeślemy mailem",
			EN: "We will send the discount by e-mail"
		}
	}
};


export const ApplicationCartPageComponent = ({activeLanguage, location}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode || "PL";
	
	const {
		user_package_active_message: userPackageActiveMessage
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	const LOGGED_USER = useSelector(state => state?.authentication)?.loggedUser || null;
	
	const DISPATCHER = useDispatch();
	

	const [CHECK_FLAT_USER, SET_CHECK_FLAT_USER] = useState(null);

	
	useEffect(() => {
		
		if (!LOGGED_USER || !LOGGED_USER?.id) return null;
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		fetch(`${REST_API_URL}/app/users/list/${LOGGED_USER?.id}/`)
			.then(response => response.ok ? response.json() : {error: true, code: response.status})
			.then(response => {
				SET_CHECK_FLAT_USER(response);
				DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
			})
			.catch(error => console.log(error))
		;
		
		return () => {
			SET_CHECK_FLAT_USER(null);
		};
		
	}, [LOGGED_USER]);
	
	
	const packagesProducts = useContext(GlobalDataContext)?.checksPackages;
	
	const storedCartContent = JSON.parse(localStorage.getItem("checkFlatShoppingCartContent"));

	
	const {state: redirectState} = location;
	const addedPackageProduct = {addedPackage: redirectState?.packageData};
	
	
	const [cartStep, setCartStep] = useState(null);
	const [paymentType, setPaymentType] = useState(null);
	const [chosenPackage, setChosenPackage] = useState(null);
	const [extraProducts, setExtraProducts] = useState(null);
	
	const [discountCode, setDiscountCode] = useState(null);
	const [transactionStatus, setTransactionStatus] = useState(null);
	
	const [productActionRedirect, setProductActionRedirect] = useState(null);
	const [productAddedRedirect, setProductAddedRedirect] = useState(null);
	
	useEffect(() => {

		if (!storedCartContent && !addedPackageProduct) {
			setCartStep(-1);
			return false;
		}
		
		setCartStep(storedCartContent?.cartStep || 1);
		
		const paymentType = addedPackageProduct.addedPackage?.paymentType || storedCartContent?.paymentType;
		const chosenPackage = addedPackageProduct.addedPackage?.chosenPackage || storedCartContent?.chosenPackage;
		
		if (addedPackageProduct) {
			setProductAddedRedirect(true);
		}
		
		const extraProducts = storedCartContent?.extraProducts || {
			appWebinar: false,
			estWebinars: false,
			addRadar: false
		};
		
		const discountCode = storedCartContent?.discountCode || null;
		
		if (!paymentType || !chosenPackage) {
			setCartStep(-1);
			return false;
		}
		
		setPaymentType(paymentType);
		setChosenPackage(chosenPackage);
		setExtraProducts(extraProducts);
		setDiscountCode(discountCode);
		
		if (!storedCartContent) {
			saveCartData(
				{cartStep, paymentType, chosenPackage, extraProducts, discountCode}
			);
		}
		// changeCartStep(3)
		return () => {
			setCartStep(1);
			setPaymentType(null);
			setChosenPackage(null);
			setExtraProducts(null);
			setTransactionStatus(null);
			setDiscountCode(null);
			setProductActionRedirect(null);
			setProductAddedRedirect(null);
		};
		
	}, []);
	
	
	const saveCartData = (options = {}) => {
		localStorage.setItem("checkFlatShoppingCartContent", JSON.stringify({
			cartStep, paymentType, chosenPackage, extraProducts, discountCode,
			...options
		}));
	};
	
	
	const changePaymentType = (type) => {
		setPaymentType(type);
		saveCartData({paymentType: type});
	};
	
	
	if (cartStep === -1 || productActionRedirect) {
		if (productActionRedirect?.cleanCart) {
			localStorage.removeItem("checkFlatShoppingCartContent");
		}
		return <Redirect to={{pathname: URLS.pricing[activeLanguage], state: {chosenPackage}}} />;
	}
	
	if (productAddedRedirect) {
		setProductAddedRedirect(null);
		return <Redirect to={URLS.cart[activeLanguage]} />
	}
	
	if (!chosenPackage || !paymentType || !cartStep || !extraProducts) {
		return <MainContentLoaderComponent />;
	}
	
	
	const changeCartStep = (step) => {
		setCartStep(step);
		saveCartData({cartStep: step})
	};
	
	
	const editCartPackageProduct = () => {
		setProductActionRedirect(true);
	};
	
	
	const removeCartPackageProduct = () => {
		setProductActionRedirect({cleanCart: true});
	};
	
	
	const chosenPackageData = packagesProducts[chosenPackage.packageID];
	
	
	const changeChosenPackageChecksNumber = (checksNumberOption) => {
		const updatedPackageData = {...chosenPackage, packageChecks: checksNumberOption};
		setChosenPackage(updatedPackageData);
		saveCartData({chosenPackage: updatedPackageData});
	};
	
	
	const changeExtraProducts = (product) => {
		const updatedExtraProduct = {...extraProducts, [product]: !extraProducts[product]};
		setExtraProducts(updatedExtraProduct);
		saveCartData({extraProducts: updatedExtraProduct});
	};
	
	
	const changeDiscountCode = (code) => {
		setDiscountCode(code);
		saveCartData({discountCode: code});
	};
	
	
	const {package_checks_number: chosenPackageChecks} = chosenPackageData
	
	const {
		checks_number_value: chosenPackageChecksNumber, checks_number_prices: chosenPackageChecksPrice
	} = chosenPackageChecks[chosenPackage.packageChecks];
	
	const selectedExtraProducts = Object.entries(extraProducts).filter((product) => product[1]).map(product => product[0]);
	
	
	let cartTotalPrice = chosenPackageChecksNumber * chosenPackageChecksPrice[paymentType];
	
	if (selectedExtraProducts.indexOf("appWebinar") !== -1) {
		cartTotalPrice += EXTRA_PRODUCTS["application_tutorial_webinar"]["product_price"]["price_value"];
	}
	if (selectedExtraProducts.indexOf("estWebinars") !== -1) {
		cartTotalPrice += EXTRA_PRODUCTS["estates_market_webinars"]["product_price"]["price_value"];
	}
	if (selectedExtraProducts.indexOf("addRadar") !== -1) {
		cartTotalPrice += EXTRA_PRODUCTS["add_radar_discount"]["product_price"]["price_value"];
	}
	
	const cartTotalPrices = {
		cartTotalOldPrice: discountCode ? cartTotalPrice : null,
		cartTotalPrice:
			discountCode ?
				cartTotalPrice * ((100 - discountCode.discountCodeValue) / 100)
				:
				cartTotalPrice
	};
	
	
	const checkoutProcessData = {
		paymentType, paymentTypeHandler: changePaymentType,
		chosenPackage, chosenPackageData, extraProducts,
		cartTotalPrices, discountCode, discountCodeHandler: changeDiscountCode
	};
	
	
	if (CHECK_FLAT_USER && cartStep !== 3) {
		const {user_orders: userOrders} = CHECK_FLAT_USER;
		if (userOrders.filter(order => order["estate_reports_order_status"] !== "cancelled").length > 0) {
			return (
				<Container fluid={true} className={"system-communicate-component error-communicate-component"}>
					<Container className={"system-communicate-container error-communicate-container"}>
						<Row className={"system-communicate-content error-communicate-content"}>
							<Col xs={12} as={Alert} variant={"danger"} className={"system-communicate error-communicate"}>
								<span>{userPackageActiveMessage}</span>
							</Col>
						</Row>
					</Container>
				</Container>
			);
		}
	}
	
	
	if (REST_API_REQUEST_STATUS && LOGGED_USER) {
		return <MainContentLoaderComponent />;
	}
	
	
	return (
		<>
		
			{cartStep === 1 &&
			<CartPageCartComponent
				activeLanguage={activeLanguage}
				paymentType={paymentType}
				chosenPackage={chosenPackage}
				chosenPackageData={chosenPackageData}
				changePackageChecksNumberHandler={changeChosenPackageChecksNumber}
				extraProducts={extraProducts}
				extraProductsHandler={changeExtraProducts}
				cartTotalPrices={cartTotalPrices}
				discountCode={discountCode}
				discountCodeHandler={changeDiscountCode}
				editProductHandler={editCartPackageProduct}
				removeProductHandler={removeCartPackageProduct}
				cartStepHandler={changeCartStep}
			/>
			}
			
			
			{cartStep === 2 && !LOGGED_USER &&
			<CartPageAuthenticateComponent
				activeLanguage={activeLanguage}
				loggedUser={LOGGED_USER}
			/>
			}
			
			
			{cartStep === 2 && LOGGED_USER &&
			<CartPagePaymentComponent
				activeLanguage={activeLanguage}
				loggedUser={LOGGED_USER}
				checkoutProcessData={checkoutProcessData}
				cartStepHandler={changeCartStep}
			/>
			}
			
			
			{cartStep === 3 && LOGGED_USER &&
			<CartPageSummaryComponent
				activeLanguage={activeLanguage}
				loggedUser={LOGGED_USER}
				checkoutProcessData={checkoutProcessData}
				cartStepHandler={changeCartStep}
			/>
			}
			
			
			{cartStep !== 1 && cartStep !== 3 &&
			<CartPageBackComponent
				previousStep={cartStep - 1}
				cartStepHandler={changeCartStep}
			/>
			}
			
		</>
	);
	
};


export default ApplicationCartPageComponent;
