import React from "react";


import {NavLink} from "react-router-dom";


import {Container, Row, Col} from "react-bootstrap"

import {IoArrowForwardCircleOutline} from "react-icons/all";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import imagePlaceholder from "@Assets/Images/no-image.png";


export const HomePageOurTipsSectionComponent = ({sectionData, activeLanguage}) => {
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent/>;
	}
	
	
	const {
		section_title: sectionTitle,
		button_text: buttonText
	} = sectionData;
	
	
	const postTitle = activeLanguage === "PL" ?
		"Tytuł ciekawego artykułu o rynku nieruchomości"
		:
		"The title of an interesting article about the real estate market"
	;
	
	
	return (
		<Container as="section" fluid={true} id="home-page-tips-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} as="h2" className="section-main-heading text-lg-center">
						<span>{sectionTitle}</span>
					</Col>
					
					<Col xs={12} className="pro-tips-articles">
						<Row as="ul" className="pro-tips-articles-list">
							
							<Col xs={12} lg={6} className="pro-tips-article">
								<Row className="pro-tips-article-header">
									<Col xs={12} className="pro-tips-article-title">
										<NavLink to={"/"} title={""} onClick={e => e.preventDefault()}>
											<span>{postTitle}</span>
										</NavLink>
									</Col>
								</Row>
								<Row className="pro-tips-article-image">
									<Col xs={12} className="pro-tips-article-picture">
										<NavLink to={"/"} title={""} onClick={e => e.preventDefault()}>
											<img src={imagePlaceholder} alt={""} />
										</NavLink>
									</Col>
								</Row>
								<Row className="pro-tips-article-content">
									<Col xs={12} className="pro-tips-article-text">
										<p>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."}</p>
									</Col>
								</Row>
							</Col>
							
							<Col xs={12} lg={6} className="pro-tips-article">
								<Row className="pro-tips-article-header">
									<Col xs={12} className="pro-tips-article-title">
										<NavLink to={"/"} title={""} onClick={e => e.preventDefault()}>
											<span>{postTitle}</span>
										</NavLink>
									</Col>
								</Row>
								<Row className="pro-tips-article-image">
									<Col xs={12} className="pro-tips-article-picture">
										<NavLink to={"/"} title={""} onClick={e => e.preventDefault()}>
											<img src={imagePlaceholder} alt={""} />
										</NavLink>
									</Col>
								</Row>
								<Row className="pro-tips-article-content">
									<Col xs={12} className="pro-tips-article-text">
										<p>{"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna."}</p>
									</Col>
								</Row>
							</Col>
							
						</Row>
					</Col>
					
					<Col xs={12} as="aside" className="block-section-footer text-center">
						<NavLink to={"/nasze-rady"} title={sectionTitle} className={"button main-button"} onClick={e => e.preventDefault()}>
							<span>{buttonText}</span>
							<IoArrowForwardCircleOutline />
						</NavLink>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
}


export default HomePageOurTipsSectionComponent;
