import React, {useContext} from "react";

import {useSelector} from "react-redux";

import TranslationsContext from "@Contexts";


import {Container, Row, Col} from "react-bootstrap";


import {BsArrowLeft} from "react-icons/all";


export const CartPageBackComponent = ({buttonText = "", previousStep = 1, cartStepHandler}) => {
	
	
	const activeLanguage = useSelector(state => state.localization).activeLanguage.languageCode;
	const WORDS = useContext(TranslationsContext).SINGLE_WORDS;
	
	buttonText = buttonText || (WORDS?.back[activeLanguage] || "back");
	
	
	const backButtonClickHandler = (e) => {
		e.preventDefault();
		cartStepHandler(previousStep);
	};
	
	
	return (
		<Container as="section" fluid={true} id="cart-page-back-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} className="cart-page-back">
						<a href={"#back"} title={buttonText} onClick={e => backButtonClickHandler(e)} className={"cart-page-back-button"}>
							<BsArrowLeft />
							<span>{buttonText}</span>
						</a>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default CartPageBackComponent;
