import React, {useContext} from "react";

import {useSelector} from "react-redux";

import TranslationsContext from "@Contexts";

import {Button, Col} from "react-bootstrap";


export const CheckFormNextButtonComponent = ({
	buttonText = "",
	cssClasses = "",
	clickHandler = null,
	...props
}) => {
	
	
	const activeLanguage = useSelector(state => state.localization).activeLanguage.languageCode;
	const WORDS = useContext(TranslationsContext).SINGLE_WORDS;
	
	buttonText = buttonText || (WORDS?.next[activeLanguage] || "next");
	
	
	return (
		<Col {...props} className={"user-form-footer-submit"}>
			<Button onClick={clickHandler} className={"btn-large btn-special" + (cssClasses ? ` ${cssClasses}` : "")}>
				<span>{buttonText}</span>
			</Button>
		</Col>
	);
	
};


export default CheckFormNextButtonComponent;
