import React from "react";

import {useSelector} from "react-redux";


import {Col} from "react-bootstrap";


const TEXTS = {
	PL: {team_members_header_text: "Członkowie zespołu"},
	EN: {team_members_header_text: "Team members"}
};


export const TeamMembersHeaderComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {team_members_header_text: teamMembersHeaderText} = TEXTS[ACTIVE_LANGUAGE];

	
	return (
		<Col as={"h4"} xs={12} className={"team-members-header"}>
			<span>{teamMembersHeaderText}:</span>
		</Col>
	);
	
};


export default TeamMembersHeaderComponent;
