import React from "react";

import {useSelector} from "react-redux";

import PropTypes from 'prop-types';


import {BLOCK_HTML_TAGS} from "@Utilities/Data/HTML";


import {Col, Button} from "react-bootstrap";


export const FormSubmitFieldComponent = ({
	buttonText, submitHandler = null, fieldDisabledStatus = false,
	containerCssClasses = "",
	buttonVariant = "primary", buttonCssClasses = "",
	xs = 12, sm = null, lg = 12, xl = null, as = "aside",
	...props
}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	
	
	if (!buttonText) return null;
	
	
	containerCssClasses = "form-field submit-field text-center" + (containerCssClasses ? ` ${containerCssClasses}` : "");
	
	buttonCssClasses = "btn-large" + (buttonCssClasses ? ` ${buttonCssClasses}` : "");
	
	
	return (
		<Col
			as={as}
			xs={xs} sm={sm || xs} lg={lg} xl={xl || lg}
			className={containerCssClasses}
			{...props}
		>
			
			<Button
				variant={buttonVariant}
				className={buttonCssClasses}
				onClick={submitHandler}
				disabled={fieldDisabledStatus || REST_API_REQUEST_STATUS}
			>
				<span>{buttonText}</span>
			</Button>
			
		</Col>
	);
	
};


FormSubmitFieldComponent.propTypes = {
	buttonText: PropTypes.string.isRequired,
	submitHandler: PropTypes.func,
	as: PropTypes.oneOf(BLOCK_HTML_TAGS),
	xs: PropTypes.number,
	sm: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	containerCssClasses: PropTypes.string,
	buttonVariant: PropTypes.string,
	buttonCssClasses: PropTypes.string,
};


export default FormSubmitFieldComponent;
