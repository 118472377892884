export const LOG_USER_IN = "LOG_USER_IN";
export const LOG_USER_OUT = "LOG_USER_OUT";


export const LOG_USER_IN_ACTION = (userData) => {
	return {
		type: LOG_USER_IN,
		userData: userData
	}
};


export const LOG_USER_OUT_ACTION = () => {
	return {
		type: LOG_USER_OUT
	};
};
