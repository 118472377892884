import React from "react";

import {useSelector} from "react-redux";

import {Col, Form} from "react-bootstrap";


const TEXTS = {
	PL: {default_option_text: "Wybierz temat z listy"},
	EN: {default_option_text: "Select subject from list"},
}


export const FormSelectFieldComponent = ({
	fieldOptions = null, selectedFieldValue = null, fieldValueHandler = null,
	fieldDefaultOption = "0", fieldDefaultOptionLabel = null, fieldLabel = "",
	fieldMandatoryStatus = true, fieldError = null, fieldDisabledStatus = false,
	fieldControlID = null,
	xs = 12, sm = null, lg = 12, xl = null
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const {default_option_text: defaultOptionText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	
	
	if (!fieldOptions) return null;
	
	
	const FieldOptionsList = fieldOptions.map((option, i) =>
		<option key={i} value={option.optionValue}>{option.optionText}</option>
	);
	
	
	return (
		<Form.Group
			as={Col} xs={xs} sm={sm} lg={lg} xl={xl}
			className={`form-field select-field`}
			controlId={fieldControlID}
		>
			
			{fieldLabel &&
			<Form.Label>
				<span>{fieldLabel}</span>
				{fieldMandatoryStatus && <span className={"asterisk"}>*</span>}
			</Form.Label>
			}
			
			<Form.Control
				as={"select"}
				value={selectedFieldValue || fieldDefaultOption}
				onChange={e => fieldValueHandler(e.target.value)}
				disabled={fieldDisabledStatus || REST_API_REQUEST_STATUS}
				className={fieldError !== null ? "error" : ""}
			>
				
				{fieldDefaultOption && <option value={fieldDefaultOption}>{fieldDefaultOptionLabel || defaultOptionText}</option>}
				
				{FieldOptionsList}
				
			</Form.Control>
			
			{fieldError &&
			<Form.Label className={"error"}>{fieldError}</Form.Label>
			}
			
		</Form.Group>
	);
	
};


export default FormSelectFieldComponent;
