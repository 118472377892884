import React, {useState} from "react";

import {useSelector} from "react-redux";


import {Col, Form} from "react-bootstrap";

import {FaEdit, FaCheck, FaTimes} from "react-icons/all";


import camelCaseToSnakeCase from "@Utilities/Tools/Strings/CamelCaseToSnakeCase";


export const ProfileTextFieldComponent = ({
	fieldName, fieldValue = "", fieldValueHandler = null,
	fieldLabel = "", fieldType = "text",
	fieldValidator = null, fieldErrorMessage = null,
	xs = 12, sm = 12, lg = 12, xl = 12
}) => {
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	
	
	const [editableFieldValue, setEditableFieldValue] = useState(fieldValue);
	const [fieldEditStatus, setFieldEditStatus] = useState(false);
	
	const [fieldError, setFieldError] = useState(
		{status: false, message: fieldErrorMessage || "Incorrect Value"}
	);
	
	fieldName = camelCaseToSnakeCase(fieldName);
	
	const saveFieldValue = () => {
		if (fieldValidator && !fieldValidator()) {
		
		}
		if (fieldValueHandler) {
			fieldValueHandler(fieldName, editableFieldValue, saveFieldValueCallback);
		}
	};
	
	const saveFieldValueCallback = (callbackMessage) => {
		setFieldEditStatus(false);
	};
	
	
	return (
		<Col
			xs={xs} sm={sm} lg={lg} xl={xl}
			className={"user-data-field profile-section-field text-field"}
		>
			
			{fieldLabel &&
			<span className={"data-field-label" + (fieldEditStatus ? " active" : "")}>{fieldLabel}:</span>
			}
			
			{!fieldEditStatus &&
			<span className={"data-field-value"}>{editableFieldValue || "-"}</span>
			}
			
			{fieldEditStatus &&
			<Form.Control
				type={fieldType}
				name={fieldName}
				value={editableFieldValue || ""}
				onChange={e => setEditableFieldValue(e.target.value)}
				disabled={REST_API_REQUEST_STATUS}
			/>
			}
			
			{!fieldEditStatus &&
			<span className={"data-field-edit"} onClick={REST_API_REQUEST_STATUS ? null : () => setFieldEditStatus(true)}>
				<FaEdit className={"field-icon"}/>
			</span>
			}
			
			{fieldEditStatus &&
			<span className={"data-field-accept"} onClick={REST_API_REQUEST_STATUS ? null : () => saveFieldValue()}>
				<FaCheck className={"field-icon"}/>
			</span>
			}
			
			{fieldEditStatus &&
			<span className={"data-field-abort"} onClick={REST_API_REQUEST_STATUS ? null : () => setFieldEditStatus(false)}>
				<FaTimes className={"field-icon"}/>
			</span>
			}
			
			{fieldError.status &&
			<Form.Label className={"error"}>{fieldError.message}</Form.Label>
			}
			
		</Col>
	);
	
};


export default ProfileTextFieldComponent;
