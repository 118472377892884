import {
	CHANGE_REST_API_REQUEST_STATUS, GLOBAL_POPUP_CONTENT, STATE_UPDATE_HANDLER
} from "@Store/Actions";


const initialState = {
	restApiRequestStatus: false,
	globalPopupContent: null,
	stateUpdateHandler: true
};


export const InterfaceReducer = (state = initialState, action) => {

	switch (action.type) {
		
		case CHANGE_REST_API_REQUEST_STATUS:
			return {...state, restApiRequestStatus: action.status || false};
		
		case GLOBAL_POPUP_CONTENT:
			return {...state, globalPopupContent: action.popupContent || null};
		
		case STATE_UPDATE_HANDLER:
			return {...state, stateUpdateHandler: action.status || false};
			
		default:
			return state;
			
	}
	
};


export default InterfaceReducer;
