import React from "react";


import {NavLink} from "react-router-dom";


import {Container, Row, Col} from "react-bootstrap"

import {IoArrowForwardCircleOutline} from "react-icons/all";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import imagePlaceholder from "@Assets/Images/no-image.png";


export const HomePageCtaSectionComponent = ({sectionData}) => {
	
	
	if (!sectionData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const  {
		section_title: sectionTitle,
		section_sub_title: sectionSubTitle,
		section_text: sectionText,
		section_label: sectionLabel
	} = sectionData;
	
	
	return (
		<Container as="section" fluid={true} id="home-page-cta-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col xs={12} lg={4} className="block-section-texts">
						<Row className="block-section-texts-content">
							
							<Col xs={12} as="h3" className="block-section-heading">
								<span>{sectionTitle}</span>
							</Col>
							
							<Col xs={12} as="h4" className="block-section-sub-heading">
								<span>{sectionSubTitle}</span>
							</Col>
							
							<Col xs={12} as="article" className="block-section-text">
								<p>
									{sectionText}
								</p>
							</Col>
							
							<Col xs={12} as="aside" className="block-section-footer">
								<h5 className="block-section-label">
									<span>{sectionLabel}</span>
								</h5>
								<NavLink to={"/checkflat"} title={"Checkflat"} className={"button main-button"} onClick={e => e.preventDefault()}>
									<span>{"Checkflat"}</span>
									<IoArrowForwardCircleOutline />
								</NavLink>
							</Col>
							
						</Row>
					</Col>
					
					<Col xs={12} lg={8} className="block-section-image">
						<Row className="block-section-image-content">
							<Col xs={12} as="picture" className="block-section-image-picture">
								<img src={imagePlaceholder} alt={sectionTitle} />
							</Col>
						</Row>
					</Col>
					
				</Row>
			</Container>
		</Container>
	)
	
};


export default HomePageCtaSectionComponent;
