import React, {useEffect, useState} from "react";


import {Switch, Route, Redirect} from "react-router-dom";


import ApplicationHomePageComponent from "@UIPages/Common/HomePage";
import ContactPageComponent from "@UIPages/Common/ContactPage";
import ArticlesPageComponent from "@UIPages/Common/ArticlesPage";
import ArticlePageComponent from "@UIPages/Common/ArticlePage";

import ApplicationPricingPageComponent from "@UIPages/Common/PricingPage";
import ApplicationCartPageComponent from "@UIPages/Common/CartPage";

import ApplicationCheckFlatPageComponent from "@UIPages/User/Authenticated/Checkflat";
import ApplicationUserProfilePageComponent from "@UIPages/User/Authenticated/Profile";

import ApplicationLoginPageComponent from "@UIPages/User/NotAuthenticated/Login";
import ApplicationRegisterPageComponent from "@UIPages/User/NotAuthenticated/Register";

import Error404PageComponent from "@UIPages/Errors/404Page";


const ROUTES = {
	"home-page": {url: {"PL": "", "EN": ""}, title: {"PL": "Strona Główna", "EN": "Home Page"}, component: ApplicationHomePageComponent, exact: true},
	"pricing-page": {url: {"PL": "cennik", "EN": "pricing"}, title: {"PL": "Cennik", "EN": "Pricing"}, component: ApplicationPricingPageComponent, exact: true},
	"cart-page": {url: {"PL": "koszyk", "EN": "cart"}, title: {"PL": "Koszyk", "EN": "Cart"}, component: ApplicationCartPageComponent, exact: true},
	"about-us": {url: {"PL": "o-nas", "EN": "about-us"}, title: {"PL": "ON", "EN": "AU"}, component: ArticlePageComponent, exact: true},
	"how-to-use": {url: {"PL": "jak-korzystac", "EN": "how-to-use"}, title: {"PL": "Jak korzystać", "EN": "How to use"}, component: ArticlePageComponent, exact: true},
	"regulations": {url: {"PL": "regulamin", "EN": "regulations"}, title: {"PL": "Regulamin", "EN": "Regulations"}, component: ArticlePageComponent, exact: true},
	"privacy-policy": {url: {"PL": "polityka-prywatnosci", "EN": "privacy-policy"}, title: {"PL": "Polityka Prywatności", "EN": "Privacy Policy"}, component: ArticlePageComponent, exact: true},
	"contact-us": {url: {"PL": "kontakt", "EN": "contact-us"}, title: {"PL": "Kontakt", "EN": "Contact Us"}, component: ContactPageComponent, exact: true},
	"our-advices": {url: {"PL": "nasze-rady", "EN": "our-advices"}, title: {"PL": "Nasze Rady", "EN": "Our Advices"}, component: ArticlesPageComponent, exact: true},
	"our-advice": {url: {"PL": "nasze-rady/:articleSlug", "EN": "our-advices/:articleSlug"}, title: {"PL": "Nasze Rady", "EN": "Our Advices"}, component: ArticlePageComponent, exact: false},
	"checkflat": {url: {"PL": "checkflat", "EN": "checkflat"}, title: {"PL": "Checkflat", "EN": "Checkflat"}, component: ApplicationCheckFlatPageComponent, exact: true},
	"profile": {url: {"PL": "profil", "EN": "profile"}, title: {"PL": "Mój Profil", "EN": "My Profile"}, component: ApplicationUserProfilePageComponent, exact: true},
	"login": {url: {"PL": "logowanie", "EN": "login"}, title: {"PL": "Logowanie", "EN": "Login"}, component: ApplicationLoginPageComponent, exact: true},
	"register": {url: {"PL": "rejestracja", "EN": "register"}, title: {"PL": "Rejestracja", "EN": "Register"}, component: ApplicationRegisterPageComponent, exact: true},
	"E404": {url: {"PL": "nie-znaleziono", "EN": "page-not-found"}, title: {"PL": "NZ", "EN": "NF"}, component: Error404PageComponent, exact: true},
};


export const ApplicationRouter = ({location, previousLanguage, activeLanguage}) => {
	
	
	const [activeRoute, setActiveRoute] = useState(null);
	
	
	useEffect(
		() => {
			
			if (!previousLanguage || previousLanguage === activeLanguage) return null;
			
			const activeRoutePath = location.pathname.split("/")[1];
			let activeRouteData = null;
			
			for (let [routeID, routeData] of Object.entries(ROUTES)) {
				if (routeData.url[previousLanguage] === activeRoutePath) {
					activeRouteData = {
						id: routeID, url: routeData.url[activeLanguage], subPages: routeData.subPages
					};
				}
			}
			
			if (!activeRouteData) return null;
			
			setActiveRoute(activeRouteData);
			
		},
		[activeLanguage]
	)
	
	useEffect(() => {
		if (!activeRoute) return null;
		setActiveRoute(null);
	}, [activeRoute])
	
	if (activeRoute) {
		return (
			<Redirect
				to={{
					pathname: `/${activeRoute?.url}`,
					state: {subPages: activeRoute.subPages}
				}}
			/>
		);
	}
	
	const routesList = Object.entries(ROUTES).map(([id, data]) => {
		
		const {
			component: Component, url, exact
		} = data;
		
		return (
			<Route
				key={id}
				exact={exact}
				path={`/${url[activeLanguage]}`}
				render={props => <Component {...props} activeLanguage={activeLanguage} />}
			/>
		);
		
	});
	
	
	return (
		<Switch>
			{routesList}
			<Redirect to={ROUTES["E404"].url[activeLanguage]} />
		</Switch>
	);
	
};


export default ApplicationRouter;
