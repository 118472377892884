import React, {useEffect, useState, useRef} from "react";


import {Col} from "react-bootstrap"


import scrollToDomElement from "@Utilities/Scrolling";


import CheckFormStepsHeaderComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Header";

import CheckFormStep1SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step1";
import CheckFormStep2SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step2";
import CheckFormStep3SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step3";
import CheckFormStep4SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step4";
import CheckFormStep5SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step5";
import CheckFormStep6SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step6";
import CheckFormStep7SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step7";
import CheckFormStep8SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step8";
import CheckFormStep9SectionComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Step9";
import CheckFormFinalStepComponent from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/StepFinal";
import CheckFormStepsButtonsComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/Steps/Buttons";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";


const LocalizationsObjects = {
	1: {localization_name: "Warszawa", localization_coords: {lat: 52.13, lng: 21.00}},
	2: {localization_name: "Gdańsk", localization_coords: {lat: 54.20, lng: 18.38}},
	3: {localization_name: "Gdynia", localization_coords: {lat: 54.31, lng: 18.32}},
	4: {localization_name: "Kraków", localization_coords: {lat: 50.03, lng: 19.56}},
	5: {localization_name: "Wrocław", localization_coords: {lat: 51.06, lng: 17.01}},
};


const MARKET = {
	PL: {1: "Pierwotny", 2: "Wtórny"},
	EN: {1: "Primary", 2: "Secondary"},
};


const FLOOR = {
	PL: "piętro",
	EN: "floor",
};


const RIGHTS = {
	PL: {1: "Własnościowe", 2: "Spółdzielcze"},
	EN: {1: "Ownership", 2: "Cooperative"},
};


const ROOMS = {
	PL: {1: "pomieszczenie", 2: "pomieszczenia", 3: "pomieszczeń"},
	EN: {1: "room", 2: "rooms", 3: "rooms"},
};


const STANDARDS = {
	PL: {1: "Wysoki", 2: "Dobry", 3: "Do odświeżenia", 4: "Do generalnego remontu", 0: "Nie wybrano"},
	EN: {1: "High", 2: "Good", 3: "To refresh", 4: "For general renovation", 0: "Not selected"},
};


export const CheckFormStepsSectionComponent = ({
	activeLanguage,
	formStep, formStepHandler,
	formData, formDataHandler
}) => {
	
	
	const checkFlatStepsContainer = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(checkFlatStepsContainer.current, 200);
		return () => {};
	}, [formStep]);
	
	
	const {
		addressCity, addressStreet, addressHouseNo,
		marketType,
		flatYardage, flatStorey, flatPropertyType, flatRooms, flatStandard,
	} = formData;
	
	let headerLabel = addressCity ?
		addressCity + (addressStreet ? ", " + addressStreet : "") + (addressHouseNo ? " " + addressHouseNo : "")
		:
		""
	;
	
	if (marketType) {
		headerLabel += `, ${activeLanguage === "PL" ? "Rynek" : "Market"} ` + (MARKET[activeLanguage][marketType]);
	}
	
	if (flatYardage) {
		headerLabel += `, ${flatYardage} m<sup>2</sup>`;
	}
	
	if (flatStorey) {
		headerLabel += `, ${flatStorey} ${FLOOR[activeLanguage]}`;
	}
	
	if (flatPropertyType) {
		headerLabel += ", " + (RIGHTS[activeLanguage][flatPropertyType]);
	}
	
	if (flatRooms) {
		headerLabel += `, ${flatRooms} ` + (+flatRooms === 1 ? ROOMS[activeLanguage][1] : (flatRooms < 5 ? ROOMS[activeLanguage][2] : ROOMS[activeLanguage][3]))
	}
	
	if (flatStandard) {
		headerLabel += ", Standard " + STANDARDS[activeLanguage][flatStandard];
	}
	
	return (
		<Col ref={checkFlatStepsContainer} xs={12} className={"check-form-steps-component"}>
			
			<CheckFormStepsHeaderComponent
				checkFormStep={formStep}
				checkFormHeaderLabel={headerLabel}
			/>
			
			{formStep === 1 &&
			<CheckFormStep1SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
			/>
			}
			
			{formStep === 2 &&
			<CheckFormStep2SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 3 &&
			<CheckFormStep3SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 4 &&
			<CheckFormStep4SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 5 &&
			<CheckFormStep5SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 6 &&
			<CheckFormStep6SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 7 &&
			<CheckFormStep7SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 8 &&
			<CheckFormStep8SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 9 &&
			<CheckFormStep9SectionComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			{formStep === 10 &&
			<CheckFormFinalStepComponent
				activeLanguage={activeLanguage}
				formData={formData}
				formDataHandler={formDataHandler}
				formStep={formStep}
				formStepHandler={formStepHandler}
				supportedLocalizations={LocalizationsObjects}
			/>
			}
			
			<CheckFormFooterComponent xs={12} lg={8} xl={8}>
				<CheckFormStepsButtonsComponent
					checkFormStep={formStep}
					checkFormStepHandler={formStepHandler}
				/>
			</CheckFormFooterComponent>
			
		</Col>
	);
	
	
};


export default CheckFormStepsSectionComponent;
