import React from "react";


import {Col, Row} from "react-bootstrap";


export const ArticlesListPaginationComponent = ({
	activePaginationPage, activePaginationPageHandler,
	paginationPagesNumber = 0
}) => {
	
	
	if (paginationPagesNumber < 1) return null;
	
	const PAGES = [];
	for (let i = 0; i < paginationPagesNumber; i++) PAGES.push(i + 1);
	
	
	const PaginationPages = PAGES.map(page =>
		page === activePaginationPage ?
			<span className={"pagination-item active-item"}>
				<span>{page}</span>
			</span>
			:
			<a href={`#page-${page}`} className={"pagination-item"} title={page} onClick={e => paginationItemClickHandler(e, page)}>
				<span>{page}</span>
			</a>
	);
	
	
	const paginationItemClickHandler = (e, page) => {
		e.preventDefault();
		activePaginationPageHandler(page);
	};
	
	
	return (
		<Col xs={12} className={"articles-list-pagination pagination-component"}>
			<Row className={"articles-list-pagination-content pagination-component-content text-center justify-content-center"}>
				
				{activePaginationPage > 1 &&
				<a href={"#prev-page"} title={""} className={"pagination-item previous-item"} onClick={e => paginationItemClickHandler(e, activePaginationPage - 1)}>
					<span>{"<"}</span>
				</a>
				}
				
				{PaginationPages}
				
				{activePaginationPage < paginationPagesNumber &&
				<a href={"#next-page"} title={""} className={"pagination-item next-item"} onClick={e => paginationItemClickHandler(e, activePaginationPage + 1)}>
					<span>{">"}</span>
				</a>
				}
			
			</Row>
		</Col>
	);
	
};


export default ArticlesListPaginationComponent;
