import React from "react";


import {Col} from "react-bootstrap";


export const CheckFormHeaderComponent = ({
	xs = 12, lg = 12,
	text = "",
	children,
	...props
}) => {
	
	
	if (!text) {
		return null;
	}
	
	
	return (
		<Col {...props} xs={xs} lg={lg} className="check-form-step-header user-form-header">
			<span>{text}</span>
		</Col>
	);
	
}


export default CheckFormHeaderComponent;
