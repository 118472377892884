import React, {useContext, useEffect, useRef, useState} from "react";

import parse from "html-react-parser";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Container, Row, Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import MainHeaderComponent from "@Elements/Blocks/Sections/Headers/MainHeader";


import scrollToDomElement from "@Utilities/Scrolling";


import CONTENT, {TITLE} from "@UIPages/Common/ArticlesPage/_SAMPLE";
import ImagePlaceholder from "@Images/no-image.png";


export const ArticlePageComponent = ({match}) => {
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	const ARTICLE_SLUG = match?.params?.articleSlug;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode;
	
	
	const [articleData, setArticleData] = useState(null);
	
	useEffect(() => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		fetch(`${REST_API_URL}?l=${ACTIVE_LANGUAGE}&s=${ARTICLE_SLUG}`).then(response => response).then(content => content).catch(error => error);
		setArticleData({article_id: 1, article_content: CONTENT, article_title: TITLE});
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		return () => {
			setArticleData(null);
		};
	}, [ACTIVE_LANGUAGE]);
	
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 150);
		return () => {};
	}, [articleData]);
	
	
	if (!articleData) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		// article_id: articleID,
		article_title: articleTitle,
		article_content: articleContent,
		article_information: articleInformation
	} = articleData;
	
	
	return (
		<Container as={"article"} ref={CONTAINER_REF} as="main" fluid={true} id="article-page-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<MainHeaderComponent headerText={articleTitle} headerCssClasses={"article-title"} />
					
					<Col as={"aside"} xs={12} className={"article-image"}>
						<img src={ImagePlaceholder} alt={articleTitle} />
					</Col>
					
					<Col as={"article"} xs={12} className={"article-content"}>
						{parse(articleContent)}
					</Col>
					
					{!!ARTICLE_SLUG &&
					<Col as={"aside"} xs={12} className={"article-information"}>
						<Row className={"article-information-content"}>
							<Col xs={6} className={"article-author"}>{"John Doe"}</Col>
							<Col xs={6} className={"article-date"}>{(new Date()).toLocaleDateString()}</Col>
						</Row>
					</Col>
					}
					
				</Row>
			</Container>
		</Container>
	);
	
	
};


export default ArticlePageComponent;
