import React from "react";


import {Col, Row} from "react-bootstrap";


export const CheckFormFooterComponent = ({
	xs = 12, lg = 6,
	children,
	...props
}) => {
	
	
	return (
		<Col {...props} xs={xs} lg={lg} className={"user-form-footer"}>
			<Row className={"user-form-footer-content"}>
				{children}
			</Row>
		</Col>
	);
	
};


export default CheckFormFooterComponent;
