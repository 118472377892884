import React, {useContext, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Row, Col, Form} from "react-bootstrap";


import AvatarPlaceholder from "@Images/no-avatar.jpg";


const TEXTS = {
	PL: {change_text: "zmień", cancel_text: "anuluj", save_text: "zapisz", remove_text: "usuń", confirm_text: "potwierdź"},
	EN: {change_text: "change", cancel_text: "cancel", save_text: "save", remove_text: "remove", confirm_text: "confirm"},
};

const STATUSES = {
	PL: {1: "Oczekuje na potwierdzenie", 2: "Potwierdzony", 3: "Odrzucony"},
	EN: {1: "Waiting for Confirmation", 2: "Confirmed", 3: "Rejected"},
};

const CHECK_TYPES = {
	PL: {
		1: "korzysta z całej puli sprawdzeń",
		2: "korzysta z części puli sprawdzeń",
		3: "nie korzysta z puli sprawdzeń",
	},
	EN: {
		1: "can use the entire pool of checks",
		2: "can use the part of pool of checks",
		3: "cannot use the check pool",
	},
};

const MESSAGES = {
	PL: {data_update_success_message: "Dane zostały zaktualizowane", remove_member_from_team_message: "Członek zespołu został usunięty"},
	EN: {data_update_success_message: "Team member updated successfully", remove_member_from_team_message: "Team member successfully removed"},
};


export const TeamMemberComponent = ({
	teamMemberData, teamMemberHandler = null,
	orderNr = null,
	teamMembersListMessageHandler
}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {change_text: changeText, cancel_text: cancelText, save_text: saveText, remove_text: removeText, confirm_text: confirmText} = TEXTS[ACTIVE_LANGUAGE];
	
	const STATUSES_LIST = STATUSES[ACTIVE_LANGUAGE];
	const CHECK_TYPES_LIST = CHECK_TYPES[ACTIVE_LANGUAGE];
	
	const {
		data_update_success_message: dataUpdateSuccessMessage,
		remove_member_from_team_message: removeMemberFromTeamMessage
	} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const [teamMemberDataEditableStatus, setTeamMemberDataEditableStatus] = useState(false);
	const [teamMemberRemoveStatus, setTeamMemberRemoveStatus] = useState(false);
	
	
	const {
		users_team_user_id: teamMemberID, users_team_user_status: teamMemberStatus,
		users_team_user_checks_type: teamMemberChecksType, // users_team_user_Checks_number: teamMemberChecksNumber,
		users_team_user_data: teamMemberUserData
	} = teamMemberData;
	
	const [teamMemberChecks, setTeamMemberChecks] = useState(teamMemberChecksType);
	
	
	const {
		user_first_name: userFirstName, user_last_name: userLastName,
		user_avatar: userAvatar, user_email: userEmail
	} = teamMemberUserData;
	
	
	const TEAM_MEMBER_API_URL = `${REST_API_URL}/app/users/teams/members/${teamMemberID}/`;
	
	
	const teamMemberDataHandler = (e) => {
		e.preventDefault();
		setTeamMemberDataEditableStatus(!teamMemberDataEditableStatus);
	};
	
	
	const teamMemberRequestHandler = async (e) => {
		
		e.preventDefault();
		
		setTeamMemberDataEditableStatus(false);
		
		if (REST_API_REQUEST_STATUS || teamMemberChecks === teamMemberChecksType) return null;
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const formData = new FormData;
		formData.append("users_team_user_checks_type", teamMemberChecks.toString());
		
		try {
			const response = await fetch(TEAM_MEMBER_API_URL, {method: "PATCH", body: formData});
			if (!response.ok) {
				console.log(response);
			} else {
				const content = await response.json();
				teamMembersListMessageHandler({success: true, message: dataUpdateSuccessMessage});
				teamMemberHandler(content);
			}
		} catch (e) {
			console.log(e);
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	const removeTeamMemberHandler = async (e) => {
		
		e.preventDefault();
		if (!teamMemberRemoveStatus) {
			return setTeamMemberRemoveStatus(true);
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		try {
			const response = await fetch(TEAM_MEMBER_API_URL, {method: "DELETE"});
			if (!response.ok) {
				console.log(response);
			} else {
				teamMembersListMessageHandler({success: true, message: removeMemberFromTeamMessage});
				teamMemberHandler();
			}
		} catch (e) {
			console.log(e);
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	const changeDataEditableStatusText = teamMemberDataEditableStatus ? cancelText : changeText;
	const removeMemberStatusText = teamMemberRemoveStatus ? confirmText : removeText;
	
	
	return (
		<Row className={"team-member-item"}>
		
			<Col xs={12} className={"team-member-header"}>
				<Row className={"team-member-header-content"}>
					
					<Col xs={1} className={"team-member-order-nr"}>
						<span>{orderNr}</span>
					</Col>
					
					<Col xs={2} className={"team-member-avatar"}>
						<img src={userAvatar || AvatarPlaceholder} alt={""} />
					</Col>
					
					<Col xs={6} className={"team-member-name"}>
						<span>{userFirstName} {userLastName}</span>
						<a href={`mailto:${userEmail}`} title={"Email"}>{userEmail}</a>
					</Col>
					
					<Col xs={3} className={`team-member-status status-${teamMemberStatus}`}>
						<span>{STATUSES_LIST[teamMemberStatus]}</span>
					</Col>
					
				</Row>
			</Col>
			
			<Col xs={12} className={"team-member-footer"}>
				<Row className={"team-member-footer-content"}>
					
					<Col xs={7} className={"team-member-checks"}>
						{!teamMemberDataEditableStatus &&
						<span>{CHECK_TYPES_LIST[teamMemberChecksType]}</span>
						}
						{teamMemberDataEditableStatus &&
						<Form.Control
							as={"select"}
							value={teamMemberChecks}
							onChange={e => setTeamMemberChecks(e.target.value)}
							disabled={REST_API_REQUEST_STATUS}
						>
							<option value={"1"}>{CHECK_TYPES_LIST["1"]}</option>
							<option value={"2"}>{CHECK_TYPES_LIST["2"]}</option>
							<option value={"3"}>{CHECK_TYPES_LIST["3"]}</option>
						</Form.Control>
						}
					</Col>
					
					<Col xs={3} className={"team-member-checks-change"}>
						<a href={"#change-team-member-checks"} title={changeDataEditableStatusText} onClick={e => teamMemberDataHandler(e)} className={teamMemberDataEditableStatus ? "active" : ""}>
							<span>{changeDataEditableStatusText}</span>
						</a>
						{teamMemberDataEditableStatus &&
						<a href={"#save-team-member-checks"} title={saveText} onClick={e => teamMemberRequestHandler(e)} className={"save"}>
							<span>{saveText}</span>
						</a>
						}
					</Col>
					
					<Col xs={2} className={"team-member-remove"}>
						<a href={"#remove-team-member"} title={removeMemberStatusText} onClick={e => removeTeamMemberHandler(e)} className={teamMemberRemoveStatus ? "active" : ""}>
							<span>{removeMemberStatusText}</span>
						</a>
					</Col>
					
				</Row>
			</Col>
		
		</Row>
	);
	
};


export default TeamMemberComponent;
