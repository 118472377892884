import React, {useContext, useEffect, useRef, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION, STATE_UPDATE_HANDLER_ACTION} from "@Store/Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Redirect} from "react-router-dom";


import {Container, Row} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import ProfilePageMainDataSectionComponent from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection";
import UserPaymentHistoryComponent from "@UIPages/User/Authenticated/Profile/Sections/PaymentsHistory";
import UserTeamComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection";
// import UserSubscriptionsComponent from "@UIPages/User/Authenticated/Profile/Sections/Subscriptions";
import UserChecksHistoryComponent from "@UIPages/User/Authenticated/Profile/Sections/ChecksHistory";


const URLS = {
	PL: {login_page_url: "logowanie"},
	EN: {login_page_url: "login"},
}


export const ApplicationUserProfilePageComponent = ({}) => {
	
	
	const STATE_UPDATE_HANDLER = useSelector(state => state?.interface)?.stateUpdateHandler;
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	
	const CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 150);
		return () => {};
	}, [ACTIVE_LANGUAGE]);
	
	
	const LOGGED_USER = useSelector(state => state?.authentication?.loggedUser);
	
	
	const [CHECK_FLAT_USER, SET_CHECK_FLAT_USER] = useState(null);
	
	useEffect(() => {
		
		if (STATE_UPDATE_HANDLER) return DISPATCHER(STATE_UPDATE_HANDLER_ACTION())
		
		if (!LOGGED_USER?.id) return null;
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		fetch(`${REST_API_URL}/app/users/list/${LOGGED_USER.id}`)
			.then(response => response.ok ? response.json() : {error: true, code: response.status, message: response.statusText})
			.then(response => {
				SET_CHECK_FLAT_USER(response);
				DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
			})
			.catch(error => {
				SET_CHECK_FLAT_USER({error: true, code: 500, message: error.message});
				DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
			})
		;
		
		return () => {
			SET_CHECK_FLAT_USER(null);
		};
		
	}, [LOGGED_USER, STATE_UPDATE_HANDLER]);
	
	
	const changeUserDataHandler = async (fieldName, fieldValue, callback = null) => {
		
		const userFormData = new FormData();
		userFormData.append(fieldName, fieldValue);
		
		await DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		try {
			const response = await fetch(`${REST_API_URL}/app/users/list/${LOGGED_USER.id}/`, {method: "PATCH", body: userFormData})
			if (!response.ok) return console.log(response.status, response.statusText);
			try {
				const userData = await response.json();
				SET_CHECK_FLAT_USER(userData);
				callback();
			} catch (e) {
				console.log(e);
			}
		} catch (e) {
			console.log(e);
		}
		
		await DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	if (!LOGGED_USER) {
		return <Redirect to={`/${URLS[ACTIVE_LANGUAGE]["login_page_url"]}`} />;
	}
	
	
	if (!CHECK_FLAT_USER) {
		return <MainContentLoaderComponent />;
	}
	
	
	const {
		id: userID,
		user_first_name: userFirstName, user_last_name: userLastName,
		user_email: userEmailAddress,
		user_company_invoice_status: userCompanyInvoiceStatus, user_company_name: userCompanyName, user_company_dni: userCompanyDni,
		user_company_street: userCompanyStreet, user_company_house_no: userCompanyHouseNo,
		user_company_post_code: userCompanyPostCode, user_company_city: userCompanyCity,
		user_birthday_present_status: userBirthdayPresentStatus, user_birthday_date: userBirthdayDate,
		user_transaction_type: userTransactionType, user_transaction_sub_type: userTransactionSubType,
		user_orders: userOrders, user_payments: userPayments,
		user_reports: userReports, user_subscriptions: userSubscriptions, user_team: userTeam,
		user_active_package_order_data: userActivePackageOrder
	} = CHECK_FLAT_USER;
	
	
	const proceededOrders = userOrders.filter(order => order["estate_reports_order_status"] !== "cancelled");
	
	
	let userActivePackage = null;
	
	if (userActivePackageOrder) {
		
		const {
			// id: orderID, estate_reports_order_status: orderStatus, estate_reports_order_payment: orderPayment,
			estate_reports_order_offer_type: orderOfferType,
			estate_reports_order_date: orderDate, estate_reports_order_reports: orderReports
		} = userActivePackageOrder;
		
		const {offer_price_package: offerPricePackage} = orderOfferType;
		
		const {
			offer_price_package_name: offerPricePackageName, offer_price_package_team_members: offerPricePackageTeamMembers
		} = offerPricePackage;
		
		userActivePackage = {offerPricePackageName, offerPricePackageTeamMembers, orderDate, orderReports};
		
	}
	
	
	return (
		<Container ref={CONTAINER_REF} as="section" fluid={true} id="profile-page-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
		
					<ProfilePageMainDataSectionComponent
						checkFlatUserData={{
							userID, userFirstName, userLastName, userEmailAddress,
							userBirthdayPresentStatus, userBirthdayDate,
							userCompanyInvoiceStatus, userCompanyName, userCompanyDni,
							userCompanyStreet, userCompanyHouseNo,
							userCompanyPostCode, userCompanyCity,
							userTransactionType, userTransactionSubType,
							proceededOrders
						}}
						checkFlatUserDataHandler={changeUserDataHandler}
						CHECK_FLAT_USER={CHECK_FLAT_USER}
						CHECK_FLAT_USER_HANDLER={SET_CHECK_FLAT_USER}
					/>
					
					
					<UserTeamComponent
						CHECK_FLAT_USER_DATA={{userID, userEmailAddress, userActivePackage, userTeam}}
					/>
					
					
					<UserChecksHistoryComponent
						CHECK_FLAT_USER_DATA={{userActivePackage, userReports}}
					/>
					
					
					{/*<UserSubscriptionsComponent*/}
					{/*	CHECK_FLAT_USER_DATA={{userSubscriptions}}*/}
					{/*/>*/}
					
					
					<UserPaymentHistoryComponent
						CHECK_FLAT_USER_DATA={{userPayments}}
					/>
					
			
				</Row>
			</Container>
		</Container>
	);
	
};


export default ApplicationUserProfilePageComponent;
