import React from "react";


export const REST_API_SETTINGS = {
	rootRestApiUrl: "https://agge.webprosdev.usermd.net/api/v1",
	// rootRestApiUrl: "http://127.0.0.1:8010/api/v1",
}


const RestApiContext = React.createContext(null);


export default RestApiContext
