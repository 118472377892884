import React from "react";

import PropTypes from 'prop-types';


import {BLOCK_HTML_TAGS} from "@Utilities/Data/HTML";


import {Col, Form} from "react-bootstrap";


export const FormErrorFieldComponent = ({
	errorMessage,
	xs = 12, sm = 12, lg = 12, xl = 12, as = "aside",
	containerCssClasses = "", labelCssClasses = "",
	...props
}) => {
	
	
	if (!errorMessage) return null;
	
	
	containerCssClasses = "form-field error-field" + (containerCssClasses ? ` ${containerCssClasses}` : "");
	
	labelCssClasses = "error" + (labelCssClasses ? ` ${labelCssClasses}` : "");
	
	
	return (
		<Col
			as={as}
			xs={xs} sm={sm} lg={lg} xl={xl}
			className={containerCssClasses}
			{...props}
		>
			
			<Form.Label className={labelCssClasses}>
				<span>{errorMessage}</span>
			</Form.Label>
			
		</Col>
	);
	
};


FormErrorFieldComponent.propTypes = {
	errorMessage: PropTypes.string.isRequired,
	as: PropTypes.oneOf(BLOCK_HTML_TAGS),
	xs: PropTypes.number,
	sm: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	containerCssClasses: PropTypes.string,
	labelCssClasses: PropTypes.string
};


export default FormErrorFieldComponent;
