import React from "react";


import {Col, Alert} from "react-bootstrap";

import {FaRegWindowClose} from "react-icons/all";


export const CommunicateComponent = ({communicateText, children = null, variant = "info", closeHandler = null}) => {
	
	if (!communicateText && !children) return null;

	return (
		<Col className={"message-communicate-component message-communicate"}>
			{closeHandler && <span className={"close"} onClick={closeHandler}><FaRegWindowClose /></span>}
			{communicateText && <Col xs={12} as={Alert} variant={variant}>{communicateText}</Col>}
			{children}
		</Col>
	);
	
};


export default CommunicateComponent;
