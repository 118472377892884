import React, {useState, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {CHANGE_LANGUAGE_ACTION} from "@Store/Actions/Localization";


import {Row, Col} from "react-bootstrap";


import {FaGlobeEurope} from "react-icons/all";


const LANGUAGES = {
	"PL": "Polski",
	"EN": "Angielski"
};


export const HeaderLanguagesComponent = ({}) => {
	
	
	const [languagesMenuDisplayStatus, setLanguagesMenuDisplayStatus] = useState(false);
	
	useEffect(() => {
		// const clickListener = () => setLanguagesMenuDisplayStatus(false);
		// window.addEventListener("click", clickListener);
		return () => {
			setLanguagesMenuDisplayStatus(false);
			// window.removeEventListener(clickListener);
		};
	}, []);
	
	
	const activeLanguage = useSelector(state => state.localization.activeLanguage?.languageCode)
	
	const DISPATCHER = useDispatch();
	
	const changeActiveLanguage = (languageCode) => {
		DISPATCHER(CHANGE_LANGUAGE_ACTION({languageCode: languageCode}));
		setLanguagesMenuDisplayStatus(false);
	};
	
	const languagesList = Object.keys(LANGUAGES).map(language => {
		if (language === activeLanguage) return null;
		return (
			<Col
				xs={12}
				className="languages-switcher-language"
				onClick={() => changeActiveLanguage(language)}
			>
				<span>{language}</span>
			</Col>
		);
	});
	
	
	return (
		<Col xs={2} lg={1} className="application-header-languages-switcher">
		
			<Row className="languages-switcher-active-language">
				<Col
					xs={6}
					className="active-language-code"
					onClick={() => setLanguagesMenuDisplayStatus(!languagesMenuDisplayStatus)}
				>
					<span>{activeLanguage}</span>
				</Col>
				<Col xs={6} className="active-language-icon">
					<FaGlobeEurope />
				</Col>
			</Row>
			
			{languagesMenuDisplayStatus &&
			<Row className="languages-switcher-languages-list">
				{languagesList}
			</Row>
			}
			
		</Col>
	);
	
};


export default HeaderLanguagesComponent;
