import React, {useContext, useEffect, useRef, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Alert, Container, Row, Col} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import ContactPageHeaderSectionComponent from "@UIPages/Common/ContactPage/HeaderSection";
import ContactPageFormSectionComponent from "@UIPages/Common/ContactPage/FormSection";


const TEXTS = {
	PL: {
		header_title_text: "Kontakt", header_description_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
		sent_message_confirm_text: "Dziękujemy - wiadomość została wysłana"
	},
	EN: {
		header_title_text: "Contact Us", header_description_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
		sent_message_confirm_text: "Thank You - message sent"
	}
};


const MESSAGES = {
	PL: {
		undefined_error_message: code => `"Wystąpił nieokreślony błąd (${code})"`,
		missing_data_error_message: "Nie przesłano wszystkich wymaganych danych",
	},
	EN: {
		undefined_error_message: code => `"Undefined Error (${code})"`,
		missing_data_error_message: "Missing Request Data",
	}
};


export const ContactPageComponent = ({location}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const {
		header_title_text: headerTitleText, header_description_text: headerDescriptionText,
		sent_message_confirm_text: sentMessageConfirmText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	const {
		undefined_error_message: undefinedErrorMessage, missing_data_error_message: missingDataErrorMessage
	} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	
	const CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 150);
		return () => {
			setContactMessageSentStatus(false);
			setContactFormErrors({});
		}
	}, []);
	
	
	const [contactMessageSentStatus, setContactMessageSentStatus] = useState(false);
	const [contactFormErrors, setContactFormErrors] = useState({});
	
	
	const sendMessageHandler = async (messageData) => {
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const {messageSubject, messageContent, messageEmail} = messageData;
		
		try {
			
			const formData = new FormData();
			formData.append("email_type", "contact");
			formData.append("contact_form_email", messageEmail);
			formData.append("contact_form_subject", messageSubject);
			formData.append("contact_form_content", messageContent);
			
			const response = await fetch(`${REST_API_URL}/mailer/`, {method: "POST", body: formData});
			console.log(response, messageSubject, messageContent, messageEmail)
			if (!response.ok) {
				setContactFormErrors({responseError: undefinedErrorMessage(response.status)});
			} else {
				try {
					const responseContent = await response.json();
					if (responseContent.error) {
						setContactFormErrors({responseError: missingDataErrorMessage(responseContent.code)});
					} else if (responseContent.success && responseContent.success === true) {
						setContactMessageSentStatus(true);
					} else {
						setContactFormErrors({responseError: undefinedErrorMessage(500)});
					}
				} catch (e) {
					setContactFormErrors({responseError: undefinedErrorMessage(500)});
				}
			}
			
		} catch (e) {
			setContactFormErrors({responseError: undefinedErrorMessage(500)});
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	const messageSubject = location?.state?.contactFormSubject || "0";
	
	
	return (
		<Container ref={CONTAINER_REF} as="section" fluid={true} id="contact-page-component">
			
			<ContactPageHeaderSectionComponent
				sectionData={{sectionTitle: headerTitleText, sectionText: headerDescriptionText}}
			/>
			
			<Container as="section" fluid={true} id="contact-page-form-section-component" className="block-section-container-fluid">
				<Container className="block-section-container">
					<Row className="block-section-content">
					
						{!contactMessageSentStatus && !contactFormErrors.responseError &&
						<ContactPageFormSectionComponent
							contactFormErrors={contactFormErrors}
							contactFormErrorsHandler={setContactFormErrors}
							sendMessageHandler={sendMessageHandler}
							messageSubject={messageSubject}
						/>
						}
						
						{contactFormErrors.responseError &&
						<Col as={Alert} xs={12} className={"message-communicate error-communicate"} variant={"danger"}>
							<span>{contactFormErrors.responseError}</span>
						</Col>
						}
						
						{contactMessageSentStatus &&
						<Col as={Alert} xs={12} className={"message-communicate success-communicate"} variant={"success"}>
							<span>{sentMessageConfirmText}</span>
						</Col>
						}
					
					</Row>
				</Container>
			</Container>
			
			
		</Container>
	);
	
};


export default ContactPageComponent;
