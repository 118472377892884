import React from "react";


import {Col} from "react-bootstrap";


export const ProfileSectionHeaderComponent = ({headerText}) => {

	
	return (
		<Col xs={12} as={"h4"} className={"profile-section-header"}>
			<span>{headerText}</span>
		</Col>
	);
	
};


export default ProfileSectionHeaderComponent;
