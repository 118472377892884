import React from "react";


import CommunicateComponent from "@Elements/Communicates";


export const SuccessCommunicateComponent = ({communicateText, children = null, ...props}) => {
	
	if (!communicateText && !children) return null;
	
	return (
		<CommunicateComponent variant={"success"} communicateText={communicateText} {...props}>
			{children}
		</CommunicateComponent>
	);
	
};


export default SuccessCommunicateComponent;
