import React, {useContext, useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions/Interface";

import RestApiContext from "@Contexts/REST";


import {Row, Col} from "react-bootstrap";


import ErrorCommunicateComponent from "@Elements/Communicates/Error";
import SuccessCommunicateComponent from "@Elements/Communicates/Success";


import AddTeamMemberFormComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/Add/Form";
import AddTeamMemberSwitcherComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/Add/Switcher";


const TEXTS = {
	PL: {},
	EN: {}
};

const MESSAGES = {
	PL: {
		super_user_add_to_team_error_message: "Nie możesz dodać do zespołu siebie",
		add_team_member_success_message: "Wysłano zaproszenie do zespołu",
		user_already_in_team_message: "Użytkownik o podanym adresie email należy już do zespołu",
		user_does_not_exist_error_message: "Brak użytkownika o podanym adresie email w bazie CheckFlat",
		undefined_error_message: code => `Wystąpił nieokreślony błąd - skontaktuj się z naszym supportem (kod ${code})`
	},
	EN: {
		super_user_add_to_team_error_message: "You can not add yourself to team",
		add_team_member_success_message: "Team Member Invitation Sent",
		user_already_in_team_message: "User with that email address already in team",
		user_does_not_exist_error_message: "User with that email address Does not exist in CheckFlat Database",
		undefined_error_message: code => `Undefined Error appeared - please contact support (code: ${code})`
	},
};


export const AddTeamMemberComponent = ({userEmailAddress, teamApiURL, teamMembersHandler, teamDefaultChecksOption}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	const TEAMS_MEMBERS_REST_API_URL = `${REST_API_URL}/app/users/teams/members/`;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {} = TEXTS[ACTIVE_LANGUAGE];
	
	const {
		super_user_add_to_team_error_message: superUserAddToTeamErrorMessage,
		user_does_not_exist_error_message: userDoesNotExistErrorMessage,
		undefined_error_message: undefinedErrorMessage,
		user_already_in_team_message: userAlreadyInTeamMessage,
		add_team_member_success_message: addTeamMemberSuccessMessage
	} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const [addMemberFormActiveStatus, setAddMemberFormActiveStatus] = useState(false);
	const [addMemberFormMessage, setAddMemberFormMessage] = useState(null);
	
	const addTeamMemberFormStatusHandler = (e) => {
		e.preventDefault();
		setAddMemberFormActiveStatus(!addMemberFormActiveStatus);
		setAddMemberFormMessage(null);
	};
	
	
	useEffect(() => {
		return () => {
			setAddMemberFormActiveStatus(false);
			setAddMemberFormMessage(null);
		};
	}, []);
	
	
	const addTeamMemberRequestHandler = async (memberData) => {
		
		setAddMemberFormMessage(null);
		
		const {formUserEmail, formUserChecks} = memberData;
		
		if (formUserEmail === userEmailAddress) {
			return setAddMemberFormMessage({type: "error", message: superUserAddToTeamErrorMessage});
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const checkUserExistsData = new FormData();
		checkUserExistsData.append("search_field", "user_email")
		checkUserExistsData.append("field_value", formUserEmail)
		
		const userExistsResponse = await fetch(`${REST_API_URL}/app/users/list/get-user-by-field/`, {method: "POST", body: checkUserExistsData});
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
		if (!userExistsResponse.ok) return setAddMemberFormMessage({type: "error", code: userExistsResponse.status, message: undefinedErrorMessage(userExistsResponse.status)});
		
		const userExistsResponseContent = await userExistsResponse.json();
		
		if (userExistsResponseContent.error) {
			switch (userExistsResponseContent.code) {
				case 404:
					return setAddMemberFormMessage({type: "error", code: userExistsResponseContent.code, message: userDoesNotExistErrorMessage});
				default:
					return setAddMemberFormMessage({type: "error", code: userExistsResponseContent.code, message: undefinedErrorMessage(userExistsResponseContent.code)});
			}
		}
		
		if (!userExistsResponseContent.success) {
			return setAddMemberFormMessage({type: "error", code: userExistsResponseContent.code, message: undefinedErrorMessage(500)});
		}
		
		const {
			id: userID, // user_active_status: userActiveStatus
		} = userExistsResponseContent.user;
		
		const addMemberFormData = new FormData();
		addMemberFormData.append("users_team_user_checks_type", formUserChecks);
		addMemberFormData.append("users_team_user_team", teamApiURL);
		addMemberFormData.append("users_team_user", `${REST_API_URL}/app/users/list/${userID}/`);
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		const addMemberResponse = await fetch(TEAMS_MEMBERS_REST_API_URL, {method: "POST", body: addMemberFormData});
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		const addMemberResponseContent = await addMemberResponse.json();
		
		if (addMemberResponse.status === 201) {
			teamMembersHandler();
			setAddMemberFormActiveStatus(false);
			return setAddMemberFormMessage({type: "success", message: addTeamMemberSuccessMessage});
		} else if (addMemberResponseContent["users_team_user"]) {
			return setAddMemberFormMessage({type: "error", code: 403, message: userAlreadyInTeamMessage});
		}
		else {
			return setAddMemberFormMessage({type: "error", code: 500, message: undefinedErrorMessage(500)});
		}
		
	};
	
	
	const MessageComponent = addMemberFormMessage ? (
		addMemberFormMessage.type === "success" ? SuccessCommunicateComponent : ErrorCommunicateComponent
	) : null;
	
	
	return (
		<Col xs={12} className={"add-team-member"}>
			
			<AddTeamMemberSwitcherComponent
				addMemberFormActiveStatus={addMemberFormActiveStatus}
				addTeamMemberFormStatusHandler={addTeamMemberFormStatusHandler}
			/>
			
			{addMemberFormMessage &&
			<Row className={"team-members-message"}>
				<MessageComponent communicateText={addMemberFormMessage.message} closeHandler={() => setAddMemberFormMessage(false)} />
			</Row>
			}
			
			{addMemberFormActiveStatus &&
			<AddTeamMemberFormComponent
				addTeamMemberRequestHandler={addTeamMemberRequestHandler}
				defaultFormUserChecks={teamDefaultChecksOption}
			/>
			}
			
		</Col>
	);
	
};


export default AddTeamMemberComponent;
