import React, {useEffect} from "react";

import {useSelector} from "react-redux";


import {NavLink} from "react-router-dom";


import {Container, Row, Col, Button} from "react-bootstrap";


import CartPageStepsSectionComponent from "@UIPages/Common/CartPage/_Parts/Steps";


const TEXTS = {
	PL: {
		header_text: "Dziękujemy i gratulacje bo właśnie zyskujesz:",
		benefit_1_text: "Czas i łatwiejszą decyzję",
		benefit_2_text: "Ekspercką wiedzę",
		benefit_3_text: "Fachowe Porady",
		invite_to_team_button_text: "Zaproś do zespołu",
		go_to_checkflat_button_text: "Przejdź do checkflata",
	},
	EN: {
		header_text: "Thank you and congratulations because you just gain:",
		benefit_1_text: "Time and easier decision",
		benefit_2_text: "Expert knowledge",
		benefit_3_text: "Professional Tips",
		invite_to_team_button_text: "Invite to the team",
		go_to_checkflat_button_text: "Go to checkflat",
	}
};


const URLS = {
	PL: {
		profile_url: "profil",
		checkflat_url: "checkflat"
	},
	EN: {
		profile_url: "profile",
		checkflat_url: "checkflat"
	},
};


export const CartPageSummaryComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage?.languageCode || "PL";
	
	const {
		header_text: headerText,
		benefit_1_text: benefit1Text,
		benefit_2_text: benefit2Text,
		benefit_3_text: benefit3Text,
		invite_to_team_button_text: inviteToTeamButtonText,
		go_to_checkflat_button_text: goToCheckflatButtonText,
	} = TEXTS[ACTIVE_LANGUAGE];
	
	const {
		profile_url: profileURL, checkflat_url: checkflatURL
	} = URLS[ACTIVE_LANGUAGE];
	
	
	useEffect(() => {
		localStorage.removeItem("checkFlatShoppingCartContent");
		return () => {
		
		};
	}, []);

	
	return (
	<>
		
		
		<CartPageStepsSectionComponent
			activeLanguage={ACTIVE_LANGUAGE}
			cartStep={4}
		/>
	 
	
		<Container as="section" fluid={true} id="cart-page-summary-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					<Col xs={12} as={"h2"} className={"main-section-header"}>
						<span>{headerText}</span>
					</Col>
					
					<Col xs={12} className={"cart-summary-benefits"}>
						<Row className={"cart-summary-benefits-list"}>
							<Col xs={12} lg={4} className={"cart-summary-benefit"}>
								<span>{benefit1Text}</span>
							</Col>
							<Col xs={12} lg={4} className={"cart-summary-benefit"}>
								<span>{benefit2Text}</span>
							</Col>
							<Col xs={12} lg={4} className={"cart-summary-benefit"}>
								<span>{benefit3Text}</span>
							</Col>
						</Row>
					</Col>
					
					<Col xs={12} className={"cart-summary-buttons"}>
						<Button as={NavLink} to={`/${profileURL}`} className={"add-team-mate btn-large"}>
							<span>{inviteToTeamButtonText}</span>
						</Button>
						<Button as={NavLink} to={`/${checkflatURL}`} className={"go-to-checkflat btn-large"}>
							<span>{goToCheckflatButtonText}</span>
						</Button>
					</Col>
				
				</Row>
			</Container>
		</Container>
		
		
	</>
	);
	
};


export default CartPageSummaryComponent;
