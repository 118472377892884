import React, {useEffect, useRef} from "react";


import {Container, Row, Col, Alert} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


const TEXTS = {
	PL: {
		not_found_text: "Strony nie znaleziono"
	},
	EN: {
		not_found_text: "Page Not Found"
	}
}


export const Error404PageComponent = ({activeLanguage}) => {
	
	
	const containerRef = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(containerRef.current, 250);
		return () => {};
	}, []);
	
	
	const {
		not_found_text: notFoundText
	} = TEXTS[activeLanguage];
	
	
	return (
		<Container ref={containerRef} as="section" fluid={true} id="error-page-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col as="h1" xs={12} className="main-header text-center">
						<span>{notFoundText}</span>
					</Col>
					
					<Col xs={12} as={Alert} variant={"warning"}>
						<span>{notFoundText}</span>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
}


export default Error404PageComponent;
