import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import {GLOBAL_POPUP_CONTENT, GLOBAL_POPUP_CONTENT_ACTION} from "@Actions";
import CancelPackageComponent
	from "@UIPages/User/Authenticated/Profile/Sections/MainDataSection/PackageData/CancelPackagePopup";


const URLS = {
	PL: {pricing_page_url: "cennik"},
	EN: {pricing_page_url: "pricing"},
}

const TEXTS = {
	PL: {
		header_text: "Mój Pakiet",
		no_package_message_text: "nie posiadasz jeszcze pakietu", our_offer_text: "zobacz naszą ofertę",
		left_checks_label_text: "Pozostałe sprawdzenia", valid_to_label_text: "Ważne do", payment_type_label_text: "Płatność",
		upgrade_button_text: "ulepsz", cancel_button_text: "chcę anulować"
	},
	EN: {
		header_text: "My Package",
		left_checks_label_text: "Left Reports", valid_to_label_text: "Valid to", payment_type_label_text: "Payment",
		upgrade_button_text: "upgrade", cancel_button_text: "cancel"
	}
};

const PAYMENT_TYPES = {
	PL: {1: "Jednorazowa", 2: "Cykliczna"},
	EN: {1: "One Time", 2: "Partial"},
};


export const MainDataSectionPackageComponent = ({
    userID, userOrders
}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	
	const {
		header_text: headerText,
		no_package_message_text: noPackageMessageText, our_offer_text: ourOfferText,
		left_checks_label_text: leftChecksLabelText, valid_to_label_text: validToLabelText, payment_type_label_text: paymentTypeLabelText,
		upgrade_button_text: upgradeButtonText, cancel_button_text: cancelButtonText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	console.log(userID);
	useEffect(() => {
		return () => {
			DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(null))
		};
	}, []);
	
	
	if (!userOrders || !userOrders.length) {
		return (
			<Col xs={12} lg={3} className={"profile-section user-package-data user-data-form"}>
				<Row className={"personal-data-content profile-section-content"}>
					
					<ProfileSectionHeaderComponent
						headerText={headerText}
					/>
					
					<Col xs={12} className={"user-package-field profile-section-field no-package"}>
						<span className={"no-package-label"}>{noPackageMessageText}</span>
						<NavLink to={`/${URLS[ACTIVE_LANGUAGE]["pricing_page_url"]}`} title={ourOfferText} className={"no-package-link"}>
							<span>{ourOfferText}</span>
						</NavLink>
					</Col>
				
				</Row>
			</Col>
		);
	}
	
	
	const {
		estate_reports_order_offer_type: packageData,
		estate_reports_order_reports: packageReports, // estate_reports_order_offer_quantity: packageReportsNumber,
		estate_reports_order_date: packageOrderDate, estate_reports_order_payment_type: packagePaymentType
	} = userOrders[0] || null;
	
	
	const {
		offer_price_package: packagePackage, // offer_price_name: packageName
	} = packageData;
	const {offer_price_package_name: packageMainName} = packagePackage;
	
	const usedPackageReports = packageReports.filter(report => {
		const {estate_report_date: reportDate} = report;
		return !!reportDate;
	});
	
	const totalReportsNumber = packageReports.length;
	const leftReportsNumber = totalReportsNumber - usedPackageReports;
	
	const packageValidDate = (
		new Date(new Date(packageOrderDate).setFullYear(new Date(packageOrderDate).getFullYear() + 1))
	).toLocaleDateString();
	
	
	const packageResignHandler = (e) => {
		e.preventDefault();
		DISPATCHER(GLOBAL_POPUP_CONTENT_ACTION(<CancelPackageComponent userID={userID} packageData={userOrders[0]} />));
	};
	
	return (
		<Col xs={12} lg={3} className={"profile-section user-package-data user-data-form"}>
			<Row className={"personal-data-content profile-section-content"}>
				
				<ProfileSectionHeaderComponent
					headerText={headerText}
				/>
				
				<Col xs={12} className={"user-package-field profile-section-field package-name"}>
					<span className={"data-field-value"}>{packageMainName}</span>
					<NavLink to={`/${URLS[ACTIVE_LANGUAGE]["pricing_page_url"]}`} title={upgradeButtonText} className={"data-field-link"} onClick={e => e.preventDefault()}>
						<span>{upgradeButtonText}</span>
					</NavLink>
				</Col>
				
				<Col xs={12} className={"user-package-field profile-section-field package-checks"}>
					<span className={"data-field-label"}>{leftChecksLabelText}:</span>
					<span className={"data-field-value"}>
						<span className={"left-checks"}>{leftReportsNumber}</span>
						<span>{"z"}</span>
						<span className={"total-checks"}>{totalReportsNumber}</span>
					</span>
				</Col>
				
				<Col xs={12} className={"user-package-field profile-section-field package-valid-date"}>
					<span className={"data-field-label"}>{validToLabelText}:</span>
					<span className={"data-field-value"}>{packageValidDate}</span>
				</Col>
				
				<Col xs={12} className={"user-package-field profile-section-field package-payment-type"}>
					<span className={"data-field-label"}>{paymentTypeLabelText}:</span>
					<span className={"data-field-value"}>{PAYMENT_TYPES[ACTIVE_LANGUAGE][packagePaymentType]}</span>
				</Col>
				
				<Col xs={12} className={"user-package-field profile-section-field cancel-package"}>
					<a href={"#cancel"} title={cancelButtonText} onClick={e => packageResignHandler(e)}>
						<span>{cancelButtonText}</span>
					</a>
				</Col>
			
			</Row>
		</Col>
	);
	
};


export default MainDataSectionPackageComponent;
