import React, {useState, useEffect, useContext} from "react";

import isEmail from "validator/es/lib/isEmail";


import {Row, Col, Alert} from "react-bootstrap";


import FormTextFieldComponent from "@Elements/Forms/Fields/Text";
import FormSubmitFieldComponent from "@Elements/Forms/Fields/Submit";
import RestApiContext from "@Contexts/REST";
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";
import {LOG_USER_IN_ACTION} from "@Store/Actions";
import {Redirect} from "react-router-dom";


const URLS = {
	PL: {profilePageUrl: "profil"},
	EN: {profilePageUrl: "profile"},
}


const TEXTS = {
	PL: {
		login_text_label: "Zaloguj się", email_address_label: "Adres Email", password_label: "Hasło",
		ERRORS: {
			incorrectValueErrorMessage: "Niepoprawna wartość",
			incorrectEmailErrorMessage: "Niepoprawny Adres email",
			unknownErrorMessage: "Wystąpił błąd! Spróbuj ponownie lub skontaktuj się z nami",
			userDoesNotExistErrorMessage: "Brak użytkownika w bazie danych",
			incorrectPasswordErrorMessage: "Podane Hasło jest nieprawidłowe",
		}
	},
	EN: {
		login_text_label: "Login", email_address_label: "Email Address", password_label: "Password",
		ERRORS: {
			incorrectValueErrorMessage: "Incorrect Value",
			incorrectEmailErrorMessage: "Incorrect Email Address",
			unknownErrorMessage: "Error occurred! Try again or contact us",
			userDoesNotExistErrorMessage: "No user in Database",
			incorrectPasswordErrorMessage: "Incorrect Password",
		}
	}
}


export const LoginFormComponent = ({}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const {
		login_text_label: loginTextLabel, email_address_label: emailAddressLabel, password_label: passwordLabel, ERRORS
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	const LOGGED_USER = useSelector(state => state?.authentication)?.loggedUser || null;
	
	const DISPATCHER = useDispatch();
	
	
	const [loginFormEmailAddress, setLoginFormEmailAddress] = useState("");
	const [loginFormPassword, setLoginFormPassword] = useState("");
	
	const [loginFormErrors, setLoginFormErrors] = useState({});
	
	useEffect(() => {
		return () => {
			setLoginFormErrors({});
		};
	}, []);
	
	
	const changeLoginFormEmailAddress = (fieldValue) => {
		if (isEmail(fieldValue)) setLoginFormErrors({...loginFormErrors, loginFormEmailAddress: null})
		setLoginFormEmailAddress(fieldValue);
	};
	
	const changeLoginFormPassword = (fieldValue) => {
		if (fieldValue.length > 7) setLoginFormErrors({...loginFormErrors, loginFormPassword: null})
		setLoginFormPassword(fieldValue);
	};
	
	
	const submitLoginForm = async() => {
	
		const errors = {};
		
		if (!isEmail(loginFormEmailAddress)) errors.loginFormEmailAddress = ERRORS.incorrectEmailErrorMessage;
		
		if (loginFormPassword.length < 8) errors.loginFormPassword = ERRORS.incorrectValueErrorMessage;
		
		setLoginFormErrors(errors);
		
		if (Object.entries(errors).length > 0) {
			return null;
		}
		
		await DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const formData = new FormData();
		formData.append("user_email", loginFormEmailAddress);
		formData.append("user_password", loginFormPassword);
		
		try {
			const response = await fetch(`${REST_API_URL}/app/users/list/user-login-request/`, {method: "POST", body: formData});
			if (!response.ok) {
				setLoginFormErrors({responseError: {message: (await response).statusText, code: response.status}})
			} else {
				try {
					const userData = await response.json();
					if (userData.error) {
						switch (userData.code) {
							case 404:
								setLoginFormErrors({responseError: {message: ERRORS.userDoesNotExistErrorMessage, code: 404}});
								break;
							case 401:
								setLoginFormErrors({responseError: {message: ERRORS.incorrectPasswordErrorMessage, code: 401}});
								break;
							default:
								setLoginFormErrors({responseError: {message: ERRORS.unknownErrorMessage, code: 500}});
						}
					} else if (userData.success) {
						if (!userData?.user?.id) {
							setLoginFormErrors({responseError: {message: ERRORS.unknownErrorMessage, code: 500}});
						} else {
							DISPATCHER(LOG_USER_IN_ACTION(userData.user));
						}
						
					} else {
						setLoginFormErrors({responseError: {message: ERRORS.unknownErrorMessage, code: 500}});
					}
				} catch (e) {
					setLoginFormErrors({responseError: {message: ERRORS.unknownErrorMessage, code: 500}});
				}
			}
		} catch (e) {
			setLoginFormErrors({responseError: {message: ERRORS.unknownErrorMessage, code: 500}});
		}
		
		await DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
	};
	
	
	if (LOGGED_USER && LOGGED_USER?.id) {
		return <Redirect to={`/${URLS[ACTIVE_LANGUAGE].profilePageUrl}`} />
	}
	
	
	return (
		<Row className={"register-form-component form-component"}>
			
			<Col xs={12} as={"h2"} className={"form-header register-form-header"}>
				<span>{loginTextLabel}</span>
			</Col>
			
			{loginFormErrors?.responseError &&
			<Col as={Alert} xs={12} variant={"danger"}>
				<span>{loginFormErrors.responseError.message}</span>
			</Col>
			}
			
			<FormTextFieldComponent
				fieldValue={loginFormEmailAddress}
				fieldValueHandler={changeLoginFormEmailAddress}
				fieldLabel={emailAddressLabel}
				fieldError={loginFormErrors.loginFormEmailAddress}
				fieldControlID={"user-email"}
				fieldType={"email"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormTextFieldComponent
				fieldValue={loginFormPassword}
				fieldValueHandler={changeLoginFormPassword}
				fieldLabel={passwordLabel}
				fieldError={loginFormErrors.loginFormPassword}
				fieldControlID={"user-password"}
				fieldType={"password"}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
			<FormSubmitFieldComponent
				buttonText={loginTextLabel}
				submitHandler={submitLoginForm}
				fieldDisabledStatus={REST_API_REQUEST_STATUS}
			/>
			
		</Row>
	);
	
};


export default LoginFormComponent;
