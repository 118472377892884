import React, {useEffect, useRef} from "react";

import {Container, Row} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import CartPageStepComponent from "@UIPages/Common/CartPage/_Parts/Steps/Step";


const TEXTS = {
	PL: {
		login_step_text: "Zaloguj się",
		payment_step_text: "Zapłać",
		summary_step_text: "Gotowe"
	},
	EN: {
		login_step_text: "Log In",
		payment_step_text: "Make Payment",
		summary_step_text: "Done!"
	}
}


export const CartPageStepsSectionComponent = ({activeLanguage = "PL", cartStep}) => {
	
	
	const containerRef = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(containerRef.current, 200);
		return () => {
		
		};
	}, []);
	
	
	if (!cartStep) {
		return null;
	}
	
	
	const {
		login_step_text: loginStepText,
		payment_step_text: paymentStepText,
		summary_step_text: summaryStepText
	} = TEXTS[activeLanguage];
	
	const CART_STEPS = [
		{stepNumber: 1, stepText: loginStepText},
		{stepNumber: 2, stepText: paymentStepText},
		{stepNumber: 3, stepText: summaryStepText},
	];
	
	const cartStepsList = CART_STEPS.map(step =>
		<CartPageStepComponent
			key={step.stepNumber}
			cartStepNumber={step.stepNumber}
			cartStepText={step.stepText}
			cartStepActiveStatus={cartStep > step.stepNumber}
		/>
	);
	
	
	return (
		<Container ref={containerRef} as="section" fluid={true} id="cart-page-steps-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<span className={"cart-page-steps-decorator"}></span>
					
					{cartStepsList}
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default CartPageStepsSectionComponent;
