import React, {useEffect, useRef, useState} from "react";


import {Row, Col, Button, Form} from "react-bootstrap"

import {BsArrowLeft} from "react-icons/all";


import imagePlaceholder from "@Assets/Images/no-image_s.png";
import scrollToDomElement from "@Utilities/Scrolling";
import CheckFormBackButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Back";
import CheckFormNextButtonComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Buttons/Next";
import CheckFormInformationComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Information";
import CheckFormFooterComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Footer";
import CheckFormHeaderComponent
	from "@UIPages/User/Authenticated/Checkflat/Sections/CheckFormSection/_Parts/Sections/Header";


const CONTENT = {
	PL: {
		texts: {
			step_header: "Podaj metraż mieszkania",
			button_text: "Dalej",
			return_text: "Wróć"
		},
		
	},
	EN: {
		texts: {
			step_header: "Enter the size of the apartment",
			button_text: "Next",
			return_text: "Back"
		},
		
	}
};


export const CheckFormStep4SectionComponent = ({
	activeLanguage,
	formStep,
	formData, formDataHandler
}) => {
	
	
	const SECTION_DATA = CONTENT[activeLanguage]
	
	
	const {
		flatYardage
	} = formData;
	
	
	const [checkFlatFormDataFlatYardage, setCheckFlatFormDataFlatYardage] = useState(flatYardage || "");
	
	const [formStepSubmitAttemptStatus, setFormStepSubmitAttemptStatus] = useState(false);
	const [formStepErrors, setFormStepErrors] = useState({flatYardage: null});
	
	const changeCheckFlatFormDataFlatYardage = (yardage) => {
		setCheckFlatFormDataFlatYardage(yardage);
		if (formStepSubmitAttemptStatus) {
			setFormStepErrors({
				flatYardage: parseFloat(yardage) > 25 ? null : {message: activeLanguage === "PL" ? "Podany metraż jest nieprawidłowy" : "Incorrect value"}
			});
		}
	};
	
	
	const returnToPreviousStep = (e) => {
		e.preventDefault();
		submitCheckFlatFormStep(true);
	};
	
	const submitCheckFlatFormStep = (reset = false) => {
		setFormStepSubmitAttemptStatus(true);
		if ((!parseFloat(checkFlatFormDataFlatYardage) || parseFloat(checkFlatFormDataFlatYardage) < 25.01) && !reset) {
			setFormStepErrors({
				flatYardage: {message:
					activeLanguage === "PL" ? "Podany metraż jest nieprawidłowy" : "Incorrect value"
				}
			});
			return null;
		}
		formDataHandler({
			flatYardage: reset ? null : checkFlatFormDataFlatYardage
		}, reset ? 2 : 4);
	};
	
	return (
		<Row className={"check-form-step-component check-form-step-4-component user-form-component"}>
			
			<CheckFormHeaderComponent
				text={SECTION_DATA["texts"]["step_header"]}
			/>
			
			<Col xs={12} lg={6} className="check-form-step-content user-form-content">
				<Row className={"check-form-step-option user-form-option select-option"}>
					<Col xs={12} className={"step-option-content user-form-option-content"}>
						<Form.Label>{activeLanguage === "PL" ? "Metraż" : "Yardage"}</Form.Label>
						<Form.Control
							type={"number"}
							value={checkFlatFormDataFlatYardage}
							onChange={e => changeCheckFlatFormDataFlatYardage(e.target.value)}
							placeholder={activeLanguage === "PL" ? "Wpisz metraż mieszkania, np. 45.56 m2" : "Enter apartament yardage, f.e. 45.56 m2"}
							className={formStepErrors.flatYardage ? "error" : ""}
						/>
						{formStepErrors.flatYardage &&
						<Form.Label className={"error"}>{formStepErrors.flatYardage.message}</Form.Label>
						}
					</Col>
				</Row>
			</Col>
			
			<CheckFormInformationComponent xs={12} lg={{span: 4, offset: 2}} formStep={formStep} />
			
		</Row>
	)
	
};


export default CheckFormStep4SectionComponent;
