import React from "react";


import {Col} from "react-bootstrap";


export const CartPageStepComponent = ({
	cartStepNumber,
	cartStepText,
	cartStepActiveStatus = false
}) => {
	
	
	return (
		<Col xs={4} className={"cart-page-step" + (cartStepActiveStatus ? " active" : "")}>
			<span className={"step-number"}>{cartStepNumber}</span>
			<span className={"step-text"}>{cartStepText}</span>
		</Col>
	);
	
};


export default CartPageStepComponent;
