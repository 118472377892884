export const TRANSLATIONS = {
	SINGLE_WORDS: {
		"back": {PL: "wróć", EN: "back"},
		"next": {PL: "dalej", EN: "next"},
		"skip": {PL: "pomiń", EN: "skip"},
		"previous_step": {PL: "poprzedni krok", EN: "previous step"},
	}
};


export default TRANSLATIONS;
