import React from "react";


import {Col, Row} from "react-bootstrap";


import {FaEdit} from "react-icons/all";


export const CheckFormConfiguredOptionComponent = ({elementLabel, elementValue, elementHandler}) => {

	
	if (!elementLabel || !elementValue) {
		return null;
	}
	
	
	return (
		<Row className={"check-form-step-option user-form-option info-option"}>
			<Col xs={12} className={"step-option-content user-form-option-content"}>
				<span className={"form-info-label"}>{elementLabel}:</span>
				<span className={"form-info-value"}>{elementValue}</span>
				<FaEdit className={"form-info-edit"} onClick={elementHandler}/>
			</Col>
		</Row>
	);
	
};


export default CheckFormConfiguredOptionComponent;
