import React, {useContext, useEffect, useState} from "react";

import dateFormat from "dateformat";

import {Container, Row, Col, Form} from "react-bootstrap";

import {FaEdit} from "react-icons/all";


import CartPageStepsSectionComponent from "@UIPages/Common/CartPage/_Parts/Steps";

import FormCheckboxFieldComponent from "@Elements/Forms/Fields/Checkbox";
import FormSubmitFieldComponent from "@Elements/Forms/Fields/Submit";
import {Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RestApiContext from "@Contexts/REST";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Store/Actions/Interface";
import {LOG_USER_IN_ACTION} from "@Store/Actions";


const TEXTS = {
	PL: {
		welcome_text: "Witaj",
		payment_type_label: "Rodzaj płatności",
		payment_type_advertising: "Zyskaj 15% przy płątności jednorazowej",
		one_time_payment: "Jednorazowo",
		monthly_time_payment: "Miesięcznie",
		online_payment_label: "Płatność Online",
		classic_payment_label: "Przelew Tradycyjny",
		regulations_label: "Akceptuję regulamin, politykę prywatności oraz warunki odstąpienia od umowy. Zapoznałem/łam się z dostępnymi miastami i oświadczam, że sprawdzone przeze mnie nieruchomość znajduje się na terenie jednego z tych miast. Usługę chce mieć natychmiast, więc rezygnuje z 14- dniowego okresu zwrotu.",
		submit_button_text: "Potwierdź i zapłać",
		order_header: "Zamawiasz",
		package_label: "Pakiet",
		checks_label: "Liczba Sprawdzeń",
		valid_to_label: "Ważne do",
		payment_label: "Płatność",
		total_amount_label: "Łączna kwota",
		including_vat_label: "z VAT",
		discount_code: "Kod Rabatowy",
	},
	EN: {
		welcome_text: "Welcome",
		payment_type_label: "Payment Type",
		payment_type_advertising: "Get 15% with a one-time payment",
		one_time_payment: "One Time",
		monthly_time_payment: "Monthly",
		online_payment_label: "Online Payment",
		classic_payment_label: "Traditional Bank Transfer",
		regulations_label: "I accept the regulations, privacy policy and terms of withdrawal from the contract. I have familiarized myself with the available cities and I declare that the property I have checked is located in one of these cities. He wants to get the service immediately, so he gives up the 14-day refund period.",
		submit_button_text: "Confirm and pay",
		order_header: "You order",
		package_label: "Package",
		checks_label: "Number of Checks",
		valid_to_label: "Valid until",
		payment_label: "Payment",
		total_amount_label: "Total amount",
		including_vat_label: "including VAT",
		discount_code: "Discount Code",
	},
}


export const CartPagePaymentComponent = ({checkoutProcessData, cartStepHandler}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus || false;
	const LOGGED_USER = useSelector(state => state?.authentication)?.loggedUser || null;
	
	
	useEffect(() => {
		return () => {
			setSelectedPaymentOption(0);
			setRegulationsAcceptedStatus(false);
			setCheckoutProcessErrors({});
		};
	}, []);
	
	
	const [selectedPaymentOption, setSelectedPaymentOption] = useState(0);
	
	const [regulationsAcceptedStatus, setRegulationsAcceptedStatus] = useState(false);
	
	const [checkoutProcessErrors, setCheckoutProcessErrors] = useState({});
	
	const changeSelectedPaymentOption = (option) => {
		if (option) setCheckoutProcessErrors({...checkoutProcessErrors, selectedPaymentOption: null})
		setSelectedPaymentOption(option);
	};
	
	const changeRegulationsAcceptedStatus = (status) => {
		if (status) setCheckoutProcessErrors({...checkoutProcessErrors, regulationsAcceptedStatus: null})
		setRegulationsAcceptedStatus(status);
	};
	
	
	if (!LOGGED_USER) {return <Redirect to={"/"} />;}
	
	
	const {id: userID, user_first_name: userFirstName} = LOGGED_USER;
	
	
	const {
		paymentType, paymentTypeHandler,
		chosenPackage, chosenPackageData,
		// extraProducts,
		// cartTotalPrices,
		discountCode,
		// discountCodeHandler
	} = checkoutProcessData;
	
	const {
		package_name: packageName, package_checks_number: packageChecksNumber
	} = chosenPackageData;
	
	const {
		checks_number_value: chosenPackageChecksNumber,
		checks_number_prices: chosenPackageChecksPrices
	} = packageChecksNumber[chosenPackage.packageChecks];
	
	const chosenPackageNormalPrice = chosenPackageChecksNumber * chosenPackageChecksPrices[paymentType];
	const chosenPackageOldPrice = discountCode ? chosenPackageNormalPrice : null;
	const chosenPackagePrice = discountCode ?
		chosenPackageNormalPrice * ((100 - discountCode.discountCodeValue) / 100)
		:
		chosenPackageNormalPrice
	;
	const chosenPackageVAT = 0.23 * chosenPackagePrice;
	
	
	const todayDate = new Date();
	const validDate = (new Date(todayDate.setFullYear(todayDate.getFullYear() + 1))).toLocaleDateString();
	
	
	const submitCheckoutProcess = async() => {
		
		if (!userID) return null;
		
		const errors = {};
		
		if (selectedPaymentOption === 0) errors.selectedPaymentOption = "Nieprawidłowa Wartość";
		
		if (!regulationsAcceptedStatus) errors.regulationsAcceptedStatus = "Nieprawidłowa Wartość"
		
		setCheckoutProcessErrors(errors);
		
		if (Object.entries(errors).length) {
			return null;
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		
		const {paymentType, chosenPackage, chosenPackageData} = checkoutProcessData;
		const {packageID, packageChecks} = chosenPackage;
		const {package_checks_number: packageChecksNumber} = chosenPackageData
		const {checks_number_value: checksNumberValue, checks_number_prices: checksNumberPrices} = packageChecksNumber[packageChecks];
		const singlePackageCheckValue = checksNumberPrices[paymentType]
		
		const orderValue = singlePackageCheckValue * checksNumberValue;
		const totalOrderValue = orderValue * 1.23;
		
		const orderFormData = new FormData();
		
		orderFormData.append("estate_reports_order_user", userID);
		orderFormData.append("estate_reports_order_offer_type", (+packageID + 1).toString());
		orderFormData.append("estate_reports_order_offer_packages_type", packageID);
		orderFormData.append("estate_reports_order_offer_quantity", checksNumberValue);
		orderFormData.append("estate_reports_order_offer_single_report_value", singlePackageCheckValue || "0");
		orderFormData.append("estate_reports_order_value", totalOrderValue.toString());
		orderFormData.append("estate_reports_order_tax_percentage_value", "23");
		orderFormData.append("estate_reports_order_payment_type", paymentType);
		
		
		try {
			
			const response = await fetch(
				`${REST_API_URL}/estates/orders/list/create-new-order/`,
				{method: "POST", body: orderFormData}
			);
			
			if (!response.ok) {
				console.log("response", response.statusText, response.status);
			} else {
				
				try {
					
					const orderData = await response.json();
					
					if (orderData.error) {
						console.log("order", orderData.message);
					} else if (orderData.success) {
					
						const {
							// id: orderID,
							url: orderURL,
							estate_reports_order_payment: orderPayment
						} = orderData.order;
						
						const {
							url: orderPaymentURL
						} = orderPayment;
						
						const userUpdateFormData = new FormData();
						userUpdateFormData.append("user_active_package_order", orderURL);
						
						try {
							await fetch(`${REST_API_URL}/app/users/list/${userID}/`, {method: "PATCH", body: userUpdateFormData});
						} catch (e) {
							console.log("user", 500, e);
						}
						
						const paymentFormData = new FormData();
						paymentFormData.append("payment_date", dateFormat(new Date(), "isoDateTime"));
						paymentFormData.append("payment_status", "3");
						
						try {
							const paymentResponse = await fetch(orderPaymentURL, {method: "PATCH", body: paymentFormData});
							if (paymentResponse.ok) {
								const UPDATED_LOGGED_USER = {...LOGGED_USER, user_orders: (typeof LOGGED_USER["user_orders"] === "object" ? LOGGED_USER["user_orders"] : []).unshift(orderData.order)};
								DISPATCHER(LOG_USER_IN_ACTION(UPDATED_LOGGED_USER));
								cartStepHandler(3);
							} else {
								console.log("paym", paymentResponse.status, paymentResponse.statusText);
							}
						} catch (e) {
							console.log("paym", 500, e);
						}
						
					} else {
						console.log("wtf");
					}
					
					
				} catch (e) {
					console.log("json", e);
				}
				
			}
			
		} catch (e) {
			console.log("response", e, 500);
		}
		
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
		
		// cartStepHandler(3);
		
	};
	
	
	const {
		welcome_text: welcomeText,
		payment_type_label: paymentTypeLabel,
		payment_type_advertising: paymentTypeAdvertising,
		one_time_payment: oneTimePayment,
		monthly_time_payment: monthlyTimePayment,
		online_payment_label: onlinePaymentLabel,
		classic_payment_label: classicPaymentLabel,
		regulations_label: regulationsLabel,
		submit_button_text: submitButtonText,
		order_header: orderHeader,
		package_label: packageLabel,
		checks_label: checksLabel,
		valid_to_label: validToLabel,
		payment_label: paymentLabel,
		total_amount_label: totalAmountLabel,
		including_vat_label: includingVatLabel,
		discount_code: discountCodeLabel,
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
	<>
		
		
		<CartPageStepsSectionComponent
			cartStep={3}
		/>
		
		
		<Container as="section" fluid={true} id="cart-page-payment-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
				
					
					<Col xs={12} lg={5} className={"cart-payment-data form-component"}>
						
						<Row className={"cart-payment-header"}>
							<Col xs={12} as={"h2"} className={"form-header text-lg-left"}>
								<span>{welcomeText} {userFirstName}</span>
							</Col>
						</Row>
						
						<Row className={"cart-payment-type"}>
							<Col xs={12} as={"h3"} className={"form-sub-header"}>
								<span>{paymentTypeLabel}</span>
							</Col>
							<Col xs={12} className={"payment-type-options"}>
								<Row className={"payment-type-label"}>
									<Col xs={12} as={"h4"} className={"form-decoration-label"}>
										<span>{paymentTypeAdvertising}</span>
									</Col>
								</Row>
								<Row className={"payment-type-choice"}>
									<Col xs={12} className={"form-field switch-field"}>
										<aside className={"form-switch-container left"}>
										<Form.Label className={"switch-label" + (paymentType === "2" ? " selected" : "")}>{monthlyTimePayment}</Form.Label>
										<Form.Check
											type={"switch"}
											id={"payment-type-switch"}
											checked={paymentType === "1"}
											onChange={e => {
												paymentTypeHandler(e.target.checked ? "1" : "2");
											}}
											disabled={REST_API_REQUEST_STATUS}
										/>
										<Form.Label className={"switch-label" + (paymentType === "1" ? " selected" : "")}>{oneTimePayment}</Form.Label>
										</aside>
									</Col>
								</Row>
							</Col>
						</Row>
						
						<Row className={"cart-payment-options"}>
							<Col xs={12} className={"form-field radio-field"}>
								<Row className={"form-field-radio"}>
									<Col xs={12} className={"radio-field-option"}>
										<span
											className={"radio-input" + (selectedPaymentOption === 1 ? " checked" : "") + (REST_API_REQUEST_STATUS ? " disabled:" : "")}
											onClick={REST_API_REQUEST_STATUS ? null : () => changeSelectedPaymentOption(selectedPaymentOption === 1 ? 0 : 1)}
										></span>
										<Form.Label
											onClick={REST_API_REQUEST_STATUS ? null : () => changeSelectedPaymentOption(selectedPaymentOption === 1 ? 0 : 1)}
											className={"radio-input-label"}
										>{onlinePaymentLabel}
										</Form.Label>
									</Col>
								</Row>
							</Col>
							<Col xs={12} className={"form-field radio-field"}>
								<Row className={"form-field-radio"}>
									<Col xs={12} className={"radio-field-option"}>
										<span
											className={"radio-input" + (selectedPaymentOption === 2 ? " checked" : "")  + (REST_API_REQUEST_STATUS ? " disabled:" : "")}
											onClick={REST_API_REQUEST_STATUS ? null : () => changeSelectedPaymentOption(selectedPaymentOption === 2 ? 0 : 2)}
										></span>
										<Form.Label
											onClick={REST_API_REQUEST_STATUS ? null : () => changeSelectedPaymentOption(selectedPaymentOption === 2 ? 0 : 2)}
											className={"radio-input-label"}
										>{classicPaymentLabel}
										</Form.Label>
									</Col>
								</Row>
							</Col>
							{checkoutProcessErrors.selectedPaymentOption &&
							<Col xs={12} className={""}>
								<Form.Label className={"error"}>{checkoutProcessErrors.selectedPaymentOption}</Form.Label>
							</Col>
							}
						</Row>
						
						<Row className={"cart-payment-regulation"}>
							<FormCheckboxFieldComponent
								checkedStatus={regulationsAcceptedStatus}
								clickHandler={REST_API_REQUEST_STATUS ? null : () => changeRegulationsAcceptedStatus(!regulationsAcceptedStatus)}
								fieldLabel={regulationsLabel}
								fieldError={checkoutProcessErrors.regulationsAcceptedStatus}
								containerCssClasses={"align-items-top" + (REST_API_REQUEST_STATUS ? " disabled" : "")}
							/>
						</Row>
						
						<Row className={"cart-payment-submit"}>
							<FormSubmitFieldComponent
								buttonText={submitButtonText}
								submitHandler={REST_API_REQUEST_STATUS ? null : () => submitCheckoutProcess()}
							/>
						</Row>
						
					</Col>
					
					
					<Col xs={12} lg={{span: 5, offset: 2}} className={"cart-payment-summary form-component"}>
						
						<Row className={"payment-summary-information"}>
							
							<Col xs={12} as={"h2"} className={"form-header text-lg-left"}>
								<span>{orderHeader}</span>
								<span onClick={REST_API_REQUEST_STATUS ? null : () => cartStepHandler(1)} className={"edit-element"}>
									<FaEdit />
								</span>
							</Col>
							
							<Col xs={12} className={"form-field information-field"}>
								<span className={"information-label"}>{packageLabel}:</span>
								<span className={"information-value"}>{packageName}</span>
							</Col>
							
							<Col xs={12} className={"form-field information-field package-checks-field"}>
								<span className={"information-label"}>{checksLabel}:</span>
								<span className={"information-value"}>{chosenPackageChecksNumber}</span>
								{chosenPackageOldPrice &&
								<span className={"information-value old-price"}>{chosenPackageOldPrice.toFixed(2)} PLN</span>
								}
								<span className={"information-value package-price"}>{chosenPackagePrice.toFixed(2)} PLN</span>
							</Col>
							
							<Col xs={12} className={"form-field information-field"}>
								<span className={"information-label"}>{validToLabel}:</span>
								<span className={"information-value"}>{validDate}</span>
							</Col>
							
							<Col xs={12} className={"form-field information-field"}>
								<span className={"information-label"}>{paymentLabel}:</span>
								<span className={"information-value"}>{paymentType === "1" ? oneTimePayment : monthlyTimePayment}</span>
							</Col>
							
							<Col xs={12} className={"form-field information-field vat-tax-field"}>
								<span className={"information-label"}>{"VAT"}:</span>
								<span className={"information-value"}>{chosenPackageVAT.toFixed(2)} PLN</span>
							</Col>
							
							{discountCode &&
							<Col xs={12} className={"form-field information-field discount-code-field"}>
								<span className={"information-label"}>{discountCodeLabel}:</span>
								<span className={"information-value"}>
									<span className={"discount-code-value"}>-{discountCode.discountCodeValue}%</span>
									<span className={"discount-code-name"}>({discountCode.discountCodeName})</span>
								</span>
							</Col>
							}
							
							<Col xs={12} className={"form-field information-field total-amount-field"}>
								<span className={"information-label"}>{totalAmountLabel} ({includingVatLabel}):</span>
								{discountCode &&
								<span className={"information-value old-price"}>{(chosenPackageOldPrice * 1.23).toFixed(2)} PLN</span>
								}
								<span className={"information-value total-price"}>{(chosenPackagePrice * 1.23).toFixed(2)} PLN</span>
							</Col>
							
						</Row>
						
					</Col>
					
				
				</Row>
			</Container>
		</Container>
	
	</>
	);
	
};


export default CartPagePaymentComponent;
