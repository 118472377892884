import React from "react";

import {useSelector} from "react-redux";


import {Row, Col} from "react-bootstrap";


import WarningCommunicateComponent from "@Elements/Communicates/Warning";

import TeamMembersHeaderComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/Header";
import TeamMembersListComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/List";
import AddTeamMemberComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData/Members/Add";


const TEXTS = {
	PL: {},
	EN: {}
};

const MESSAGES = {
	PL: {no_team_members_message: "Brak członków zespołu"},
	EN: {no_team_members_message: "No team members"}
};


export const OwnedTeamMembersComponent = ({
	userEmailAddress,
	teamApiURL,
	teamMembers = [], teamMembersHandler = null,
	teamDefaultChecksOption = null,
	teamMaximumMembers = 0
}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {} = TEXTS[ACTIVE_LANGUAGE];
	const {no_team_members_message: noTeamMembersMessage} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	return (
		<Row className={"user-team-members"}>
			
			<TeamMembersHeaderComponent />
			
			{teamMembers.length === 0 &&
			<WarningCommunicateComponent communicateText={noTeamMembersMessage}/>
			}
			
			{teamMembers.length > 0 &&
			<TeamMembersListComponent teamMembers={teamMembers} teamMemberHandler={teamMembersHandler} />
			}
			
			{teamMaximumMembers > teamMembers.length &&
			<AddTeamMemberComponent
				userEmailAddress={userEmailAddress}
				teamApiURL={teamApiURL}
				teamMembersHandler={teamMembersHandler}
				teamDefaultChecksOption={teamDefaultChecksOption}
			/>
			}
		
		</Row>
	);
	
};


export default OwnedTeamMembersComponent;
