import React from "react";

import {useSelector} from "react-redux";


import {Alert, Col, Row} from "react-bootstrap";


import camelCaseToSnakeCase from "@Utilities/Tools/Strings/CamelCaseToSnakeCase";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import ProfileTextFieldComponent from "@UIPages/User/Authenticated/Profile/Elements/Fields/Text";


const TEXTS = {
	PL: {
		header_text: "Dane do Faktury",
		company_invoice_label_text: "Potrzebuję Faktury VAT",
		company_name_label_text: "Nazwa Firmy", company_dni_label_text: "NIP",
		company_street_label_text: "Ulica", company_house_label_text: "Nr budynku / lokalu",
		company_postcode_label_text: "Kod pocztowy", company_city_label_text: "Miejscowość",
		invoice_legend_text: "Jeśli potrzebujesz faktury VAT, wypełnij wszystkie pola"
	},
	EN: {
		header_text: "Invoice Data",
		company_invoice_label_text: "I need Invoice",
		company_name_label_text: "Company Name", company_dni_label_text: "DNI",
		company_street_label_text: "Street", company_house_label_text: "House / Flat nr",
		company_postcode_label_text: "Postcode", company_city_label_text: "City",
		invoice_legend_text: "If You need an invoice, fill all fields"
	}
};


export const MainDataSectionCompanyComponent = ({userData = null, userDataHandler = null}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {
		header_text: headerText,
		company_invoice_label_text: companyInvoiceLabelText,
		company_name_label_text: companyNameLabelText, company_dni_label_text: companyDniLabelText,
		company_street_label_text: companyStreetLabelText, company_house_label_text: companyHouseLabelText,
		company_postcode_label_text: companyPostcodeLabelText, company_city_label_text: companyCityLabelText,
		invoice_legend_text: invoiceLegendText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	if (!userData) return null;
	
	
	const {
		userCompanyInvoiceStatus,
		userCompanyName, userCompanyDni,
		userCompanyStreet, userCompanyHouseNo, userCompanyPostCode, userCompanyCity
	} = userData;

	
	const companyDataFullFilledStatus = (
		!!userCompanyName && !!userCompanyDni && !!userCompanyStreet && !!userCompanyHouseNo && !!userCompanyPostCode && !!userCompanyCity
	);
	
	
	return (
		<Col xs={12} lg={3} className={"profile-section user-company-data user-data-form"}>
			<Row className={"personal-data-content profile-section-content"}>
				
				<ProfileSectionHeaderComponent
					headerText={headerText}
				/>
				
				<Col xs={12} className={"user-data-field profile-section-field checkbox-field"}>
					<span
						className={"checkbox" + (userCompanyInvoiceStatus ? " checked" : "")}
						onClick={() => userDataHandler(camelCaseToSnakeCase("userCompanyInvoiceStatus"), !userCompanyInvoiceStatus)}
					></span>
					<span
						className={"data-field-label"}
						onClick={() => userDataHandler(camelCaseToSnakeCase("userCompanyInvoiceStatus"), !userCompanyInvoiceStatus)}
					>
						{companyInvoiceLabelText}
					</span>
				</Col>
				
				{userCompanyInvoiceStatus && <>
				<ProfileTextFieldComponent
					fieldLabel={companyNameLabelText}
					fieldName={"userCompanyName"}
					fieldValue={userCompanyName}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={companyDniLabelText}
					fieldName={"userCompanyDni"}
					fieldValue={userCompanyDni}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={companyStreetLabelText}
					fieldName={"userCompanyStreet"}
					fieldValue={userCompanyStreet}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={companyHouseLabelText}
					fieldName={"userCompanyHouseNo"}
					fieldValue={userCompanyHouseNo}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={companyPostcodeLabelText}
					fieldName={"userCompanyPostCode"}
					fieldValue={userCompanyPostCode}
					fieldValueHandler={userDataHandler}
				/>
				
				<ProfileTextFieldComponent
					fieldLabel={companyCityLabelText}
					fieldName={"userCompanyCity"}
					fieldValue={userCompanyCity}
					fieldValueHandler={userDataHandler}
				/>
				</>}
				
				{userCompanyInvoiceStatus && !companyDataFullFilledStatus &&
				<Col xs={12} as={Alert} variant={"warning"} className="user-data-field profile-section-field communicate-field">
					<span>{invoiceLegendText}</span>
				</Col>
				}
			
			</Row>
		</Col>
	)
	
	
};


export default MainDataSectionCompanyComponent;


