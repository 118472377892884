import React, {useContext, useState, useEffect} from "react";

import {useSelector} from "react-redux";

import RestApiContext from "@Contexts/REST";


import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";
import WarningCommunicateComponent from "@Elements/Communicates/Warning";


import ProfileSectionHeaderComponent from "@UIPages/User/Authenticated/Profile/Elements/Headers/SectionHeader";
import CreateTeamComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/CreateTeam";
import OwnedTeamDataComponent from "@UIPages/User/Authenticated/Profile/Sections/TeamSection/OwnedTeamData";


const TEXTS = {
	PL: {section_header_text: "Mój Zespół"},
	EN: {section_header_text: "My Team"}
};

const MESSAGES = {
	PL: {no_team_message: "Nie masz jeszcze zespołu"},
	EN: {no_team_message: "Yo don't have Team yet"}
}


export const UserTeamComponent = ({
    CHECK_FLAT_USER_DATA, CHECK_FLAT_USER_DATA_HANDLER = null
}) => {
	
	
	const REST_API_URL = useContext(RestApiContext)?.rootRestApiUrl;
	const {userID, userEmailAddress, userActivePackage} = CHECK_FLAT_USER_DATA;
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage.languageCode;
	
	const {section_header_text: sectionHeaderText} = TEXTS[ACTIVE_LANGUAGE];
	const {no_team_message: noTeamMessage} = MESSAGES[ACTIVE_LANGUAGE];
	
	
	const [userOwnedTeams, setUserOwnedTeams] = useState(null);
	
	
	useEffect(() => {
		getUserOwnedTeams();
		return () => {
			setUserOwnedTeams(null);
		};
	}, []);
	
	const getUserOwnedTeams = () => {
		fetch(`${REST_API_URL}/app/users/teams/?users_team_super_user=${userID}&all_results=true`)
			.then(response => response.ok ? response.json() : [{error: true}])
			.then(content => setUserOwnedTeams(content))
			.catch(error => error)
		;
	};
	
	
	const teamDataRequestHandler = async () => {
		getUserOwnedTeams();
	};
	
	
	if (!CHECK_FLAT_USER_DATA) return null;
	
	
	const offerPricePackageTeamMembersNumber = userActivePackage ? userActivePackage.offerPricePackageTeamMembers : null;

	
	return (
		<Col xs={12} lg={4} className={"profile-section user-team-data"}>
			<Row className={"profile-section-content"}>
				
				<ProfileSectionHeaderComponent headerText={sectionHeaderText} />
				
				{userOwnedTeams === null &&
				<SingleContentLoaderComponent/>
				}
				
				{userOwnedTeams && !userOwnedTeams.length &&
				<WarningCommunicateComponent communicateText={noTeamMessage}/>
				}
				
				{offerPricePackageTeamMembersNumber > 0 && userOwnedTeams && !userOwnedTeams.length &&
				<CreateTeamComponent
					CHECK_FLAT_USER_DATA={{userID}}
					CHECK_FLAT_USER_DATA_HANDLER={CHECK_FLAT_USER_DATA_HANDLER}
					teamDataHandler={teamDataRequestHandler}
				/>
				}
				
				{userOwnedTeams && userOwnedTeams.length > 0 &&
				<OwnedTeamDataComponent
					userEmailAddress={userEmailAddress}
					teamMaximumMembers={offerPricePackageTeamMembersNumber}
					teamData={userOwnedTeams[0]}
					teamDataHandler={teamDataRequestHandler}
				/>
				}
				
			</Row>
		</Col>
	);
	
};


export default UserTeamComponent;
