import React, {useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap";


import {IoIosArrowDown, IoIosArrowUp} from "react-icons/all";


const TEXTS = {
	PL: {
		total_amount: "Łączna kwota",
		includes_vat: "w tym VAT",
		go_to_cashier: "Przejdź do kasy",
		add_discount_code: "Dodaj kod rabatowy",
		optional: "opcjonalnie",
		submit: "Zatwierdź",
		discount: "Kod Rabatowy"
	},
	EN: {
		total_amount: "Total amount",
		includes_vat: "including VAT",
		go_to_cashier: "Proceed to checkout",
		add_discount_code: "Add a discount code",
		optional: "optional",
		submit: "Submit",
		discount: "Discount Code"
	}
	
};

export const CartShoppingCartComponent = ({
	activeLanguage,
	cartTotalPrices,
	discountCode, discountCodeHandler = null,
	cartStepHandler
}) => {
	
	
	const [discountCodeOpenStatus, setDiscountCodeOpenStatus] = useState(!!discountCode);
	const [discountCodeName, setDiscountCodeName] = useState("");
	const [discountCodeError, setDiscountCodeError] = useState(null);
	
	const changeDiscountCodeOpenStatus = () => {
		setDiscountCodeOpenStatus(!discountCodeOpenStatus);
		setDiscountCodeError(null);
	}
	
	const changeDiscountCodeName = (code) => {
		setDiscountCodeName(code);
		if (discountCodeError && code === "") {
			setDiscountCodeError(null);
		}
	};
	
	const submitDiscountCode = () => {
		if (discountCodeName !== "checkflat") {
			setDiscountCodeError("kod jest nieprawidłowy");
			return null;
		}
		if (discountCodeHandler) {
			discountCodeHandler({discountCodeName: "checkflat", discountCodeValue: 10});
		}
	};
	
	
	const {
		total_amount: totalAmountText,
		includes_vat: includesVatText,
		go_to_cashier: goToCashierText,
		add_discount_code: addDiscountCodeText,
		optional: optionalText,
		submit: submitText,
		discount: discountText
	} = TEXTS[activeLanguage];
	
	
	const {
		cartTotalOldPrice, cartTotalPrice
	} = cartTotalPrices;
	
	
	return (
		<Row className={"cart-shopping-cart"}>
			<Col xs={12} className={"shopping-cart-content"}>
				
				<Row className={"shopping-cart-component"}>
					<Col xs={12} className={"shopping-cart-header"}>
						<span className={"cart-price-label"}>{totalAmountText}:</span>
						{discountCode &&
						<span className={"cart-old-price-label"}>{cartTotalOldPrice.toFixed(2)}</span>
						}
						<span className={"cart-price-value"}>{cartTotalPrice.toFixed(2)} PLN</span>
					</Col>
					{discountCode &&
					<Col xs={12} className={"shopping-cart-discount-code"}>
						<span className={"discount-code-label"}>{discountText}:</span>
						<span className={"discount-code-value"}>{discountCode?.discountCodeValue}%</span>
						<span className={"discount-code-name"}>({discountCode?.discountCodeName})</span>
					</Col>
					}
					<Col xs={12} className={"shopping-cart-price"}>
						<span className={"cart-price-label"}>{totalAmountText}</span>
						<span className={"cart-price-label"}>{includesVatText}:</span>
						<span className={"cart-price-value"}>{(cartTotalPrice * 1.23).toFixed(2)} PLN</span>
					</Col>
					<Col xs={12} className={"shopping-cart-button"}>
						<Button variant={"primary"} onClick={() => cartStepHandler(2)}>
							<span>{goToCashierText}</span>
						</Button>
					</Col>
				</Row>
				
				{!discountCode &&
				<Row className={"discount-code-component"}>
					<Col xs={12} className={"discount-code-header"}>
						<Row>
							<Col xs={10} className={"discount-code-label"}>
								<span className={"discount-code-label-text"}>{addDiscountCodeText}</span>
								<span className={"discount-code-label-legend"}>({optionalText})</span>
							</Col>
							{!discountCode &&
							<Col xs={2} className={"discount-code-switcher"}
							     onClick={() => changeDiscountCodeOpenStatus()}>
								<span className={"discount-code-switcher-button"}>
									{discountCodeOpenStatus ? <IoIosArrowUp/> : <IoIosArrowDown/>}
								</span>
							</Col>
							}
						</Row>
					</Col>
					{discountCodeOpenStatus &&
					<Col xs={12} className={"discount-code-field"}>
						<Form.Control
							value={discountCodeName}
							onChange={e => changeDiscountCodeName(e.target.value)}
							className={"discount-code-input" + (discountCodeError ? " error" : "")}
						/>
						{discountCodeError && <Form.Label className={"error"}>{discountCodeError}</Form.Label>}
						<Button onClick={() => submitDiscountCode()}>
							<span>{submitText}</span>
						</Button>
					</Col>
					}
				</Row>
				}
				
			</Col>
		</Row>
	)
	
};


export default CartShoppingCartComponent;
