import React, {useEffect, useState} from "react";


import ApplicationOpinionsComponent from "@UIParts/Opinions";

import HomePageCtaSectionComponent from "@UIPages/Common/HomePage/Sections/CtaSection";
import HomePageHowItWorksSectionComponent from "@UIPages/Common/HomePage/Sections/HowItWorksSection";
import HomePageOurOfferSectionComponent from "@UIPages/Common/HomePage/Sections/OfferSection";
import HomePageOurTipsSectionComponent from "@UIPages/Common/HomePage/Sections/TipsSection";


const PAGE_DATA = {
	cta_section_data: {
		PL: {
			section_title: "Zminimalizuj Ryzyko!",
			section_sub_title: "Kupujesz lub sprzedajesz mieszkanie?",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			section_label: "nie czekaj, sprawdź wartości mieszkań już teraz!"
		},
		EN: {
			section_title: "Minimize the Risk!",
			section_sub_title: "Are you buying or selling an apartment?",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			section_label: "do not wait, check the apartment values now!"
		}
	},
	hiw_section_data: {
		PL: {
			section_title: "Jak to działa?",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			button_text: "Sprawdź"
		},
		EN: {
			section_title: "How it works?",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra. Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			button_text: "Check"
		}
	},
	offer_section_data: {
		PL: {
			section_title: "Zobacz naszą ofertę",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra.\n Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			button_text: "Sprawdź"
		},
		EN: {
			section_title: "See our offer",
			section_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis libero faucibus elementum pharetra.\n Nunc elementum, justo ac condimentum porttitor, turpis metus accumsan nulla, tristique congue nulla felis sed magna.",
			button_text: "Check"
		}
	},
	tips_section_data: {
		PL: {
			section_title: "Nasze Rady",
			button_text: "Zobacz więcej"
		},
		EN: {
			section_title: "Our advice",
			button_text: "See more"
		}
	}
};


export const ApplicationHomePageComponent = ({activeLanguage}) => {
	
	
	const [homePageData, setHomePageData] = useState(null);
	
	useEffect(() => {
		
		const {
			cta_section_data: ctaSectionData,
			hiw_section_data: hiwSectionData,
			offer_section_data: offerSectionData,
			tips_section_data: tipsSectionData
		} = PAGE_DATA;
		
		setHomePageData({
			ctaSectionData: ctaSectionData[activeLanguage],
			hiwSectionData: hiwSectionData[activeLanguage],
			offerSectionData: offerSectionData[activeLanguage],
			tipsSectionData: tipsSectionData[activeLanguage]
		});
		
		return () => {
			setHomePageData(null);
		};
		
	}, []);
	
	
	const ctaSectionData = homePageData?.ctaSectionData || null;
	const hiwSectionData = homePageData?.hiwSectionData || null;
	const offerSectionData = homePageData?.offerSectionData || null;
	const tipsSectionData = homePageData?.tipsSectionData || null;
	
	
	return (
		<>
			
			<HomePageCtaSectionComponent
				sectionData={ctaSectionData}
			/>
		
			<ApplicationOpinionsComponent
				activeLanguage={activeLanguage}
			/>
			
			<HomePageHowItWorksSectionComponent
				sectionData={hiwSectionData}
			/>
			
			<HomePageOurOfferSectionComponent
				sectionData={offerSectionData}
			/>
			
			<HomePageOurTipsSectionComponent
				sectionData={tipsSectionData}
				activeLanguage={activeLanguage}
			/>
			
		</>
	);
	
};


export default ApplicationHomePageComponent;
