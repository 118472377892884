import React from "react";


export const GlobalDataContext = React.createContext(null);


export const GLOBAL_DATA = {
	checksPackages: {
		1: {
			"package_name": "Basic",
			"package_information": [
				{"information_icon": true, "information_text": {PL: "tylko dla Ciebie", EN: "only for you"}},
				{"information_icon": true, "information_text": {PL: "pojedyncze sprawdzenie", EN: "single check"}},
				{"information_icon": true, "information_text": {PL: "lub pakiety sprawdzeń (5/10/15/20)", EN: "or check packages (5/10/15/20)"}},
			],
			"package_checks_number": {
				1: {"checks_number_value": 1, "checks_number_label": "single", "checks_number_prices": {"1": 9.5, "2": 10}},
				2: {"checks_number_value": 5, "checks_number_label": "package", "checks_number_prices": {"1": 9, "2": 9.5}},
				3: {"checks_number_value": 10, "checks_number_label": "package", "checks_number_prices": {"1": 8.5, "2": 9}},
				4: {"checks_number_value": 15, "checks_number_label": "package", "checks_number_prices": {"1": 8, "2": 8.5}},
				5: {"checks_number_value": 20, "checks_number_label": "package", "checks_number_prices": {"1": 7.5, "2": 8}},
			},
			"package_legend_label": {PL: "* do wykorzystania przez rok", EN: "* to be used for one year"},
			"package_button_label": {PL: "Kup Teraz", EN: "Buy Now"}
		},
		2: {
			"package_name": "Team",
			"package_information": [
				{"information_icon": true, "information_text": {PL: "dla zespołu (2-3 osoby)", EN: "for the team (2-3 people)"}},
				{"information_icon": false, "information_text": {PL: "-", EN: "-"}},
				{"information_icon": true, "information_text": {PL: "pakiety sprawdzeń (10/25/50/100)", EN: "check packages (10/25/50/100)"}},
			],
			"package_checks_number": {
				1: {"checks_number_value": 10, "checks_number_label": "package", "checks_number_prices": {"1": 9, "2": 9.5}},
				2: {"checks_number_value": 25, "checks_number_label": "package", "checks_number_prices": {"1": 8.5, "2": 9}},
				3: {"checks_number_value": 50, "checks_number_label": "package", "checks_number_prices": {"1": 8, "2": 8.5}},
				4: {"checks_number_value": 100, "checks_number_label": "package", "checks_number_prices": {"1": 7.5, "2": 8}},
			},
			"package_legend_label": {PL: "* do wykorzystania przez rok", EN: "* to be used for one year"},
			"package_button_label": {PL: "Kup Teraz", EN: "Buy Now"}
		},
		3: {
			"package_name": "Professional",
			"package_information": [
				{"information_icon": true, "information_text": {PL: "dla zespołu (3-10 osoby)", EN: "for the team (3-10 people)"}},
				{"information_icon": false, "information_text": {PL: "-", EN: "-"}},
				{"information_icon": true, "information_text": {PL: "pakiety sprawdzeń (50/100/250/500)", EN: "check packages (50/100/250/500)"}},
			],
			"package_checks_number": {
				1: {"checks_number_value": 50, "checks_number_label": "package", "checks_number_prices": {"1": 7.5, "2": 8}},
				2: {"checks_number_value": 100, "checks_number_label": "package", "checks_number_prices": {"1": 7, "2": 7.5}},
				3: {"checks_number_value": 250, "checks_number_label": "package", "checks_number_prices": {"1": 6.5, "2": 7}},
				4: {"checks_number_value": 500, "checks_number_label": "package", "checks_number_prices": {"1": 6, "2": 6.5}},
			},
			"package_legend_label": {PL: "* do wykorzystania przez rok", EN: "* to be used for one year"},
			"package_button_label": {PL: "Kup Teraz", EN: "Buy Now"}
		},
		4: {
			"package_name": "Individual",
			"package_information": {
				PL: "Potrzebujesz więcej osób w zespole lub/i więcej sprawdzeń?",
				EN: "Do you need more people on the team and / or more checkups?"
			},
			"package_checks_number": {
				PL: "Liczba sprawdzeń dopasowana indywidualnie",
				EN: "Number of checks adjusted individually"
			},
			"package_legend_label": null,
			"package_button_label": {PL: "Wyceń dla mnie", EN: "Get a quote for me"}
		}
	}
};


export default GlobalDataContext;
