import React, {useState} from "react";


import {Container, Row, Col, Button} from "react-bootstrap";

import {useSelector} from "react-redux";


import CartPageStepsSectionComponent from "@UIPages/Common/CartPage/_Parts/Steps";

import RegisterFormComponent from "@Elements/Forms/Authentication/Register";
import LoginFormComponent from "@Elements/Forms/Authentication/Login";


const TEXTS = {
	PL: {
		dont_have_account_text: "Nie mam jeszcze konta",
		already_have_account_text: "Mam już konto",
		sign_up_text: "Zarejestruj się",
		sign_in_text: "Zaloguj się",
	},
	EN: {
		dont_have_account_text: "I don't have account",
		already_have_account_text: "I have account",
		sign_up_text: "Register",
		sign_in_text: "Login",
	}
};


export const CartPageAuthenticateComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(
		state => state?.localization
	)?.activeLanguage?.languageCode || "PL";
	
	const {
		dont_have_account_text: dontHaveAccountText, already_have_account_text: alreadyHaveAccountText,
		sign_up_text: signUpText, sign_in_text: signInText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const LOGGED_USER = useSelector(state => state?.authentication)?.loggedUser;
	
	
	const [authenticateType, setAuthenticateType] = useState(1);
	
	
	if (LOGGED_USER) return null;
	
	
	return (
		<>
		
		
		<CartPageStepsSectionComponent cartStep={2} />
		
		
		<Container as="section" fluid={true} id="cart-page-authenticate-section-component" className="block-section-container-fluid">
			<Container className="block-section-container">
				<Row className="block-section-content">
					
					<Col xs={12} lg={5} className={"cart-authenticate-column register-form"}>
						
						{authenticateType !== 1 &&
						<Row className={"register-form form-component"}>
							<Col as="h2" className={"section-header form-header"}>
								<span xs={12}>{dontHaveAccountText}</span>
							</Col>
							<Col xs={12} className={"form-field submit-field text-center"}>
								<Button variant={"primary"} className={"btn-large"}
								        onClick={() => setAuthenticateType(1)}>
									<span>{signUpText}</span>
								</Button>
							</Col>
						</Row>
						}
						
						{authenticateType === 1 &&
						<RegisterFormComponent
							activeLanguage={ACTIVE_LANGUAGE}
						/>
						}
						
					</Col>
					
					<Col xs={12} lg={{span: 5, offset: 1}} className={"cart-authenticate-column login-form"}>
						
						{authenticateType !== 2 &&
						<Row className={"login-form form-component"}>
							<Col as="h2" className={"section-header form-header"}>
								<span xs={12}>{alreadyHaveAccountText}</span>
							</Col>
							<Col xs={12} className={"form-field submit-field text-center"}>
								<Button variant={"primary"} className={"btn-large"} onClick={() => setAuthenticateType(2)}>
									<span>{signInText}</span>
								</Button>
							</Col>
						</Row>
						}
						
						{authenticateType === 2 &&
						<LoginFormComponent
							activeLanguage={ACTIVE_LANGUAGE}
						/>
						}
						
					</Col>
					
				</Row>
			</Container>
		</Container>
		
		
		</>
	);
	
	
};


export default CartPageAuthenticateComponent;
